import { AxiosResponse } from 'axios';
import ApiUtil from '../../utils/apiUtil';

export default class IngestionService {
  async dataIngestion(payload: any): Promise<any> {
    const url = '/ingestion';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getWarrenGroupFilesList(payload: any): Promise<any> {
    const url = `/warrenGroup?page=${payload.page}&limit=${payload.limit}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async mergeWarrenGroupFiles(payload: any): Promise<any> {
    const url = '/warrenGroup';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getExrIngestionData(key: string): Promise<any> {
    const url = `/ingestion/?key=${key}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getUploadedFilesName(): Promise<any> {
    const url = `/portfolio`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getUploadedFileDetails(key: any): Promise<any> {
    const url = `/portfolio/${key}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getAllFiles(payload: any): Promise<any> {
    const url = `/file?page=${payload.page}&limit=${payload.limit}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getAllFilesById(id: string): Promise<any> {
    const url = `/file/${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getUploadedFile(id: string): Promise<any> {
    const url = `/file/getUploadFile/${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async uploadCreditWidgetFile(payload: any): Promise<any> {
    const url = `/v1/uploadFile`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async searchUser(searchValue: any): Promise<any> {
    const url = `/userDetails/search?search=${searchValue}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async sharePortfolio(payload: any): Promise<any> {
    const url = '/file/sharePortfolio';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }
}

import { ConfigType } from './@types/ApiType';

const dev = {
  api: {
    URL: 'https://er-backend-staging.azurewebsites.net/api',
  },
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  CLIENT_DEVICE: 'web',
  WEBSOCKET_URL: 'https://er-backend-staging.azurewebsites.net',
  EXCHANGE_ROBOTICS_LINK: 'https://happy-ocean-0924f4e10.3.azurestaticapps.net',
  ER_ML_URL: 'https://er-ml-clone-stage.azurewebsites.net',
  PUBLIC_VAPID_KEY:
    'BJkWMgP7dnB1B0UO6zAO-s-_KeCkZJaPRYgWKREc9Ztc5JkjgL7wwyctVGnkFa1h-eaNG8ZZM51QgYO_bHyMT-U',
};

const stage = {
  api: {
    URL: 'https://er-backend-staging.azurewebsites.net/api',
  },
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  CLIENT_DEVICE: 'web',
  WEBSOCKET_URL: 'https://er-backend-staging.azurewebsites.net',
  EXCHANGE_ROBOTICS_LINK: 'https://happy-ocean-0924f4e10.3.azurestaticapps.net',
  ER_ML_URL: 'https://er-ml-clone-stage.azurewebsites.net',
  PUBLIC_VAPID_KEY:
    'BJkWMgP7dnB1B0UO6zAO-s-_KeCkZJaPRYgWKREc9Ztc5JkjgL7wwyctVGnkFa1h-eaNG8ZZM51QgYO_bHyMT-U',
};

const prod = {
  api: {
    URL: 'https://er-backend.azurewebsites.net/api',
  },
  CLIENT_ID: '',
  CLIENT_SECRET: '',
  CLIENT_DEVICE: 'web',
  WEBSOCKET_URL: 'https://er-backend.azurewebsites.net',
  EXCHANGE_ROBOTICS_LINK: 'https://app.exchangerobotics.com',
  ER_ML_URL: 'https://er-ml-clone.azurewebsites.net',
  PUBLIC_VAPID_KEY:
    'BJkWMgP7dnB1B0UO6zAO-s-_KeCkZJaPRYgWKREc9Ztc5JkjgL7wwyctVGnkFa1h-eaNG8ZZM51QgYO_bHyMT-U',
};

let config: ConfigType;
// window.alert(`environment: ${process.env.REACT_APP_STAGE}`);
switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = dev;
    break;
  case 'staging':
    config = stage;
    break;
  case 'production':
    config = prod;
    break;
  default:
    config = dev;
    break;
}

export default config;

import { AppStateType } from '../reducers';

export const nplPortfolioIdData = (state: AppStateType) => {
  return state.PortfolioReducer.nplPortfolioId;
};

export const loanPortfolioIdData = (state: AppStateType) => {
  return state.PortfolioReducer.loanPortfolioId;
};

export const receivablePortfolioIdData = (state: AppStateType) => {
  return state.PortfolioReducer.receivablePortfolioId;
};

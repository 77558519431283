// Components
// Auth Guard
//import AuthGuard from '../app/components/guards/AuthGuard';
import Dashboard from '../app/containers/client/dashboard/Dashboard';
import UpdateProfile from '../app/containers/client/dashboard/UpdateProfile';
import Newsfeed from '../app/containers/client/newsfeed/Newsfeed';
import Portfolio from '../app/containers/client/portfolio/Portfolio';
import Product from '../app/containers/client/product/Product';
import Trading from '../app/containers/client/trading/Trading';
import Home from '../app/containers/public/Home';

export const DefaultRoutes = [
  {
    path: '/',
    component: Home,
    children: null,
    guard: null,
  },
];

export const AfterLoginRoute = [
  {
    path: '/dashboard',
    component: Dashboard,
    children: null,
    guard: null,
  },
  {
    path: '/update-profile',
    component: UpdateProfile,
    children: null,
    guard: null,
  },
  {
    path: '/products',
    component: Product,
    children: null,
    guard: null,
  },
  {
    path: '/portfolio',
    component: Portfolio,
    children: null,
    guard: null,
  },
  {
    path: '/newsfeed',
    component: Newsfeed,
    children: null,
    guard: null,
  },
  {
    path: '/trading',
    component: Trading,
    children: null,
    guard: null,
  },
];

import { AppStateType } from '../reducers';

export const productStructureSelector = (state: AppStateType) => {
  return state.ProductsWidgetsReducer.productSturctureWidgetData;
};

export const allProductsWidgetsSelector = (state: AppStateType) => {
  return [
    ...state.DashboardWidgetReducer.newsFeedWidgetData,
    ...state.DashboardWidgetReducer.tasksWidgetData,
    ...state.DashboardWidgetReducer.nplFinancialWidgetData,
    ...state.DashboardWidgetReducer.transactionReportsWidgetData,
    ...state.DashboardWidgetReducer.embeddedBrowserWidgetData,
  ];
};

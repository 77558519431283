import { isEmpty, split } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconChatbot } from '../Icons';
import { userData } from '../../../redux/selector/UserSelector';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { widgetTypeData } from '../../../redux/selector/WidgetTypeSelector';
import moment from 'moment';
import useAction from '../../hooks/useAction';
import { chatData } from '../../../redux/selector/ChatSelector';
import { productStructuringWidgetData } from '../../../redux/selector/ProductStructuringSelector';
import { reloadPortfolioData } from '../../../redux/selector/ReloadPortfolioSelector';
import {
  portfolioCreditWidgetSelector,
  portfolioLoanWidgetSelector,
  portfolioNplWidgetSelector,
} from '../../../redux/selector/PortfolioWidgetsSelector';
import {
  loanPortfolioIdData,
  nplPortfolioIdData,
} from '../../../redux/selector/PortfolioSelector';
import { widgetReferenceIdData } from '../../../redux/selector/WidgetReferenceIdSelector';
import { dashboardNplFinancialsWidgetDataSelector } from '../../../redux/selector/DashboardWidgetSelector';
import { warrenFileData } from '../../../redux/selector/WarrenFileSelector';
//import { totalRowsData } from '../../../redux/selector/TotalRowsSelector';
//import { rowIndexData } from '../../../redux/selector/RowIndexSelector';

const MessageInput = ({ socket }: any) => {
  const [inputText, setInputText] = useState('');

  const {
    addNewMessage,
    showModal,
    addRowIndex,
    addRowSelectionType,
    addSelectedPortfolio,
    reloadPortfolio,
    addTypingStatus,
    addChatQuery,
    addLoanChatQuery,
    addNplChatQuery,

    addPortfolioLoanWidgetData,
    addPortfolioNplWidgetData,
    addDashboardNplFinancialsData,
  } = useAction();

  const user = useSelector(userData);
  const widgetName = useSelector(widgetTypeData);
  const chatMsg = useSelector(chatData);
  const selectedPortfolios = useSelector(productStructuringWidgetData);
  const loanPortfolioId = useSelector(loanPortfolioIdData);
  const nplPortfolioId = useSelector(nplPortfolioIdData);

  const portfolioLoanReduxData = useSelector(portfolioLoanWidgetSelector);
  const warrenFileNames = useSelector(warrenFileData);

  const portfolioNplReduxData = useSelector(portfolioNplWidgetSelector);
  const nplReduxData = useSelector(dashboardNplFinancialsWidgetDataSelector);

  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);

  /*const selectedRowsData = useSelector(
    (state: any) => state.SelectedRowsReducer.selectedRows,
  );
  const selectedColumnType = useSelector(
    (state: any) => state.SelectedRowsReducer.columnType,
  );*/
  const shouldReload = useSelector(reloadPortfolioData);
  //const rowIndexArray = useSelector(rowIndexData);
  //const allRows = useSelector(totalRowsData);

  const portfolioCreditReduxData = useSelector(portfolioCreditWidgetSelector);

  //speech to text
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    setInputText('');
  }, [widgetReferenceIdValue]);

  const onInputChange = (e: any) => {
    setInputText(e.target.value);
  };

  const handleMessageValidation = () => {
    if (!isEmpty(inputText.trim())) {
      return true;
    } else {
      return false;
    }
  };

  const handleSendMessage = useCallback(() => {
    const isValid = handleMessageValidation();
    let currentDate = moment().format();
    if (isValid) {
      if (!isEmpty(socket)) {
        let searchValue = [];
        for (let chat in chatMsg) {
          let filteredVal = chatMsg[chat]
            ?.filter((a: any) => a.widgetType.toUpperCase() === widgetName)
            ?.reverse();
          searchValue.unshift(filteredVal);
        }

        let botMsg = searchValue[0]?.find(
          (x: any) => Number(x.referenceId) + 1 === x.id,
        );

        let newMessage = {
          message: inputText.trim(),
          //messageType: widgetName?.includes('NEWS') ? 'NEWS' : 'TEXT',
          messageType: 'TEXT',
          widgetType: widgetName,
          createdAt: currentDate,
          createdDate: split(currentDate, 'T')[0],
          isSearch: true,
          fromUser: true,
          fromId: user.user_id,
        };
        addNewMessage(newMessage);

        if (
          !warrenFileNames.length ||
          (warrenFileNames.length && widgetName !== 'CREDIT')
        ) {
          if (widgetName === 'CREDIT') {
            addChatQuery(inputText.trim());
          }

          if (widgetName === 'LOAN_FINANCIAL') {
            addLoanChatQuery(inputText.trim());
          }

          if (widgetName === 'NPL_FINANCIAL') {
            addNplChatQuery(inputText.trim());
          }

          if (widgetName !== 'CREDIT') {
            socket.emit('sendNewMessage', {
              message: inputText.trim(),
              messageType: 'TEXT',
              file: '',
              widgetType: widgetName,
              fromId: user.user_id,
              widgetReferenceId: widgetReferenceIdValue,
              referenceId: botMsg?.id,
              key:
                widgetName === 'CREDIT'
                  ? portfolioCreditReduxData[0]?.fileKey || ''
                  : '',
            });
          }
        } else {
          socket.emit('sendNewMessage', {
            message: inputText.trim(),
            messageType: 'COMMAND:WARREN_GROUP',
            file: '',
            widgetType: widgetName,
            fromId: user.user_id,
            widgetReferenceId: widgetReferenceIdValue,
            referenceId: botMsg?.id,
            key:
              widgetName === 'CREDIT'
                ? portfolioCreditReduxData[0]?.fileKey || ''
                : '',
            frontendStates: {
              fileList: warrenFileNames.map((x: any) => x.value),
            },
          });
        }

        if (widgetName === 'LOAN_FINANCIAL') {
          socket.off('selectPortfolio').on('selectPortfolio', (res: any) => {
            addPortfolioLoanWidgetData({
              id: portfolioLoanReduxData[widgetReferenceIdValue]?.id,
              ...portfolioLoanReduxData[widgetReferenceIdValue],
              selectedPortfolioId: res.portfolio[0]?.id,
              selectedPortfolioName: res.portfolio[0]?.portfolioName,
            });
          });

          socket
            .off('calculatePortfolio')
            .on('calculatePortfolio', (res: any) => {
              socket.emit('getProductCalculation', {
                messageId: res.messageId,
                portfolio: [{ id: loanPortfolioId }],
                functionName: res.functionName[0],
                widgetType: widgetName,
              });
            });
        }

        if (window.location.pathname.includes('portfolio')) {
          if (widgetName === 'NPL_FINANCIAL') {
            socket.off('selectPortfolio').on('selectPortfolio', (res: any) => {
              addPortfolioNplWidgetData({
                id: portfolioNplReduxData[widgetReferenceIdValue]?.id,
                ...portfolioNplReduxData[widgetReferenceIdValue],
                selectedPortfolioId: res.portfolio[0]?.id,
                selectedPortfolioName: res.portfolio[0]?.portfolioName,
              });
            });

            socket
              .off('calculatePortfolio')
              .on('calculatePortfolio', (res: any) => {
                socket.emit('getProductCalculation', {
                  messageId: res.messageId,
                  portfolio: [{ id: nplPortfolioId }],
                  functionName: res.functionName[0],
                  widgetType: widgetName,
                });
              });
          }
        }

        if (window.location.pathname.includes('dashboard')) {
          if (widgetName === 'NPL_FINANCIAL') {
            socket.off('selectPortfolio').on('selectPortfolio', (res: any) => {
              addDashboardNplFinancialsData({
                id: nplReduxData[widgetReferenceIdValue]?.id,
                ...nplReduxData[widgetReferenceIdValue],
                selectedPortfolioId: res.portfolio[0]?.id,
                selectedPortfolioName: res.portfolio[0]?.portfolioName,
              });
            });

            socket
              .off('calculatePortfolio')
              .on('calculatePortfolio', (res: any) => {
                socket.emit('getProductCalculation', {
                  messageId: res.messageId,
                  portfolio: [{ id: nplPortfolioId }],
                  functionName: res.functionName[0],
                  widgetType: widgetName,
                });
              });
          }
        }

        if (widgetName === 'PRODUCTS') {
          socket.off('selectPortfolio').on('selectPortfolio', (res: any) => {
            addSelectedPortfolio(
              res.portfolio.map((i: any) => {
                return {
                  label: i.portfolioName,
                  value: i.id,
                };
              }),
            );
          });

          socket
            .off('calculatePortfolio')
            .on('calculatePortfolio', (res: any) => {
              socket.emit('getProductCalculation', {
                messageId: res.messageId,
                portfolio: selectedPortfolios.map((i: any) => {
                  return {
                    id: i.value,
                  };
                }),
                functionName: res.functionName[0],
                widgetType: widgetName,
              });
            });
        }

        if (widgetName === 'CREDIT') {
          socket
            .off('askForRow')
            .once('askForRow', ({ messageId, forEachRow }: any) => {
              if (
                portfolioCreditReduxData[widgetReferenceIdValue]?.selectedRows
                  ?.length <= 0
              ) {
                showModal('Please Select atleast 1 row!');
              }
              if (
                portfolioCreditReduxData[widgetReferenceIdValue]?.selectedRows
                  ?.length > 0
              ) {
                socket.emit('getCalculation', {
                  messageId: messageId,
                  rowData:
                    portfolioCreditReduxData[widgetReferenceIdValue]
                      ?.selectedRows,
                  forEachRow: forEachRow,
                });
              }
            });
        }

        socket.on('typing', ({ processType }: any) => {
          addTypingStatus(processType);
        });

        if (widgetName === 'CREDIT') {
          socket
            .off('addRowForSavePortfolio')
            .on('addRowForSavePortfolio', (res: any) => {
              if (
                portfolioCreditReduxData[widgetReferenceIdValue]?.selectedRows
                  ?.length <= 0
              ) {
                showModal('Please Select atleast 1 row!');
              }
              if (
                portfolioCreditReduxData[widgetReferenceIdValue]?.selectedRows
                  ?.length > 0
              ) {
                socket.emit('saveUserPortfolio', {
                  messageId: res.messageId,
                  portfolioName: res.portfolioName,
                  category: res.category,
                  calculateResult: res.calculateResult,
                  inputData:
                    portfolioCreditReduxData[widgetReferenceIdValue]
                      ?.selectedRows,
                  columnType:
                    portfolioCreditReduxData[widgetReferenceIdValue]
                      ?.categoryData,
                });
                reloadPortfolio(shouldReload ? false : true);
              }
            });
        }

        socket.on('socket_error', (e: any) => {
          showModal(`Connection Error: ${e}`);
        });

        if (widgetName === 'CREDIT') {
          socket.off('selectRows').on('selectRows', ({ rowIndex }: any) => {
            addRowIndex(rowIndex);
          });
        }

        if (widgetName === 'CREDIT') {
          socket.off('deSelectRows').on('deSelectRows', ({ rowIndex }: any) => {
            let rowsSelectedIndex = portfolioCreditReduxData[
              widgetReferenceIdValue
            ]?.selectedRows.map((x: any) => x.index);

            let rowsSelected = rowsSelectedIndex.filter(
              (a: any) => rowIndex.indexOf(a) < 0,
            );

            addRowIndex(rowsSelected);
          });
        }

        resetTranscript();
        setInputText('');
      }
    }
  }, [
    socket,
    portfolioCreditReduxData[0]?.selectedRows,
    inputText,
    widgetName,
  ]);

  useEffect(() => {
    if (listening) {
      setInputText(transcript);
    }
  }, [transcript]);

  /*const toggleCloseModal = () => {
    document.body.classList.remove('has-overlay');
    document.body.classList.remove('chatbox-open');
  };*/

  return (
    <div className="keyboard-access flexbox">
      <input
        type="text"
        className="form-control"
        placeholder="Type message here..."
        autoFocus
        value={inputText}
        onClick={() => {
          addRowSelectionType('CHATBOX');
        }}
        onChange={onInputChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleSendMessage();
            //toggleCloseModal();
          }
        }}
      />
      {listening ? (
        <button
          type="button"
          className="btn-icon chat-mike"
          onClick={e => {
            e.preventDefault();
            SpeechRecognition.stopListening();
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSendMessage();
              //toggleCloseModal();
            }
          }}
        >
          X
        </button>
      ) : (
        <button
          type="button"
          className="btn-icon chat-mike"
          onClick={e => {
            e.preventDefault();
            SpeechRecognition.startListening();
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSendMessage();
              //toggleCloseModal();
            }
          }}
        >
          <IconChatbot />
        </button>
      )}

      <button
        type="button"
        className="btn btn-light"
        onClick={handleSendMessage}
      >
        Send
      </button>
    </div>
  );
};

export default MessageInput;

import { AxiosResponse } from 'axios';
import { UserProfileDataPayload } from '../../@types/UserProfileTypes';
import { UserResponseType } from '../../@types/UserType';
import ApiUtil from '../../utils/apiUtil';

export default class ProfileService {
  async getUserProfile(): Promise<any> {
    const url = '/userDetails';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async updateUserProfile(
    payload: UserProfileDataPayload,
  ): Promise<UserResponseType> {
    const url = '/userDetails';
    const res: AxiosResponse = await ApiUtil.SendAsync<UserResponseType>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }
}

import { useSelector } from 'react-redux';
import useAction from '../../../components/hooks/useAction';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';

const TransactionReports = ({ onClose }: any) => {
  const toogleFilterModal = () => {
    document.body.classList.toggle('has-overlay');
    document.body.classList.toggle('chatbox-open');
  };
  const widRank = useSelector(widgetRankData);
  const { addWidgetType } = useAction();
  return (
    <>
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>Transaction Reports Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('TRANSACTION_REPORTS');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'nplwidget'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        ></div>
      </div>
    </>
  );
};

export default TransactionReports;

import { useSelector } from 'react-redux';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
//import useAction from '../../../components/hooks/useAction';
import { isEmpty } from 'lodash';
//import Select from 'react-select';
import { useEffect, useState } from 'react';
import { loanPortfolioIdData } from '../../../redux/selector/PortfolioSelector';
import useAction from '../../../components/hooks/useAction';
import { loanAllocationCalculation } from '../../../redux/selector/AllocationCalculationSelector';
import AppLoader from '../../../components/loaders/AppLoader';
//import { isLoading } from '../../../redux/selector/LoaderSelector';
import PortfolioService from '../../../services/portfolio.service';

const portfolioService = new PortfolioService();

const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('chatbox-open');
};

/*const wateroption = [
  { value: 'txc', label: 'txc' },
  { value: 'Other', label: 'Other' },
];*/

const LOANCollateralWidget = ({ onClose, loanCollateralWidgetIndex }: any) => {
  const widRank = useSelector(widgetRankData);
  const portfolioId = useSelector(loanPortfolioIdData);
  const {
    showModal,
    addAllocationDataLoan,
    addWidgetType,
    addPortfolioLoanCollateralWidgetData,
    removePortfolioLoanCollateralWidgetData,
  } = useAction();
  //const loader = useSelector(isLoading);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsData, setRowsData] = useState({} as any);
  const [checkedData, setCheckedData] = useState({} as any);
  let allocationObj = useSelector(loanAllocationCalculation);
  //const [headerKeys, setHeaderKeys] = useState([] as any);

  const getRowsData = (id: any, columnType: string) => {
    setIsLoading(true);
    portfolioService
      .getSummary(id, columnType)
      .then(({ data }) => {
        setIsLoading(false);
        if (isEmpty(data)) {
          //setHeaderKeys([]);
          setRowsData([]);
          addPortfolioLoanCollateralWidgetData({
            id: loanCollateralWidgetIndex,
            value: 'loan_collateral',
            widgetType: 'PORTFOLIO',
            selectedPortfolioId: id,
            rowsData: [],
          });
        } else {
          //setHeaderKeys(Object.keys(data));
          setRowsData(Object.entries(data?.statistics));
          addPortfolioLoanCollateralWidgetData({
            id: loanCollateralWidgetIndex,
            value: 'loan_collateral',
            widgetType: 'PORTFOLIO',
            selectedPortfolioId: id,
            rowsData: Object.entries(data),
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  const callAllocationRowCalculation = (obj: any) => {
    let tempObj = { ...allocationObj, ...obj };
    for (let val in tempObj) {
      if (tempObj[val]?.length <= 0) {
        delete tempObj[val];
      }
    }

    addAllocationDataLoan(tempObj);
    /*service
      .allocationRowCalculation(portfolioId, obj)
      .then(({ data }) => {
        setCalData(data);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
      });*/
  };

  useEffect(() => {
    if (portfolioId) {
      setCheckedData({});
      addAllocationDataLoan({});
      setRowsData([]);
      getRowsData(portfolioId, 'COLLATERAL');
    }
  }, [portfolioId]);

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>LOAN Collateral Data Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('LOAN_COLLATERAL');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'nplcollateral'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                removePortfolioLoanCollateralWidgetData(
                  loanCollateralWidgetIndex,
                );
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* <div className="col-lg-6 col-md-6 form-group">
            <Select
              className="w-100"
              classNamePrefix="react-select"
              options={wateroption}
              // defaultInputValue="ml"
              styles={{
                control: base => ({
                  ...base,
                }),
              }}
            />
          </div>*/}
          <div className="loan-portfolio-datalist mb-3">
            {/*<div className="calc-data text-end">
              {Object.entries(calData)?.map(x => (
                <div className="mb-2">
                  {x[0]}: {x[1]}
                </div>
              ))}
              </div>*/}
            <div className="loan-datalists">
              {rowsData?.length > 0 ? (
                <>
                  {rowsData?.map((rData: any) => {
                    return (
                      <div className="loan-data-items">
                        {!isEmpty(rData[1]) && (
                          <>
                            <h4>{rData[0]}</h4>
                            <div className="loan-items-list">
                              {Object?.entries(rData[1])?.map(
                                (valData: any) => {
                                  return (
                                    <div className="form-check my-2">
                                      <label
                                        htmlFor=""
                                        className="form-check-label"
                                      >
                                        {valData[0]} :- {valData[1]}
                                      </label>
                                      <input
                                        className="form-check-input"
                                        id="selectedAllocation"
                                        type="checkbox"
                                        onChange={() => {
                                          let tempObj = {
                                            ...checkedData,
                                          } as any;
                                          const allocationKey = rData[0];
                                          const allocationVal = valData[0];
                                          // const tempArray =
                                          //   tempObj[allocationKey] || [];

                                          if (allocationKey in tempObj) {
                                            if (
                                              allocationVal in
                                              tempObj[`${allocationKey}`]
                                            ) {
                                              let newTempObj =
                                                tempObj[`${allocationKey}`];

                                              delete newTempObj[
                                                `${allocationVal}`
                                              ];

                                              // if (
                                              //   Object?.keys(
                                              //     tempObj[`${allocationKey}`],
                                              //   )?.length <= 0
                                              // ) {
                                              //   delete tempObj[allocationKey];
                                              // }
                                            } else
                                              tempObj[allocationKey] = {
                                                ...tempObj[allocationKey],
                                                [allocationVal]: valData[1],
                                              };
                                          } else {
                                            tempObj[allocationKey] = {
                                              [allocationVal]: valData[1],
                                            };
                                          }

                                          callAllocationRowCalculation(tempObj);

                                          setCheckedData(tempObj);
                                        }}
                                      />
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-record">No Data Found!</div>
              )}
            </div>
          </div>
          {/* <div className="table-responsive mb-3">
            <table className="table" aria-label="sticky table">
              <tbody className="">
                {rowsData?.length > 0 ? (
                  <>
                    {rowsData?.map((rData: any) => {
                      return (
                        <tr className="">
                          {!isEmpty(rData[1]) && (
                            <>
                              <td>{rData[0]}</td>
                              <td>
                                {Object?.entries(rData[1])?.map(
                                  (valData: any) => {
                                    return (
                                      <p>
                                        <input
                                          id="selectedAllocation"
                                          type="checkbox"
                                          onChange={() => {
                                            let tempObj = {
                                              ...checkedData,
                                            } as any;
                                            const allocationKey = rData[0];
                                            const allocationVal = valData[0];
                                            const tempArray =
                                              tempObj[allocationKey] || [];

                                            if (
                                              !tempObj[allocationKey]?.includes(
                                                allocationVal,
                                              )
                                            ) {
                                              tempObj[allocationKey] = [
                                                ...tempArray,
                                                allocationVal,
                                              ];
                                            } else {
                                              let valIndex = tempObj[
                                                allocationKey
                                              ]?.findIndex(
                                                (x: string | number) =>
                                                  x === allocationVal,
                                              );
                                              tempObj[allocationKey]?.splice(
                                                valIndex,
                                                1,
                                              );
                                            }

                                            callAllocationRowCalculation(
                                              tempObj,
                                            );

                                            setCheckedData(tempObj);
                                          }}
                                        />
                                        {valData[0]} :- {valData[1]}
                                      </p>
                                    );
                                  },
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-record">No Data Found!</div>
                )}
              </tbody>
              <tfoot>
                {Object.entries(calData)?.map(x => (
                  <p>
                    {x[0]}: {x[1]}
                  </p>
                ))}
              </tfoot>
            </table>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LOANCollateralWidget;

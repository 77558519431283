/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { IconAdminUser, IconComposer, IconContent, IconDashboard, IconMusic, IconPlayList, IconPromoCode, IconRole, IconStyleTag, IconSubscription, IconUser, IconUsers } from './icons';
import 'react-pro-sidebar/dist/css/styles.css';
// import LogoImage from '../../../assets/images/logo.svg';
//import LogoImage from '../../../assets/images/er-blue.jpg';
import AvatarImage from '../../../assets/images/avtar.svg';
// import SmallLogoImage from '../../../assets/images/small-logo.svg';
import {
  Iconclose,
  IconCollapse,
  IconCollapseClose,
  IconDashboard,
  IconLogout,
  IconNewsfeed,
  IconNotification,
  IconPortfolio,
  IconProduct,
  IconSettings,
} from './Icons';
import Avatar from 'react-avatar';
import { useContext, useState } from 'react';
import DarkLogo from '../../../assets/images/new-logo-dark.svg';
import LightLogo from '../../../assets/images/new-logo-light.svg';
import SmallDarkLogo from '../../../assets/images/small-new-logo-dark.svg';
import SmallLightLogo from '../../../assets/images/small-new-logo-light.svg';
import { Button } from 'reactstrap';
//import useAction from '../hooks/useAction';
import { userData } from '../../redux/selector/UserSelector';
import { themeTypeValue } from '../../redux/selector/ThemeSelector';
import { AppContext } from '../../../App';
import useAction from '../hooks/useAction';
//import NotificationService from '../../services/notification.service';
import NotificationModal from '../modals/NotificationModal';
//const notificationService = new NotificationService();

// import { NavLink } from 'reactstrap';

const Sidebar = () => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const user = useSelector(userData);
  const themeTypeData = useSelector(themeTypeValue);
  const { removeNplPortfolioId, removeLoanPortfolioId } = useAction();
  const { logoutUser } = useAction();
  //const [notificationCount, setNotificationCount] = useState(0);
  const routeName = window.location.pathname.split('/')[1];
  const notiCount: number | 0 =
    Number(localStorage.getItem('notificationCountER')) || 0;

  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const toggleShowNotificationModal = () => {
    setShowNotificationModal(!showNotificationModal);
  };

  const toggleSideBar = () => {
    removeNplPortfolioId();
    removeLoanPortfolioId();
    if (window.innerWidth > 992) return;
    context.toggleSideBar();
  };
  //create a custom function that will change menucollapse state from false to true and true to false

  /*useEffect(() => {
    notificationService.getNotificationCount().then(({ data }) => {
      setNotificationCount(data);
    });
  }, []);*/

  return (
    <>
      {/* <MetisMenu content={content} LinkComponent={RouterLink} clasNameLink="nav-item" classNameContainer="sidebar-menu" activeLinkFromLocation /> */}
      {/* active="true" */}
      <ProSidebar
        collapsed={context.sideBar}
        toggled={context.sideBar}
        breakPoint="lg"
        onToggle={() => context.toggleSideBar()}
      >
        <SidebarHeader>
          <Link to={'/'}>
            {context.sideBar ? (
              // <img src={SmallLogoImage} alt="" height={20} />
              <img
                src={themeTypeData === 'dark' ? SmallDarkLogo : SmallLightLogo}
                alt=""
                height={40}
              />
            ) : (
              <img
                src={themeTypeData === 'dark' ? DarkLogo : LightLogo}
                alt=""
                height={60}
              />
            )}
          </Link>
          <Button
            type="button"
            onClick={() => context.toggleSideBar()}
            className="btn-close d-lg-none"
          >
            <Iconclose fill="#fff" width={15} height={15} />
          </Button>
        </SidebarHeader>
        {/* <button onClick={() => collapseSidebar()}>Collapse</button> */}
        <SidebarContent>
          <Menu iconShape="square">
            <span
              onClick={() => {
                navigate('/dashboard');
                toggleSideBar();
              }}
            >
              <MenuItem
                active={routeName === 'dashboard' ? true : false}
                icon={<IconDashboard />}
              >
                Dashboard
              </MenuItem>
            </span>
            <span
              onClick={() => {
                navigate('/portfolio');
                toggleSideBar();
              }}
            >
              <MenuItem
                active={routeName === 'portfolio' ? true : false}
                icon={<IconPortfolio />}
              >
                Portfolio
              </MenuItem>
            </span>
            <span
              onClick={() => {
                navigate('/newsfeed');
                toggleSideBar();
              }}
            >
              <MenuItem
                active={routeName === 'newsfeed' ? true : false}
                icon={<IconNewsfeed />}
              >
                Newsfeed
              </MenuItem>
            </span>
            <span
              onClick={() => {
                navigate('/products');
                toggleSideBar();
              }}
            >
              <MenuItem
                active={routeName === 'products' ? true : false}
                icon={<IconProduct />}
              >
                Products
              </MenuItem>
            </span>
            {/*<span
              onClick={() => {
                navigate('/trading');
                toggleSideBar();
              }}
            >
              <MenuItem
                active={routeName === 'trading' ? true : false}
                icon={<IconTrading />}
              >
                Trading
              </MenuItem>
            </span>*/}

            {/*<MenuItem
              icon={<IconUser />}
              active={routeName === 'update-profile' ? true : false}
            >
              Update Profile
            </MenuItem>*/}

            {/* <SubMenu title="Components" icon={''}>
      <MenuItem>Component 1</MenuItem>
      <MenuItem>Component 2</MenuItem>
    </SubMenu> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            <span
              onClick={() => {
                // navigate('/dashboard');
                toggleSideBar();
                toggleShowNotificationModal();
              }}
            >
              <MenuItem
                suffix={
                  notiCount > 0 ? (
                    <span className="badge bg-danger">{notiCount}</span>
                  ) : (
                    <span></span>
                  )
                }
                icon={<IconNotification />}
              >
                Notifications
              </MenuItem>
            </span>

            <MenuItem icon={<IconSettings />}>Settings</MenuItem>
            <span onClick={() => context.toggleSideBar()}>
              <MenuItem
                className="d-lg-block d-none"
                icon={
                  <Button className="closemenu bg-transparent border-0">
                    {context.sideBar ? <IconCollapseClose /> : <IconCollapse />}
                  </Button>
                }
              >
                Collapse
              </MenuItem>
            </span>

            <span
              onClick={() => {
                navigate('/update-profile');
                toggleSideBar();
              }}
            >
              <MenuItem className="profils-item">
                {' '}
                <Avatar src={AvatarImage} size="32" round={true} />
                <div className="profiles-avatar">
                  <p>{user.name}</p>
                  <span>{user.email}</span>
                </div>
              </MenuItem>
            </span>
            <span
              onClick={() => {
                logoutUser();
              }}
            >
              <MenuItem
                icon={
                  <Button className=" bg-transparent border-0">
                    <IconLogout />
                  </Button>
                }
              >
                Logout
              </MenuItem>
            </span>

            {/* <SubMenu title="Components" icon={''}>
      <MenuItem>Component 1</MenuItem>
      <MenuItem>Component 2</MenuItem>
    </SubMenu> */}
          </Menu>
        </SidebarFooter>
      </ProSidebar>
      {showNotificationModal && (
        <NotificationModal
          modal={showNotificationModal}
          toggle={toggleShowNotificationModal}
        />
      )}
    </>
  );
};

export default Sidebar;

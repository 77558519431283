import { AppStateType } from '../reducers';

export const portfolioCreditWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.creditWidgetData;
};

export const portfolioNplWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.nplWidgetData;
};

export const portfolioNplCollateralWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.nplCollateralWidgetData;
};

export const portfolioNplLoanWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.nplLoanWidgetData;
};

export const portfolioLoanWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.loanWidgetData;
};

export const portfolioLoanCollateralWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.loanCollateralWidgetData;
};

export const portfolioLoanLoanWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.loanLoanWidgetData;
};

export const portfolioReceivableWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.receivableWidgetData;
};

export const portfolioReceivableCollateralWidgetSelector = (
  state: AppStateType,
) => {
  return state.PortfolioWidgetsReducer.receivableCollateralWidgetData;
};

export const portfolioReceivableLoanWidgetSelector = (state: AppStateType) => {
  return state.PortfolioWidgetsReducer.receivableLoanWidgetData;
};

export const allPortfolioWidgetsSelector = (state: AppStateType) => {
  return [
    ...state.PortfolioWidgetsReducer.creditWidgetData,
    ...state.PortfolioWidgetsReducer.nplWidgetData,
    ...state.PortfolioWidgetsReducer.nplCollateralWidgetData,
    ...state.PortfolioWidgetsReducer.nplLoanWidgetData,
    ...state.PortfolioWidgetsReducer.loanWidgetData,
    ...state.PortfolioWidgetsReducer.loanCollateralWidgetData,
    ...state.PortfolioWidgetsReducer.loanLoanWidgetData,
    ...state.PortfolioWidgetsReducer.receivableWidgetData,
    ...state.PortfolioWidgetsReducer.receivableCollateralWidgetData,
    ...state.PortfolioWidgetsReducer.receivableLoanWidgetData,
  ];
};

import { useSelector } from 'react-redux';
import Select from 'react-select';
import useAction from '../../../components/hooks/useAction';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';

const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('chatbox-open');
};
const wateroption = [
  { value: 'Call option', label: 'Call option' },
  { value: 'Put option', label: 'Put option' },
];

const DerivativeCustomizationWidget = ({ onClose }: any) => {
  // const slider = useRef<any>();
  const widRank = useSelector(widgetRankData);
  const { addWidgetType } = useAction();

  return (
    <>
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>Derivative Customization Widget</h2>
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('DERIVATIVE_CUSTOMIZATION');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'derivative'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <div className="row">
            <div className="col-lg-5 col-md-6 form-group">
              <label className="mb-0">Derivative type</label>
              <Select
                className="w-100"
                classNamePrefix="react-select"
                options={wateroption}
                // defaultInputValue="ml"
                styles={{
                  control: base => ({
                    ...base,
                  }),
                }}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table" aria-label="sticky table">
              <thead>
                <tr>
                  <th scope="col"> Claim Id </th>
                  <th scope="col">Amount</th>
                  <th scope="col">Expiry</th>
                  <th scope="col">Observation start</th>
                  <th scope="col">Observation end</th>
                </tr>
              </thead>
              <tbody className="">
                <tr className="">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive">
            <table className="table" aria-label="sticky table">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Composition</th>
                  <th scope="col">Expiry</th>
                  <th scope="col">Total amount</th>
                  <th scope="col">Expiry</th>
                  <th scope="col">Repayment probability</th>
                  <th scope="col">Premium</th>
                  <th scope="col">Payout</th>
                  <th scope="col">Multiple</th>
                  <th scope="col">AR</th>
                  <th scope="col">Spread</th>
                </tr>
              </thead>
              <tbody className="">
                <tr className="">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DerivativeCustomizationWidget;

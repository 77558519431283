import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const ScatterGeoMapLineChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (xAxis: string, yAxis: string) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);

    let modifiedArray = [
      {
        lon: xAxisArray,
        lat: yAxisArray,
        mode: 'lines',
        line: {
          width: 1,
          color: 'red',
        },
        type: 'scattergeo',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis && selectedYAxis) {
      handleXandYaxisKeys(selectedXAxis, selectedYAxis);
    }
  }, [selectedXAxis, selectedYAxis]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select Longitude</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Latitude</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedXAxis && selectedYAxis && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Scatter Geo Map',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              showlegend: false,
              geo: {
                projection: {
                  type: 'orthographic',
                  rotation: {
                    lon: -100,
                    lat: 40,
                  },
                },
                showocean: true,
                oceancolor: 'rgb(0, 255, 255)',
                showland: true,
                landcolor: 'rgb(230, 145, 56)',
                showlakes: true,
                lakecolor: 'rgb(0, 255, 255)',
                showcountries: true,
                lonaxis: {
                  showgrid: true,
                  gridcolor: 'rgb(102, 102, 102)',
                },
                lataxis: {
                  showgrid: true,
                  gridcolor: 'rgb(102, 102, 102)',
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ScatterGeoMapLineChart;

import { AppStateType } from '../reducers';

export const searchedChatQuery = (state: AppStateType) =>
  state.ChatQueryReducer.chatQuery;

export const searchedNplChatQuery = (state: AppStateType) =>
  state.ChatQueryReducer.nplChatQuery;

export const searchedLoanChatQuery = (state: AppStateType) =>
  state.ChatQueryReducer.loanChatQuery;

export const searchedReceivableChatQuery = (state: AppStateType) =>
  state.ChatQueryReducer.receivableChatQuery;

import { userDetailsConstant } from '../constant';

const { SUCCESS_USER_DETAILS } = userDetailsConstant;

export const addUserDetails = (userDetails: any) => {
  return {
    type: SUCCESS_USER_DETAILS,
    payload: { userDetails },
  };
};

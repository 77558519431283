import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import NotificationService from '../../services/notification.service';
import useAction from '../hooks/useAction';
import TablePagination from '@mui/material/TablePagination';

const notificationService = new NotificationService();

const NotificationModal = ({ modal, toggle }: any) => {
  //const loadSuggestions = debounce(promiseOptions, 1000);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const {
    showModal,
    addDashboardTasksData,
    showLoading,
    hideLoading,
  } = useAction();
  const [notificationListData, setNotificationListData] = useState([] as any);

  useEffect(() => {
    showLoading();
    const payload = {
      limit: pageLimit,
      page: page,
    };
    notificationService
      .getNotificationList(payload)
      .then(({ data }) => {
        setNotificationListData(data.notification);
        setTotalRecords(data.totalCount);
        setPageLimit(Number(payload.limit));
        hideLoading();
      })
      .catch(() => {
        showModal('Something went wrong!');
        hideLoading();
      });
  }, [pageLimit, page]);

  const handleChangePage = (_: any, value: any) => {
    setPage(value + 1);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Modal
      className="modal-md"
      isOpen={modal}
      centered={true}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} style={{ color: 'black' }}>
        Notification
      </ModalHeader>

      <ModalBody className="pb-0">
        <ul className="notification-list list-unstyled">
          {notificationListData?.length > 0
            ? notificationListData.map((notfyData: any) => (
                <li
                  className={`${
                    notfyData.isRead
                      ? 'read-notification'
                      : 'unread-notification'
                  } item-notification`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    notificationService.updateNotification(notfyData.id);
                    navigate('/dashboard');
                    toggle();

                    addDashboardTasksData({
                      id: 0,
                      value: 'task',
                      widgetType: 'DASHBOARD',
                      //taskListData: data.sharedPortfolio,
                    });
                  }}
                >
                  <p className="notification-title">{notfyData.title}</p>
                  <p className="notification-desc">{notfyData.description}</p>
                  <p className="notification-time">
                    {moment(notfyData.createdAt).format('LT')}
                  </p>
                </li>
              ))
            : 'No Record Found!'}
          <TablePagination
            className="unread-notification"
            rowsPerPageOptions={[10, 50, 75, 100]}
            component="div"
            count={totalRecords}
            rowsPerPage={pageLimit}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </ul>
      </ModalBody>
    </Modal>
  );
};

export default NotificationModal;

import { IconChat } from './Icons';
//import { useState, useEffect } from 'react';
//import io from 'socket.io-client';
import Messages from './messages/Messages';
import MessageInput from './messages/MessageInput';
import useAction from '../hooks/useAction';
import { Nav, NavItem, NavLink } from 'reactstrap';
//import { useSelector } from 'react-redux';
//import { widgetTypeData } from '../../redux/selector/WidgetTypeSelector';
import WebSocketHoc from '../../Hoc/WebSocketHoc';
import classNames from 'classnames';
import { useState } from 'react';

const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('chatbox-open');
};
const toogleCloseModal = () => {
  document.body.classList.remove('has-overlay');
  document.body.classList.remove('chatbox-open');
};

const PublicChat = ({ webSocket, setWebSocketConnection }: any) => {
  const { addWidgetType, removeWidgetReferenceId } = useAction();
  const [activeTab, setActiveTab] = useState('CHAT');

  /*const widgetName = useSelector(widgetTypeData);

  const selectedRowsData = useSelector(
    (state: any) => state.SelectedRowsReducer.selectedRows,
  );*/

  /*useEffect(() => {
    const WebSocket: any = io('https://backender.azurewebsites.net/', {
      //path: '/api/socket.io',
      transportOptions: {
        polling: {
          extraHeaders: {
            'x-er-auth-token': localStorage.getItem('_ERFTKEN'),
          },
        },
      },
    });

    if (widgetName === 'CREDIT') {
      WebSocket.on('askForRow', ({ messageId }: any) => {
        if (selectedRowsData?.length <= 0) {
          showModal('Please Select atleast 1 row!');
        }
        if (selectedRowsData.length > 0) {
          WebSocket.emit('getCalculation', {
            messageId: messageId,
            rowData: selectedRowsData,
          });
        }
      });
    }

    setSocket(WebSocket);

    return () => WebSocket.close();
  }, [setSocket, selectedRowsData, widgetName]);*/

  return (
    <>
      {/* <div className="overlay"></div> */}
      <button
        type="button"
        className="btn btn-info chat-btn"
        onClick={() => {
          toogleFilterModal();
          addWidgetType('MAIN');
        }}
      >
        <IconChat /> <span>Chat Now</span>
      </button>
      <div className="main-chatbox">
        <button
          type="button"
          className="btn btn-icon btn-primary chat-close-btn"
          onClick={() => {
            toogleCloseModal();
            removeWidgetReferenceId();
          }}
        >
          X
        </button>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === 'CHAT' })}
              onClick={() => {
                setActiveTab('CHAT');
              }}
            >
              Chat
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === 'HISTORY' })}
              onClick={() => {
                setActiveTab('HISTORY');
              }}
            >
              History
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === 'FEEDBACK' })}
              onClick={() => {
                setActiveTab('FEEDBACK');
              }}
            >
              Feedback
            </NavLink>
          </NavItem>
        </Nav>
        {webSocket ? (
          <>
            <Messages
              socket={webSocket}
              setWebSocketConnection={setWebSocketConnection}
            />
            <MessageInput socket={webSocket} />
          </>
        ) : (
          <div className="chat-content">Not Connected</div>
        )}
      </div>
    </>
  );
};

export default WebSocketHoc(PublicChat);

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { Button, FormGroup, Input } from 'reactstrap';
import useAction from '../../../components/hooks/useAction';
import useDebounce from '../../../components/hooks/useDebounce';
import AppLoader from '../../../components/loaders/AppLoader';
//import { userData } from '../../../redux/selector/UserSelector';
import {
  IconChatbot,
  Iconclose,
  IconGridView,
  IconListView,
  IconSearch,
} from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { chatData } from '../../../redux/selector/ChatSelector';
import { dashboardMoodyNewsFeedWidgetDataSelector } from '../../../redux/selector/DashboardWidgetSelector';
import { moodyNewsFeedWidgetsDataSelector } from '../../../redux/selector/NewsFeedWidgetsSelector';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import { widgetReferenceIdData } from '../../../redux/selector/WidgetReferenceIdSelector';
import TablePagination from '@mui/material/TablePagination';
import NewsService from '../../../services/news.service';

const newsService = new NewsService();

const ReadMore = ({ children }: any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? (
        <p
          dangerouslySetInnerHTML={{
            __html: text.slice(0, 300),
          }}
        ></p>
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
      )}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? '...read more' : ' show less'}
      </span>
    </p>
  );
};

interface MoodyNewsfeedsWidgetProps {
  onClose: () => void;
  newsWidgetIndex: number;
  type: string;
}

const MoodyNewsfeedsWidget = ({
  onClose,
  newsWidgetIndex,
  type,
}: MoodyNewsfeedsWidgetProps) => {
  // const slider = useRef<any>();

  const toogleFilterModal = () => {
    document.body.classList.toggle('has-overlay');
    document.body.classList.toggle('chatbox-open');
  };
  const settings = {
    dots: true,
    infinite: true,
    nav: false,
    // draggable: false,
    speed: 500,
    // fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // swipe: false,
  };
  const [viewType, setViewType] = useState('list');
  const [newsArray, setNewsArray] = useState<any[]>([]);
  const [newsEventId, setNewsEventId] = useState('');
  const [searchParams, setSearchParams] = useState('');
  const {
    showModal,
    addWidgetType,
    addMoodyNewsFeedData,
    addWidgetReferenceId,
    removeMoodyNewsFeedData,
    addDashboardMoodyNewsFeedData,
    removeDashboardMoodyNewsFeedData,
  } = useAction();
  //const [isSearching, setIsSearching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  //const [paginationToken, setPaginationToken] = useState('');
  const widRank = useSelector(widgetRankData);
  const chatMsg = useSelector(chatData);

  const [pageLimit, setPageLimit] = useState<number>(30);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const moodyNewsFeedData = useSelector(moodyNewsFeedWidgetsDataSelector);
  const dashboardMoodyNewsFeedData = useSelector(
    dashboardMoodyNewsFeedWidgetDataSelector,
  );

  const firstRender = useRef<boolean>(true);

  const debouncedUserInput = useDebounce(searchParams, 1000);
  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      updateWidgetData();
    } else {
      if (newsWidgetIndex === widgetReferenceIdValue) {
        updateWidgetData();
      }
    }
  }, [
    moodyNewsFeedData[newsWidgetIndex]?.q,
    dashboardMoodyNewsFeedData[newsWidgetIndex]?.q,
  ]);

  const updateWidgetData = () => {
    if (type === 'NEWSFEED') {
      let newsWidgetData = moodyNewsFeedData.find(
        (x: any) => x.id === newsWidgetIndex,
      );

      if (newsWidgetData) {
        setSearchParams(newsWidgetData.q);
        setNewsArray(newsWidgetData.newsArray);
        setNewsEventId(newsWidgetData.newsEventId);
      }
    }

    if (type === 'DASHBOARD') {
      let newsWidgetData = dashboardMoodyNewsFeedData.find(
        (x: any) => x.id === newsWidgetIndex,
      );

      if (newsWidgetData) {
        setSearchParams(newsWidgetData.q);
        setNewsArray(newsWidgetData.newsArray);
        setNewsEventId(newsWidgetData.newsEventId);
      }
    }
  };

  useEffect(() => {
    let searchValue = [];
    if (newsWidgetIndex === widgetReferenceIdValue) {
      for (let chat in chatMsg) {
        let filteredVal = chatMsg[chat]
          ?.filter((a: any) => a.widgetType.toUpperCase() === 'MOODY_NEWS_FEED')
          ?.reverse();
        searchValue.unshift(filteredVal);
      }

      if (searchValue[0]?.[0]?.isSearch === true) {
        setSearchParams(searchValue[0]?.[0]?.message || '');
      } /*else {
      setSearchParams(searchValue[1]?.[0]?.message || '');
    }*/
    }

    //setIsSearching(true);
  }, [chatMsg]);

  useEffect(() => {
    getNewsData();
  }, [debouncedUserInput, pageLimit, page]);

  /*const handleNewsClick = async (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };*/

  const handleSearchChange = (e: any) => {
    let queryValue: string = e.target.value;
    setSearchParams(queryValue);
  };

  const handleChangePage = (_: any, value: any) => {
    setPage(value + 1);
  };

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getNewsData = async () => {
    setIsLoading(true);
    const payload: any = {
      query:
        searchParams
          ?.trim()
          ?.split(' ')
          ?.join(' AND ') || '',
      limit: pageLimit,
      page: page,
      //paginationToken: paginationToken,
      order: 'asc',
    };
    if (!payload.query) {
      delete payload.query;
    }
    newsService
      .getMoodyNewsWidgetData(payload)
      .then(data => {
        //setIsSearching(false);

        setNewsArray(data.data.personalizesNews.slots);
        setNewsEventId(data.data.personalizesNews.event_id);
        setTotalRecords(data.data.totalCount);
        setPageLimit(Number(payload.limit));
        //setPaginationToken(data.data.paginationToken);
        if (type === 'NEWSFEED') {
          addMoodyNewsFeedData({
            id: newsWidgetIndex,
            widgetType: 'MOODY_NEWS_FEED',
            newsArray: data.data.personalizesNews.slots,
            newsEventId: data.data.personalizesNews.event_id,
            //paginationToken: data.data.paginationToken,
            q: searchParams,
            value: 'moody_news',
          });
        }

        if (type === 'DASHBOARD') {
          addDashboardMoodyNewsFeedData({
            id: newsWidgetIndex,
            widgetType: 'MOODY_NEWS_FEED',
            newsArray: data.data.personalizesNews.slots,
            newsEventId: data.data.personalizesNews.event_id,
            //paginationToken: data.data.paginationToken,
            q: searchParams,
            value: 'moody_news',
          });
        }

        setIsLoading(false);
      })
      .catch(err => {
        //setIsSearching(false);

        setIsLoading(false);
        if (type === 'NEWSFEED') {
          addMoodyNewsFeedData({
            id: newsWidgetIndex,
            widgetType: 'MOODY_NEWS_FEED',
            newsArray: [],
            //paginationToken: '',
            newsEventId: '',
            q: searchParams,
            value: 'moody_news',
          });
        }

        if (type === 'DASHBOARD') {
          addDashboardMoodyNewsFeedData({
            id: newsWidgetIndex,
            widgetType: 'MOODY_NEWS_FEED',
            newsArray: [],
            newsEventId: '',
            //paginationToken: '',
            q: searchParams,
            value: 'moody_news',
          });
        }

        showModal(err?.response?.data?.message);
      });
  };

  return (
    <>
      {isLoading && <AppLoader title="" />}
      <div className="newsfeed-widget common-widget">
        <div className="news-widget-header">
          <div className="widget-title-header common-widget-header flexbox">
            <h2>Moody Newsfeed</h2>
            <div className="d-flex gap-3 py-2 py-lg-0">
              <button
                type="button"
                className="btn-icon chat-mike"
                onClick={() => {
                  toogleFilterModal();
                  addWidgetType('MOODY_NEWS_FEED');
                  addWidgetReferenceId(newsWidgetIndex);
                  if (
                    widRank &&
                    widRank.widgetRank &&
                    widRank?.widgetRank?.slots?.length > 0
                  ) {
                    callTelemetryRecord(
                      'widgets',
                      widRank?.widgetRank?.event_id,
                      widRank?.widgetRank?.slots[0]?.id,
                      ['chat'],
                    );
                  }
                }}
              >
                <IconChatbot index={'derivative'} />
              </button>
              <button
                type="button"
                className="btn-icon chat-mike"
                onClick={() => {
                  onClose();
                  if (type === 'NEWSFEED') {
                    removeMoodyNewsFeedData(newsWidgetIndex);
                  }

                  if (type === 'DASHBOARD') {
                    removeDashboardMoodyNewsFeedData(newsWidgetIndex);
                  }
                  if (
                    widRank &&
                    widRank.widgetRank &&
                    widRank?.widgetRank?.slots?.length > 0
                  ) {
                    callTelemetryRecord(
                      'widgets',
                      widRank?.widgetRank?.event_id,
                      widRank?.widgetRank?.slots[0]?.id,
                      ['close'],
                    );
                  }
                }}
              >
                <Iconclose />
              </button>
            </div>
          </div>
          <div className="flexbox-c newsfeed-search">
            <FormGroup className="position-relative search-news">
              <Input
                placeholder="Search"
                type="search"
                value={searchParams}
                onChange={e => {
                  handleSearchChange(e);
                  if (
                    widRank &&
                    widRank.widgetRank &&
                    widRank?.widgetRank?.slots?.length > 0
                  ) {
                    callTelemetryRecord(
                      'widgets',
                      widRank?.widgetRank?.event_id,
                      widRank?.widgetRank?.slots[0]?.id,
                      ['type'],
                    );
                  }
                }}
              />
              <Button type="button" className="search-icon">
                <IconSearch />
              </Button>
            </FormGroup>
            <div className="grid-list-btn">
              <button
                type="button"
                className={`btn-icon grid-btn${
                  viewType === 'grid' ? ' active' : ''
                }`}
                onClick={() => {
                  setViewType('grid');
                }}
              >
                <IconGridView />
              </button>
              <button
                type="button"
                className={`btn-icon grid-btn${
                  viewType === 'list' ? ' active' : ''
                }`}
                onClick={() => {
                  setViewType('list');
                }}
              >
                <IconListView />
              </button>
            </div>
          </div>
          <div
            className={`news-widget${viewType === 'grid' ? ' grid-view' : ''}`}
            onClick={() => {
              if (
                widRank &&
                widRank.widgetRank &&
                widRank?.widgetRank?.slots?.length > 0
              ) {
                callTelemetryRecord(
                  'widgets',
                  widRank?.widgetRank?.event_id,
                  widRank?.widgetRank?.slots[0]?.id,
                  ['click'],
                );
              }
            }}
          >
            <div className={`row${viewType === 'grid' ? ' row-g-10' : ' '}`}>
              {newsArray?.length ? (
                newsArray?.map((news: any) => (
                  <div
                    className={`newsfeed-item${
                      viewType === 'grid' ? ' col-md-4 col-sm-6' : ' col-md-12'
                    }`}
                  >
                    {/* <Slider ref={slider} {...settings}>
                                <div key={1}>
                                </div>
                                <div key={2}>
                                </div>
                            </Slider> */}

                    <Slider {...settings}>
                      <div key={news?.id} className="main-slick-items">
                        <div
                          className="news-inner-item"
                          onClick={() => {
                            if (
                              widRank &&
                              widRank.widgetRank &&
                              widRank?.widgetRank?.slots?.length > 0
                            ) {
                              callTelemetryRecord(
                                'moody',
                                newsEventId,
                                news?.id,
                                ['click'],
                              );
                            }
                          }}
                        >
                          <div className="newsfeed-description">
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: news?.news?.title,
                              }}
                            ></h2>
                            <ReadMore>{news?.news?.body}</ReadMore>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                ))
              ) : (
                <div className="no-record">No Result Found</div>
              )}
            </div>
            <TablePagination
              rowsPerPageOptions={[30, 50, 75, 100]}
              component="div"
              count={totalRecords}
              rowsPerPage={pageLimit}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MoodyNewsfeedsWidget;

import React from 'react';
import Sidebar from '../../../components/public/Sidebar';
import PublicHeader from '../../../components/public/PublicHeader';
import {
  IconPlus,
} from '../../../components/public/Icons';
import GridLayout from 'react-grid-layout';
// import PublicPageHeader from '../../../components/public/PublicPageHeader';
import TradesWidget from '../dashboard/TradesWidget';

const Trading = () => {
  const layout = [
    { i: 'a', x: 0, y: 0, w: 100, h: 5.6 },
    { i: 'b', x: 0, y: 5.5, w: 100, h: 2 },
    { i: 'c', x: 0, y: 9, w: 6, h: 1.88 },
    { i: 'd', x: 0, y: 5.5, w: 8, h: 6 },
  ];
  /*const { search } = useLocation();
  useEffect(() => {
    let { token } = queryString.parse(search);

    if (!localStorage.getItem('_ERFTKEN')) {
      localStorage.setItem('_ERFTKEN', token as string);
    }
  }, [search]);*/

  return (
    <>
      <Sidebar />
      <div className="page-content ">
        <PublicHeader />
        {/* <PublicPageHeader /> */}
        <div className="page-middle-content">
          {/* <div className="sliding-content">
            <div className="holder">
              <div className="news">
                <Nav className="newscroll">
                  <NavItem>
                    <label htmlFor="">IDXESGL</label>
                    134139.967 825
                    <span className="text-success">
                      {' '}
                      0.24% <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">LQ45</label>
                    945.068
                    <span className="text-danger">
                      0.03% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX30</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX80</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div> */}
          <div className="mb-3">
            <button type="button" className="btn btn-secondary">
              <IconPlus /> Add
            </button>
          </div>
          <div className="main-widget">
            <GridLayout
              className="layout sortable-list row-g-24 row w-100"
              layout={layout}
              cols={12}
              // rowHeight={30}
              width={1680}
            >
              <div key="a">
                <TradesWidget />
              </div>
            </GridLayout>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trading;

import { AxiosResponse } from 'axios';
import ApiUtil from '../../utils/apiUtil';

export default class WidgetRankService {
  async getWidgetRank(): Promise<any> {
    const url = '/widgets/rank';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
}

import { AxiosResponse } from 'axios';

import {
  NewsWidgetDataPayload,
  NewsWidgetResponse,
} from '../../@types/NewsWidgetType';
import ApiUtil from '../../utils/apiUtil';

export default class NewsService {
  async getNewsWidget(
    payload: NewsWidgetDataPayload,
  ): Promise<NewsWidgetResponse> {
    const url = '/widgets/news';
    const res: AxiosResponse = await ApiUtil.SendAsync<NewsWidgetResponse>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getMoodyNewsWidgetData(payload: any): Promise<any> {
    const url = `/news?limit=${payload.limit}&page=${payload.page}&order=${
      payload.order
    }${payload.query ? `&query=${payload.query}` : ''}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
}

import { chatQueryConstant } from '../constant/chatQuery.constant';

const {
  ADD_CHAT_QUERY,
  ADD_LOAN_CHAT_QUERY,
  ADD_NPL_CHAT_QUERY,
  ADD_RECEIVABLE_CHAT_QUERY,
} = chatQueryConstant;

export const addChatQuery = (query: any) => {
  return {
    type: ADD_CHAT_QUERY,
    payload: { query },
  };
};

export const addNplChatQuery = (query: any) => {
  return {
    type: ADD_NPL_CHAT_QUERY,
    payload: { query },
  };
};

export const addLoanChatQuery = (query: any) => {
  return {
    type: ADD_LOAN_CHAT_QUERY,
    payload: { query },
  };
};

export const addReceivableChatQuery = (query: any) => {
  return {
    type: ADD_RECEIVABLE_CHAT_QUERY,
    payload: { query },
  };
};

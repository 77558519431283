import { isArray, isNumber, isObject, isString } from 'lodash';
import { JsonToFormDataType } from '../../@types/UtilType';
import PortfolioService from '../services/portfolio.service';

const portfolioService = new PortfolioService();

export const _SET_GlobaColor = ({
  colorVar,
  colorValue,
}: {
  colorVar: string;
  colorValue: string;
}) => {
  const r = document.querySelector('body') as any;
  // Set the value of variable --blue to another value (in this case "lightblue")
  r?.style.setProperty(colorVar, colorValue);
};

export const hexToRGB = (h: string) => {
  let r: string | number = 0;
  let g: string | number = 0;
  let b: string | number = 0;

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return 'rgb(' + +r + ',' + +g + ',' + +b + ')';
};

export const hexToRGBCustom = (h: string) => {
  let r: string | number = 0;
  let g: string | number = 0;
  let b: string | number = 0;

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return +r + ',' + +g + ',' + +b;
};

const convertJsonToFormData = (data: JsonToFormDataType) => {
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    const value = data[key];

    if (isString(value) || isNumber(value)) {
      formData.append(key, value as string & number);
    } else if (isArray(value)) {
      formData.append(key, JSON.stringify(value));
    } else if (isObject(value)) {
      formData.append(key, value as Blob, value.name);
    }
  });

  return formData;
};
export default convertJsonToFormData;

export const functionNames = [
  { value: 'AMORTIZATION', label: 'Amortization' },
  { value: 'CAGR', label: 'CAGR' },
  { value: 'ROI', label: 'ROI' },
  { value: 'CDR', label: 'CDR' },
  { value: 'CLC', label: 'CLC' },
  { value: 'DTI', label: 'DTI' },
  { value: 'YIELD', label: 'YIELD' },
  { value: 'RECOVERY_RATE', label: 'Recovery Rate' },
  { value: 'PAYMENT_IMPAIRMENT', label: 'Payment Impairment' },
  { value: 'NAR', label: 'NAR' },
  { value: 'CPR', label: 'CPR' },
  { value: 'MIN_OC', label: 'MIN_OC' },
  { value: 'CLS', label: 'CLS' },
];

export const keyNames = [
  { value: 'memberId', label: 'Member Id' },
  { value: 'principal', label: 'Principal' },
  { value: 'noOfPayments', label: 'No of Payments' },
  { value: 'rate', label: 'Rate' },
  { value: 'investment', label: 'Investment' },
  { value: 'earnings', label: 'Earnings' },
  { value: 'installment', label: 'Installment' },
  { value: 'beginningAmount', label: 'Beginning Amount' },
  { value: 'beginningValue', label: 'Beginning Value' },
  { value: 'endingValue', label: 'Ending Value' },
  { value: 'numberOfPeriods', label: 'Number of Periods' },
];

export const colTypes = [
  { value: 'COLLATERAL', label: 'Collateral' },
  { value: 'LOAN', label: 'Loan' },
];

export const CONSTANT_LOAN_FIELD = [
  'originationDate',
  'maturityDate',
  'principal',
  'rate',
  'counterpartyNum',
  'commencementDate',
  'collateralLatestValuation',
];

export const CONSTANT_COLLATERAL_FIELD = [
  'type',
  'Location',
  'latestValuationAmount',
  'latestValuationDate',
];

export const chartTypesArray = [
  {
    label: 'Scatter Chart',
    value: 'scatter',
  },
  {
    label: 'Line Chart',
    value: 'line',
  },
  {
    label: 'Bar Chart',
    value: 'bar',
  },
  {
    label: 'Pie',
    value: 'pie',
  },
  {
    label: 'Box',
    value: 'box',
  },
  {
    label: 'Histogram',
    value: 'histogram',
  },
  {
    label: '2D-Histogram',
    value: 'histogram2d',
  },
  {
    label: 'Violin',
    value: 'violin',
  },
  {
    label: 'Contour',
    value: 'contour',
  },
  {
    label: 'Heatmap',
    value: 'heatmap',
  },
  {
    label: 'Ternary Scatter',
    value: 'scatterternary',
  },

  {
    label: 'Polar Bar',
    value: 'barpolar',
  },

  {
    label: 'Scatter Polar',
    value: 'scatterpolar',
  },
  {
    label: 'Sunburst',
    value: 'sunburst',
  },
  {
    label: 'Treemap',
    value: 'treemap',
  },
  {
    label: 'Sankey',
    value: 'sankey',
  },
  {
    label: 'Waterfall',
    value: 'waterfall',
  },
  {
    label: 'Candlestick',
    value: 'candlestick',
  },
  {
    label: 'ohlc',
    value: 'ohlc',
  },
  {
    label: 'Funnel',
    value: 'funnel',
  },
  {
    label: 'Funnel Area',
    value: 'funnelarea',
  },

  {
    label: 'Scatter 3D',
    value: 'scatter3d',
  },
  {
    label: 'Surface 3D',
    value: 'surface',
  },
  {
    label: 'Mesh 3D',
    value: 'mesh3d',
  },
  {
    label: 'Line 3D',
    value: 'scatter3d_lines',
  },
  {
    label: 'Cone 3D',
    value: 'cone',
  },
  {
    label: 'Streamtube',
    value: 'streamtube',
  },
  {
    label: 'Scatter Map Box',
    value: 'scattermapbox',
  },
  {
    label: 'Scatter Geo Map Line',
    value: 'scattergeo',
  },
  // {
  //   label: 'Scatter Geo Map Bubble',
  //   value: 'scattergeo_bubble',
  // },
  {
    label: 'Scatter Plot Map',
    value: 'scattergeo_scatter',
  },
];

export const categories = [
  { value: 'NPL', label: 'NPL' },
  { value: 'LOAN', label: 'LOAN' },
];
export const callTelemetryRecord = async (
  personalizer: string,
  event_id: string,
  slotId: string,
  actions: string[],
) => {
  const payload: any = {
    records: [
      {
        personalizer: personalizer,
        rank_event_id: event_id,
        slot_id: slotId,
        actions: actions,
      },
    ],
  };
  await portfolioService.telemetryRecord(payload);
};

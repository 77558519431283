import { Form, Formik } from 'formik';
import { get } from 'lodash';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import {
  UserProfileData,
  UserProfileDataPayload,
} from '../../../../@types/UserProfileTypes';
import useAction from '../../../components/hooks/useAction';
import PublicHeader from '../../../components/public/PublicHeader';
import Sidebar from '../../../components/public/Sidebar';
import {
  creditsArray,
  options,
  organizations,
  positions,
  productsArray,
  roles,
  sexes,
  sizes,
  types,
} from '../../public/forms.constant';
import { useLocation } from 'react-router';
//import { isLoading } from '../../../redux/selector/LoaderSelector';
import { useSelector } from 'react-redux';
import AppLoader from '../../../components/loaders/AppLoader';
import { userDetailsData } from '../../../redux/selector/UserDetailsSelector';
import ProfileService from '../../../services/profile.service';

const profileService = new ProfileService();

const UpdateProfile = () => {
  const { showModal, addUserDetails } = useAction();
  //const loader = useSelector(isLoading);
  const profileDetails = useSelector(userDetailsData);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: UserProfileData = {
    role: profileDetails ? get(profileDetails, 'role') : '',
    position: profileDetails ? get(profileDetails, 'position', '') : '',
    type: profileDetails ? get(profileDetails, 'type', '') : '',
    sex: profileDetails ? get(profileDetails, 'sex', '') : '',
    sizes: profileDetails ? get(profileDetails, 'sizes', '') : '',
    organization: profileDetails ? get(profileDetails, 'organization', '') : '',
    functions: profileDetails
      ? get(profileDetails, 'function', []).map((x: string) => {
          return {
            label: x,
            value: x,
          };
        })
      : [],
    products: profileDetails
      ? get(profileDetails, 'products', []).map((x: string) => {
          return {
            label: x,
            value: x,
          };
        })
      : [],
    credits: profileDetails
      ? get(profileDetails, 'credits', []).map((x: string) => {
          return {
            label: x,
            value: x,
          };
        })
      : [],
  };
  const { search } = useLocation();

  useEffect(() => {
    let { token } = queryString.parse(search);

    if (token) {
      localStorage.setItem('_ERFTKEN', token as string);
    }
  }, [search]);

  const submitHandler = (values: UserProfileData) => {
    setIsLoading(true);
    const payload: UserProfileDataPayload = {
      role: values.role,
      position: values.position,
      type: values.type,
      sex: values.sex,
      sizes: values.sizes,
      organization: values.organization,
      functions: values.functions.map(x => x.value),
      products: values.products.map(x => x.value),
      credits: values.credits.map(x => x.value),
    };

    profileService
      .updateUserProfile(payload)
      .then(({ data }: any) => {
        setIsLoading(false);
        showModal('Submitted Successfully', true);
        addUserDetails(data.userDetails);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <Sidebar />
      <div className="page-content">
        <PublicHeader />
        <div className="page-header user-header">
          <div className="header-title">Registration Form</div>
        </div>
        <div className="page-middle-content profile-page-content">
          <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            enableReinitialize
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-sm-6 col-12 form-group">
                      <label>Role</label>
                      <select
                        id="role"
                        className="form-control"
                        name="role"
                        value={values.role}
                        onChange={e => {
                          setFieldValue('role', e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {roles?.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-6 col-12 form-group">
                      <label>Position</label>
                      <select
                        id="position"
                        className="form-control"
                        name="position"
                        value={values.position}
                        onChange={e => {
                          setFieldValue('position', e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {positions?.map((position, index) => (
                          <option key={index} value={position}>
                            {position}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Functions</label>
                      <Select
                        instanceId={'asynCreate'}
                        options={options}
                        isMulti
                        classNamePrefix="custom-select"
                        noOptionsMessage={() => 'No Data'}
                        placeholder={'Select Functions'}
                        value={values.functions}
                        isClearable={true}
                        onChange={e => {
                          setFieldValue('functions', e);
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Type</label>
                      <select
                        id="type"
                        name="type"
                        className="form-control"
                        value={values.type}
                        onChange={e => {
                          setFieldValue('type', e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {types?.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Sex</label>
                      <select
                        id="sex"
                        name="sex"
                        className="form-control"
                        value={values.sex}
                        onChange={e => {
                          setFieldValue('sex', e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {sexes?.map((sex, index) => (
                          <option key={index} value={sex}>
                            {sex}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Products</label>
                      <Select
                        instanceId={'asynCreate'}
                        options={productsArray}
                        isMulti
                        classNamePrefix="custom-select"
                        noOptionsMessage={() => 'No Data'}
                        placeholder={'Select Products'}
                        value={values.products}
                        isClearable={true}
                        onChange={e => {
                          setFieldValue('products', e);
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Sizes</label>
                      <select
                        id="size"
                        className="form-control"
                        name="size"
                        value={values.sizes}
                        onChange={e => {
                          setFieldValue('sizes', e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {sizes?.map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Credits</label>
                      <Select
                        instanceId={'asynCreate'}
                        options={creditsArray}
                        isMulti
                        noOptionsMessage={() => 'No Data'}
                        // className="custom-select"
                        classNamePrefix="custom-select"
                        placeholder={'Select Credits'}
                        value={values.credits}
                        isClearable={true}
                        onChange={e => {
                          setFieldValue('credits', e);
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-12 form-group">
                      <label>Organization</label>
                      <select
                        id="organization"
                        name="organization"
                        className="form-control"
                        value={values.organization}
                        onChange={e => {
                          setFieldValue('organization', e.target.value);
                        }}
                      >
                        <option value="">Please Select</option>
                        {organizations?.map((organization, index) => (
                          <option key={index} value={organization}>
                            {organization}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button type="submit" className="btn-info btn">
                    Submit
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;

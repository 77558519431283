import { Formik } from 'formik';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import IngestionService from '../../services/ingestion.service';
import useAction from '../hooks/useAction';
import { reloadPortfolioData } from '../../redux/selector/ReloadPortfolioSelector';

const ingService = new IngestionService();

const SessionModal = ({ modal, toggle, fileKey }: any) => {
  const { showModal, showLoading, hideLoading } = useAction();
  const shouldReload = useSelector(reloadPortfolioData);
  const recentMessage = JSON.parse(
    localStorage.getItem('latestMessage') || '{}',
  ) as any;

  const promiseOptions: any = async (inputValue: any) => {
    if (inputValue) {
      return new Promise(resolve => {
        ingService.searchUser(inputValue).then(res => {
          const data = res.data.map((a: any) => {
            return {
              value: get(a, 'user_id'),
              label: get(a, 'name'),
            };
          });

          resolve(data);
        });
      });
    } else {
      return [];
    }
  };

  //const loadSuggestions = debounce(promiseOptions, 1000);
  return (
    <Modal
      className="modal-md"
      isOpen={modal}
      centered={true}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Send Session</ModalHeader>

      <ModalBody className="pb-0">
        <Formik
          initialValues={{
            userData: {
              value: '',
              label: '',
            },
            introduction: '',
          }}
          onSubmit={values => {
            if (fileKey) {
              if (shouldReload) {
                showLoading();
                const payload = {
                  userId: values.userData.value,
                  introduction: values.introduction,
                  portfolioId: fileKey,
                  userPortfolioId: recentMessage.userPortfolioId,
                };

                ingService
                  .sharePortfolio(payload)
                  .then(() => {
                    showModal('Shared successfully!', true);
                    hideLoading();
                    toggle();
                  })
                  .catch(err => {
                    hideLoading();
                    showModal(
                      err?.response?.data?.message || 'Something went wrong!',
                    );
                  });
              } else {
                showModal('Please save portfolio!');
              }
            } else {
              showModal('Please upload/select portfolio!');
            }
          }}
        >
          {renderProps => {
            const { values, setFieldValue, handleSubmit } = renderProps;

            return (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        id="introduction"
                        style={{ color: 'black' }}
                        placeholder="Instruction here..."
                        rows={3}
                        name="introduction"
                        value={values.introduction}
                        onChange={e => {
                          setFieldValue('introduction', e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group csv-key-group">
                      <AsyncSelect
                        instanceId={'asynCreate'}
                        //defaultOptions={userDefaultOptions}
                        //isDisabled={editRecord ? true : false}
                        noOptionsMessage={() => 'No User Found!'}
                        cacheOptions
                        isLoading={false}
                        loadOptions={promiseOptions}
                        isMulti={false}
                        placeholder={'Search user...'}
                        value={values.userData}
                        isClearable={true}
                        onChange={e => {
                          setFieldValue('userData', e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button className="btn btn-dark btn-mw" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default SessionModal;

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const ScatterGeoChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (xAxis: string, yAxis: string) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);

    let modifiedArray = [
      {
        lon: xAxisArray,
        lat: yAxisArray,
        mode: 'markers+text',
        marker: {
          size: 7,

          line: {
            width: 1,
          },
        },
        type: 'scattergeo',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis && selectedYAxis) {
      handleXandYaxisKeys(selectedXAxis, selectedYAxis);
    }
  }, [selectedXAxis, selectedYAxis]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select Longitude</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Latitude</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedXAxis && selectedYAxis && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Scatter Map Box',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              font: {
                family: 'Droid Serif, serif',
                size: 6,
              },
              titlefont: {
                size: 16,
              },
              geo: {
                // scope: 'north america',
                resolution: 50,
                lonaxis: {
                  range: [-130, -55],
                },
                lataxis: {
                  range: [40, 70],
                },
                showrivers: true,
                rivercolor: '#fff',
                showlakes: true,
                lakecolor: '#fff',
                showland: true,
                landcolor: '#EAEAAE',
                countrycolor: '#d3d3d3',
                countrywidth: 1.5,
                subunitcolor: '#d3d3d3',
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ScatterGeoChart;

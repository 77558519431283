import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const ViolinChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  // const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (yAxis: string) => {
    let tempData = [...chartData];

    //   let xAxisArrayValue = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);

    let modifiedArray = [
      {
        //   x: xAxisArray,
        y: yAxisArray,
        points: 'none',
        box: {
          visible: true,
        },
        line: {
          color: 'green',
        },
        meanline: {
          visible: true,
        },
        type: 'violin',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedYAxis) {
      handleXandYaxisKeys(selectedYAxis);
    }
  }, [selectedYAxis]);
  return (
    <>
      <div className="row">
        {/* <div className="col-md-6">
          <label>Select X Axis</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div> */}

        <div className="col-md-6">
          <label>Select Y</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedYAxis && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Violin',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,

              // xaxis: {
              //   title: selectedXAxis,
              // },

              yaxis: {
                zeroline: false,
                title: selectedYAxis,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ViolinChart;

import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import Checkbox from '@mui/material/Checkbox';
//import { visuallyHidden } from '@mui/utils';
import IngestionService from '../../../services/ingestion.service';
import { Input } from 'reactstrap';
import {
  IconChatbot,
  IconSend,
  Iconclose,
} from '../../../components/public/Icons';
import useAction from '../../../components/hooks/useAction';
import {
  CONSTANT_COLLATERAL_FIELD,
  CONSTANT_LOAN_FIELD,
  callTelemetryRecord,
  colTypes,
} from '../../../helpers/utils.helper';
import { useSelector } from 'react-redux';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import axios from 'axios';
import config from '../../../../config';
import SessionModal from '../../../components/modals/SessionModal';
import AppLoader from '../../../components/loaders/AppLoader';
import PortfolioService from '../../../services/portfolio.service';
import NormalizeService from '../../../services/normalize.service';
import { get } from 'lodash';
import { components } from 'react-select';
import AddActualFieldNameModal from '../../../components/modals/AddActualFieldNameModal';
import { searchedChatQuery } from '../../../redux/selector/ChatQuerySelector';
import { widgetReferenceIdData } from '../../../redux/selector/WidgetReferenceIdSelector';
import { reloadPortfolioData } from '../../../redux/selector/ReloadPortfolioSelector';

const ingService = new IngestionService();
const portfolioService = new PortfolioService();
const normalizeService = new NormalizeService();

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const tovoOption = [
  { value: 'TOVO', label: 'TOVO' },
  { value: 'CREDIT_REPORT', label: 'Equifax Credit Report' },
  { value: 'WARREN_GROUP', label: 'Warren Group' },
  { value: 'MORNING_STAR', label: 'Morningstar' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(order: Order, orderBy: Key) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: any) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: any;
  onSelectAllClick: any;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
  setIsLoading: any;
  setSelectedExportType: any;
  setHeadCellsArray: any;
  colTypeArray: any;
  fileKey: any;
  setColTypeArray: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    onSelectAllClick,
    //order,
    orderBy,
    numSelected,
    rowCount,
    //onRequestSort,
    setIsLoading,
    setSelectedExportType,
    setHeadCellsArray,
    colTypeArray,
    setColTypeArray,
    fileKey,
  } = props;
  // const createSortHandler = (property: string) => (
  //   event: React.MouseEvent<unknown>,
  // ) => {
  //   onRequestSort(event, property);
  // };

  const [normalizeHeaderOptions, setNormalizeHeaderOptions] = React.useState(
    [] as any,
  );
  const [selectedHeaderValue, setSelectedHeaderValue] = React.useState('');
  const [editFieldModal, setEditFieldModal] = React.useState(false);
  const [editRecord, setEditRecord] = React.useState(false);

  const { showModal } = useAction();

  const toggleEditFieldModal = (data: any) => {
    if (!editFieldModal && data) {
      setEditRecord(!editFieldModal ? data : false);
      setEditFieldModal(!editFieldModal);
    } else {
      setEditRecord(false);
      setEditFieldModal(!editFieldModal);
    }
  };

  const updateNormalizeHeaderList = () => {
    //setIsLoading(true);
    normalizeService
      .getNormalizeHeader()
      .then(({ data }) => {
        const result = data.fieldExist.map((a: any) => {
          return {
            value: get(a, 'actualFieldName'),
            label: get(a, 'actualFieldName'),
          };
        });
        setNormalizeHeaderOptions(result);
        //setIsLoading(false);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        //setIsLoading(false);
      });
  };

  const handleHeaderDropdown = (
    e: any,
    synonymsField: string,
    headerArrayVal: any,
  ) => {
    setSelectedHeaderValue(e.target.value);
    setIsLoading(true);

    let tempHeaderArray: any = [...headerArrayVal];

    const payload = {
      actualFieldName: e.target.value,
      synonymsField: synonymsField,
    };

    normalizeService
      .addSynonymsField(payload)
      .then(() => {
        //changeFileDataEXR();

        //tempHeaderArray[synonymsField].actualFieldName = e.target.value;
        const keyIndex = tempHeaderArray?.findIndex(
          (a: any) => a.synonymsField === synonymsField,
        );
        tempHeaderArray[keyIndex].actualFieldName = e.target.value;
        tempHeaderArray[keyIndex].id = e.target.value;
        tempHeaderArray[keyIndex].label = e.target.value;

        setHeadCellsArray(tempHeaderArray);
        showModal('Updated', true);
        setSelectedHeaderValue('');
        setSelectedExportType('');
        //setSelectedFuncName([]);
        setIsLoading(false);

        updateNormalizeHeaderList();
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  const colTypeFunc = (
    headerData: any,
    keyName: any,
    synKeyName: any,
    keyValue?: any,
  ) => {
    let headerArray = [];
    let obj: any = { ...colTypeArray };
    //let keyVal = [];

    for (let key in headerData) {
      if (headerData[key].actualFieldName === '') {
        headerArray.push({
          synonymsField: key,
          actualFieldName: '',
          visibleKey: key,
          categoryType: headerData[key].type,
          id: key,
          numeric: false,
          disablePadding: true,
          label: key,
        });
      }

      if (headerData[key].actualFieldName !== '') {
        headerArray.push({
          synonymsField: key,
          actualFieldName: headerData[key].actualFieldName,
          visibleKey: headerData[key].actualFieldName,
          categoryType: headerData[key].type,
          id: headerData[key].actualFieldName,
          numeric: false,
          disablePadding: true,
          label: headerData[key].actualFieldName,
        });
      }
    }

    /*keyVal = uniq(headerArray.map(x => x.visibleKey));

for (let i of keyVal) {
obj[i] = getAbstractField(i);
}*/

    for (let k = 0; k < headerArray.length; k++) {
      obj[headerArray[k].visibleKey] = headerArray[k].categoryType;
    }

    obj = { ...obj, ...colTypeArray };

    obj[keyName] = keyValue;
    const payload = {
      columnType: {
        [synKeyName]: keyValue,
      },
    };

    portfolioService
      .addColumnType(fileKey, payload)
      .then(() => {
        showModal('Updated Successfully', true);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
      });

    setColTypeArray(obj);

    return obj;
  };

  React.useEffect(() => {
    updateNormalizeHeaderList();
  }, []);

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            sx={{ color: '#fff', padding: '5px', verticalAlign: 'bottom' }}
          >
            Select All
            <Checkbox
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all rows',
              }}
            />
          </TableCell>
          {headCells.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              //sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: '#fff', padding: '5px', minWidth: '150px' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  minHeight: '133px',
                }}
              >
                <select
                  id="npl-dropdown"
                  style={{
                    marginBottom: '20px',
                  }}
                  defaultValue={headCell.categoryType}
                  value={colTypeArray[headCell.visibleKey]}
                  className="form-control"
                  onChange={e => {
                    colTypeFunc(
                      headCells,
                      headCell.visibleKey,
                      headCell.synonymsField,
                      e.target.value,
                    );
                  }}
                >
                  <option value="OTHER">Please Select</option>
                  {colTypes.map(colValues => (
                    <option key={colValues.value} value={colValues.value}>
                      {colValues.label}
                    </option>
                  ))}
                </select>

                {headCell.actualFieldName === '' && (
                  <select
                    id="header-key"
                    style={{
                      marginBottom: '20px',
                    }}
                    value={selectedHeaderValue}
                    className="form-control"
                    onChange={e => {
                      handleHeaderDropdown(
                        e,
                        headCell.synonymsField,
                        headCells,
                      );
                    }}
                  >
                    <option value="">Please Select</option>
                    {normalizeHeaderOptions?.map((keyValues: any) => (
                      <option key={keyValues.value} value={keyValues.value}>
                        {keyValues.label}
                      </option>
                    ))}
                  </select>
                )}
                <TableSortLabel
                  active={orderBy === headCell.id}
                  //direction={orderBy === headCell.id ? order : 'asc'}
                  //onClick={createSortHandler(headCell.id)}
                  hideSortIcon
                  onDoubleClick={() => {
                    if (headCell.actualFieldName === '') {
                      toggleEditFieldModal(headCell);
                    }
                  }}
                  style={{ marginTop: 'auto' }}
                >
                  {headCell.synonymsField}
                  {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null} */}
                </TableSortLabel>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {editFieldModal && (
        <AddActualFieldNameModal
          modal={editFieldModal}
          toggle={toggleEditFieldModal}
          editRecord={editRecord}
          addSuccess={(synField: string, actField: string) => {
            //changeFileDataEXR();
            let tempHeaderArray: any = [...headCells];

            setSelectedHeaderValue(actField);
            const keyIndex = tempHeaderArray?.findIndex(
              (a: any) => a.synonymsField === synField,
            );
            tempHeaderArray[keyIndex].actualFieldName = actField;
            tempHeaderArray[keyIndex].id = actField;
            tempHeaderArray[keyIndex].label = actField;

            setHeadCellsArray(tempHeaderArray);

            setSelectedHeaderValue('');
            setSelectedExportType('');

            updateNormalizeHeaderList();
          }}
        />
      )}
    </>
  );
}

export default function CreditWidgetCopy({ creditIndex, onClose }: any) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('' as string);
  const [selected, setSelected] = React.useState([] as any);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [headCellsArray, setHeadCellsArray] = React.useState([] as any);
  const [tableArray, setTableArray] = React.useState([] as any);
  const widRank = useSelector(widgetRankData);
  const [selectedExportType, setSelectedExportType] = React.useState('');
  const [csvDownload, setCsvDownload] = React.useState(false);
  const taskWidgetPortfolioId = localStorage.getItem('taskPortfolioId');
  const [fileKey, setFileKey] = React.useState('');
  const [showSessionModal, setShowSessionModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState([] as any);
  const [fileNames, setFileNames] = React.useState([] as any);
  const [fileNamesPage, setFileNamesPage] = React.useState(1);
  const [isBottom, setIsBottom] = React.useState(false);

  //file dropdown
  const [allFiles, setAllFiles] = React.useState([] as any);
  const [selectedAllFile, setSelectedAllFile] = React.useState([] as any);
  const [allFilePage, setAllFilePage] = React.useState(1);

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState([] as any);
  const chatQueryData = useSelector(searchedChatQuery);
  const [colTypeArray, setColTypeArray] = React.useState({} as any);

  //Warren
  const [warrenGroupFiles, setWarrenGroupFiles] = React.useState([] as any);
  const [
    warrenGroupFilesInitialData,
    setWarrenGroupFilesInitialData,
  ] = React.useState([] as any);
  const [selectedWarrenFile, setSelectedWarrenFile] = React.useState([] as any);
  const [warrenPage, setWarrenPage] = React.useState(1);

  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);
  const shouldReload = useSelector(reloadPortfolioData);

  const {
    showModal,
    addWidgetType,
    removePortfolioCreditWidgetData,
    reloadPortfolio,
    //addPortfolioCreditWidgetData,
    addWidgetReferenceId,
    addChatQuery,
  } = useAction();

  React.useEffect(() => {
    if (chatQueryData !== '' && widgetReferenceIdValue === creditIndex) {
      setIsLoading(true);
      const payload = {
        portfolio_id:
          selectedOption?.value === 'WARREN_GROUP'
            ? selectedWarrenFile?.map(
                (a: { label: string; value: string }) => a?.value,
              )
            : fileKey,
        user_query: chatQueryData,
      };
      axios({
        method: 'post',
        url: `${config.ER_ML_URL}/portfolio/calculate`,
        data: payload,
      })
        .then(({ data }) => {
          if (
            data?.message !== 'file uploaded!' &&
            data?.message !== 'File is merged!'
          ) {
            addDefaultCategoryForHeaders(data.headingSchema, data);
            setFileKey(data?.portfolioId);
          } else {
            showModal(data?.message, true);
          }

          if (
            data?.message === 'file uploaded!' ||
            data?.message === 'File is merged!'
          ) {
            reloadPortfolio(shouldReload ? false : true);
            getFileNames();
          }
          // getFileNames();
          addChatQuery('');
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          showModal(err?.response?.data?.error);
          addChatQuery('');
        });
    }
  }, [chatQueryData]);

  const toggleShowSessionModal = () => {
    setShowSessionModal(!showSessionModal);
  };

  let csvData: any;
  const csvLink: any = React.useRef();

  React.useEffect(() => {
    if (csvData && csvLink.current && csvDownload) {
      csvLink.current.link.click();
      setCsvDownload(false);
    }
  }, [selectedExportType]);

  const handleUploadFile = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];

    if (file) {
      setIsLoading(true);

      try {
        const formData = new FormData();

        formData.append('uploaded_file', e.target.files[0]);

        ingService
          .uploadCreditWidgetFile(formData)
          .then(({ data }) => {
            //addDefaultCategoryForHeaders(data.headingSchema, data);
            showModal('Uploaded successfully!', true);
            getFileNames();
            setFileKey(data?.portfolioId);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            showModal('Opps! something went wrong');
          });
      } catch (e) {
        setIsLoading(false);
      }
    }
    e.target.value = null;
  };

  const addDefaultCategoryForHeaders = (headerData: any, allData: any) => {
    let headerArray = [];
    let obj: any = {};

    for (let key in headerData) {
      if (headerData[key].actualFieldName === '') {
        headerArray.push({
          synonymsField: key,
          actualFieldName: '',
          visibleKey: key,
          categoryType: headerData[key].type,
          id: key,
          numeric: false,
          disablePadding: true,
          label: key,
        });
      }

      if (headerData[key].actualFieldName !== '') {
        headerArray.push({
          synonymsField: key,
          actualFieldName: headerData[key].actualFieldName,
          visibleKey: headerData[key].actualFieldName,
          categoryType: headerData[key].type,
          id: headerData[key].actualFieldName,
          numeric: false,
          disablePadding: true,
          label: headerData[key].actualFieldName,
        });
      }
    }
    setHeadCellsArray(headerArray);

    for (let k = 0; k < headerArray.length; k++) {
      if (CONSTANT_LOAN_FIELD.includes(headerArray[k].visibleKey)) {
        obj[headerArray[k].visibleKey] = 'LOAN';
      } else if (
        CONSTANT_COLLATERAL_FIELD.includes(headerArray[k].visibleKey)
      ) {
        obj[headerArray[k].visibleKey] = 'COLLATERAL';
      } else {
        obj[headerArray[k].visibleKey] = headerArray[k].categoryType;
      }
    }

    /*for (let i of headerArray) {
obj[i] = getAbstractField(i);
}*/

    setColTypeArray(obj);

    selectRowsForCalculation(
      allData.data,
      allData.headingSchema,
      // allData,
      // headerArray,
    );
  };

  const headerFunc = (headerData: any) => {
    let headerArray = [];

    if (headerData && Object.keys(headerData)?.length > 0) {
      for (let key in headerData) {
        if (headerData[key].actualFieldName === '') {
          headerArray.push({
            synonymsField: key,
            actualFieldName: '',
            visibleKey: key,
            categoryType: headerData[key].type,
            id: key,
            numeric: false,
            disablePadding: true,
            label: key,
          });
        }

        if (headerData[key].actualFieldName !== '') {
          headerArray.push({
            synonymsField: key,
            actualFieldName: headerData[key].actualFieldName,
            visibleKey: headerData[key].actualFieldName,
            categoryType: headerData[key].type,
            id: headerData[key].actualFieldName,
            numeric: false,
            disablePadding: true,
            label: headerData[key].actualFieldName,
          });
        }
      }
    }
    return headerArray;
  };

  const selectRowsForCalculation = (
    rowArray: any,
    headerScObject: any,
    //allData: any,
    //updatedHeaderData: any,
  ) => {
    let result: any = {};
    let resultArray: any = [];
    let calValues: any = [];

    //const calHeader = headerFunc(headerScObject).map(a => a.visibleKey);
    const calHeader1 = headerFunc(headerScObject).map(a => a.synonymsField);

    //calHeader.push('index')
    const headArray = headerFunc(headerScObject);

    rowArray.forEach((item: any) => {
      const newObj: any = {};

      for (let itKey in item) {
        let keyIndex = headArray.findIndex(
          hdKey => hdKey.synonymsField === itKey,
        );
        if (keyIndex > -1) {
          newObj[`${headArray[keyIndex].synonymsField}`] = item[itKey];
        }
      }
      calValues.push(newObj);
    });

    calValues.forEach((item: any) => {
      calHeader1.forEach((key: any) => {
        result[key] = item[key];
      });
      //result['index'] = calIndex + 1;
      resultArray.push(result);
      result = {};
    });
    setTableArray(resultArray);

    // addPortfolioCreditWidgetData({
    //   id: creditIndex,
    //   value: 'credits',
    //   widgetType: 'PORTFOLIO',
    //   headersData: updatedHeaderData,
    //   allRows: resultArray,
    //   portfolioId: allData.portfolioId,
    //   //fileKey: allData?.key,
    //   // categoryData: colTypeArray,
    //   // selectedFile: fileId,
    //   //missingFields: allData?.includesColumn || [],
    // });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tableArray.map((n: any, index: number) => index);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: number | string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly (number | string)[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number | string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableArray.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(tableArray, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, tableArray],
  );

  const toogleFilterModal = () => {
    document.body.classList.toggle('has-overlay');
    document.body.classList.toggle('chatbox-open');
  };

  const exportTypes = [
    { label: 'CSV', value: 'data.csv' },
    { label: 'XLSX', value: 'data.xlsx' },
  ];

  React.useEffect(() => {
    if (taskWidgetPortfolioId) {
      getSelectedFileData(taskWidgetPortfolioId);
    }

    getFileNames();
    //getAllFilesList();
  }, []);

  const getAllFilesList = () => {
    const payload = {
      page: allFilePage,
      limit: 20,
    };
    ingService
      .getAllFiles(payload)
      .then(({ data }) => {
        setAllFilePage(allFilePage + 1);
        const allFilesData = data?.files?.map((x: any) => {
          return {
            label: x.fileName,
            value: x._id,
          };
        });

        if (allFiles.length) {
          setAllFiles([...allFiles, ...allFilesData]);
        } else {
          setAllFiles(allFilesData);
        }
      })
      .catch(() => {
        showModal('Someting went wrong');
      });
  };

  const getAllWarrenGroupFiles = () => {
    const payload = {
      page: warrenPage,
      limit: 20,
    };
    ingService
      .getWarrenGroupFilesList(payload)
      .then(({ data }) => {
        setWarrenPage(warrenPage + 1);

        const warrenData = data?.warrenGroupFile?.map((x: any) => {
          return {
            label: x.name,
            value: x.id,
          };
        });
        if (warrenGroupFiles.length) {
          setWarrenGroupFiles([...warrenGroupFiles, ...warrenData]);
          setWarrenGroupFilesInitialData([
            ...warrenGroupFilesInitialData,
            ...data?.warrenGroupFile,
          ]);
        } else {
          setWarrenGroupFiles(warrenData);
          setWarrenGroupFilesInitialData(data?.warrenGroupFile);
        }
      })
      .catch(() => {
        showModal('something went wrong');
      });
  };

  const getSelectedAllFileData = (file: string) => {
    setIsLoading(true);
    ingService
      .getAllFilesById(file)
      .then(({ data }) => {
        setIsLoading(false);
        let tempArray = [...tableArray];
        let schemaKeysObj = [
          ...headCellsArray,
          {
            id: 'indexLevel',
            numeric: true,
            disablePadding: true,
            label: 'indexLevel',
          },
        ];

        for (let i = 0; i < tempArray.length; i++) {
          if (selected.findIndex((x: number) => x === i) > -1) {
            tempArray[i] = {
              ...tempArray[i],
              indexLevel: data.amount,
            };
          } else {
            tempArray[i] = {
              ...tempArray[i],
              indexLevel: '-',
            };
          }
        }

        setTableArray(tempArray);
        setHeadCellsArray(schemaKeysObj);
        setSelectedExportType('');
      })
      .catch((err: any) => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  const getFileNames = () => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', `${config.ER_ML_URL}/portfolio/?page=1&limit=${20}`, true);
    xhr.setRequestHeader(
      'x-er-Auth-Token',
      localStorage.getItem('_ERFTKEN') as string,
    );

    xhr.send();
    xhr.onload = function() {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          const resData = JSON.parse(xhr.response)?.data;
          // setFileNamesPage(fileNamesPage + 1);
          setFileNamesPage(1);
          const fileNamesData = resData?.map((x: any) => {
            return {
              label: x.fileName,
              value: x._id,
            };
          });

          setFileNames(fileNamesData);
          setIsBottom(false);

          // if (fileNames.length) {
          //   setFileNames([...fileNames, ...fileNamesData]);
          // } else {
          //   setFileNames(fileNamesData);
          // }

          //setFileNames(resData);
        } else {
          showModal(`Request failed with status: ${xhr.status}`);
        }
      }
    };
  };

  const getFileNamesAfterSavePortfolio = () => {
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `${config.ER_ML_URL}/portfolio/?page=${
        fileNamesPage === 1 ? 2 : fileNamesPage
      }&limit=${20}`,
      true,
    );
    xhr.setRequestHeader(
      'x-er-Auth-Token',
      localStorage.getItem('_ERFTKEN') as string,
    );

    xhr.send();
    xhr.onload = function() {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          const resData = JSON.parse(xhr.response)?.data;
          if (fileNamesPage === 1) {
            setFileNamesPage(fileNamesPage + 2);
          } else {
            setFileNamesPage(fileNamesPage + 1);
          }

          if (resData.length < 20) {
            setIsBottom(true);
          }

          const fileNamesData = resData?.map((x: any) => {
            return {
              label: x.fileName,
              value: x._id,
            };
          });

          if (fileNames.length) {
            setFileNames([...fileNames, ...fileNamesData]);
          } else {
            setFileNames(fileNamesData);
          }

          //setFileNames(resData);
        } else {
          showModal(`Request failed with status: ${xhr.status}`);
        }
      }
    };
  };

  const getSelectedFileData = (file: string) => {
    setIsLoading(true);
    axios
      .get(`${config.ER_ML_URL}/portfolio/${file}`)
      .then(({ data }) => {
        setFileKey(file);
        setSelectedExportType('');
        //setSelectedFile(file);
        localStorage.removeItem('taskPortfolioId');

        addDefaultCategoryForHeaders(data.data.headingSchema, data.data);
        //setTableArray(data.data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  // const selectedPortofioDataMap = (data: any) => {
  //   let headerArray: {
  //     id: string;
  //     numeric: boolean;
  //     disablePadding: boolean;
  //     label: string;
  //   }[] = [];

  //   const firstHeaderData = Object.keys(data[0]);

  //   for (let i = 0; i < Object.keys(data[0])?.length; i++) {
  //     headerArray.push({
  //       id: firstHeaderData[i],
  //       numeric: false,
  //       disablePadding: true,
  //       label: firstHeaderData[i],
  //     });
  //   }

  //   setHeadCellsArray(headerArray);
  // };

  const callTovoApi = () => {
    setIsLoading(true);

    let newArr: any = [];
    const keys_to_keep = ['address1', 'city', 'state', 'zip', 'index'];

    for (let x of selected?.sort()) {
      newArr.push(tableArray[x]);
    }

    const result = newArr.map((e: any) => {
      const obj = {} as any;
      keys_to_keep.forEach(k => (obj[k] = e[k]?.toString() || ''));
      return obj;
    });

    const payload = {
      tovoData: result,
    };

    portfolioService
      .addTovoData(payload)
      .then(({ data }) => {
        let schemaKeysObj = [...headCellsArray];
        let tempArray = [...tableArray];

        for (let i = 0; i < data?.length; i++) {
          let valIndex = data[i].index - 1;

          if (valIndex > -1) {
            const tempObj: any = {
              ...data[i]?.data?.avm,
              ...data[i]?.data?.details,
              ...data[i]?.data?.estimated,
              ...data[i]?.data?.foreclosure,
              id: data[i]?.data?.id,
              ...data[i]?.data?.originalRequest,
              ...data[i]?.data?.propertyAddress,
            };

            const newObj: any = { ...tempArray[valIndex] };

            for (let k in tempObj) {
              schemaKeysObj = [
                ...schemaKeysObj,
                {
                  id: k,
                  numeric: false,
                  disablePadding: true,
                  label: k,
                  actualFieldName: k,
                },
              ];
              if (valIndex > -1) {
                //tempArray[valIndex] = { [k]: tempObj[k] };
                newObj[k] = tempObj[k];
              }
            }

            tempArray[valIndex] = newObj;
          }
        }

        setTableArray(tempArray);
        setHeadCellsArray(schemaKeysObj);
        setSelectedExportType('');

        setIsLoading(false);
      })
      .catch(err => {
        showModal(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  const callCustomCreditReport = () => {
    setIsLoading(true);

    let tempPayload = [];
    let newArr: any = [];

    for (let x of selected?.sort()) {
      newArr.push(tableArray[x]);
    }

    for (let i = 0; i < selected.length; i++) {
      const obj = {
        index: selected[i],
        consumers: {
          name: [
            {
              firstName: newArr[i].firstName?.toString(),
              lastName: newArr[i].lastName?.toString(),
            },
          ],
        },

        customerConfiguration: {},
      };

      tempPayload.push(obj);
    }

    const payload = {
      creditReportData: tempPayload,
    };

    portfolioService
      .addCustomCreditReport(payload)
      .then(({ data }) => {
        setIsLoading(false);

        //const selectedRowsArray = [...inputArray];
        let schemaKeysObj = [
          ...headCellsArray,
          {
            id: 'score',
            numeric: false,
            disablePadding: true,
            label: 'score',
            actualFieldName: 'score',
          },
        ];
        let tempArray = [...tableArray];

        for (let i = 0; i < data?.length; i++) {
          tempArray[data[i]?.index] = {
            ...tempArray[data[i]?.index],
            score: data[i]?.score,
          };
        }

        setTableArray(tempArray);
        setHeadCellsArray(schemaKeysObj);
        setSelectedExportType('');

        showModal('Updated!', true);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  const callWarrenGroupFilesMerge = () => {
    const selectedWarrenIds = selectedWarrenFile.map(
      (a: { label: string; value: string }) => a.value,
    );

    if (selectedWarrenIds?.length >= 2 && selectedWarrenIds?.length <= 10) {
      setIsLoading(true);

      const selectedBlobKeys = warrenGroupFilesInitialData
        .filter((item: any) => selectedWarrenIds.includes(item.id))
        .map((item: any) => item.blobKey);

      const payload = {
        portfolioIds: selectedBlobKeys,
      };

      ingService
        .mergeWarrenGroupFiles(payload)
        .then(({ message }) => {
          showModal(message, true);
          getFileNames();

          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          showModal(err?.response?.data?.error);
        });
    }

    if (selectedWarrenIds?.length < 2) {
      showModal('Please select atleast 2 items');
    }

    if (selectedWarrenIds?.length > 10) {
      showModal('Only 10 items are allowed');
    }
  };

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>Credit</h2>
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toggleShowSessionModal();
              }}
            >
              <IconSend />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();

                addWidgetReferenceId(creditIndex);

                addWidgetType('CREDIT');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'stocks'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();

                removePortfolioCreditWidgetData(creditIndex);
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <div className="row mb-4">
            <div className="col-md-6">
              {csvData && (
                <div className="row">
                  <div className="col-md-12 d-flex">
                    <div className="form-group csv-key-group">
                      <select
                        id="csv-key"
                        style={{
                          marginBottom: '20px',
                          marginRight: '10px',
                        }}
                        value={selectedExportType}
                        className="btn btn-primary"
                        onChange={e => {
                          if (e.target.value === '') {
                            showModal('Please select download type');
                          } else {
                            setSelectedExportType(e.target.value);
                            setCsvDownload(true);
                          }
                        }}
                      >
                        <option value="">Export</option>
                        {exportTypes?.map((keyValues: any) => (
                          <option key={keyValues.value} value={keyValues.value}>
                            {keyValues.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    {
                      <div className="col-auto d-none">
                        <CSVLink
                          ref={csvLink}
                          data={csvData}
                          filename={selectedExportType}
                        >
                          <button type="submit" className="btn btn-primary">
                            Export{' '}
                            {selectedExportType === 'data.csv' ? 'CSV' : 'XLSX'}
                          </button>
                        </CSVLink>
                      </div>
                    }
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-lg-6 col-md-6 form-group">
                  <label className="mb-0">Data Source</label>
                  <Select
                    instanceId={'asynCreate'}
                    className="w-100"
                    classNamePrefix="react-select"
                    options={tovoOption}
                    value={selectedOption}
                    isClearable={true}
                    onChange={e => {
                      if (
                        e.value !== 'WARREN_GROUP' &&
                        e.value !== 'MORNING_STAR'
                      ) {
                        if (selected?.length) {
                          setSelectedOption(e);
                        } else {
                          showModal('Please select at least 1 row!');
                          setSelectedOption([]);
                        }
                      }

                      if (e.value === 'WARREN_GROUP') {
                        setSelectedOption(e);
                        getAllWarrenGroupFiles();
                        setFileKey('');
                        setHeadCellsArray([]);
                        setTableArray([]);
                        setSelectedFile([]);
                        setSelectedAllFile([]);
                        setColTypeArray({});
                      }

                      if (e.value === 'MORNING_STAR') {
                        setSelectedOption(e);
                        getAllFilesList();
                      }
                    }}
                    // defaultInputValue="ml"
                    styles={{
                      control: base => ({
                        ...base,
                      }),
                    }}
                  />
                </div>
                <div className="col-auto">
                  <label className="pb-4 d-block"></label>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      if (selected?.length) {
                        if (selectedOption.value === 'TOVO') {
                          callTovoApi();
                        }

                        if (selectedOption.value === 'CREDIT_REPORT') {
                          callCustomCreditReport();
                        }
                      }
                    }}
                  >
                    Refresh list
                  </button>
                </div>

                {selectedOption.value === 'WARREN_GROUP' && (
                  <div
                  //className="col-lg-6 col-md-6 form-group"
                  >
                    <label>Select Warren Files</label>
                    {/*<select
                      id={`warren_allFiles_dropdown-${creditIndex}`}
                      value={selectedWarrenFile}
                      className="form-control"
                      onChange={e => {
                        setSelectedWarrenFile(e.target.value);
                        setFileKey(e.target.value);
                      }}
                    >
                      <option value="">Please Select</option>
                      {warrenGroupFiles?.map((cat: any) => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>*/}

                    <Select
                      className="w-100"
                      classNamePrefix="react-select"
                      noOptionsMessage={() => 'No Data'}
                      placeholder={'Select Warren Group File'}
                      options={warrenGroupFiles}
                      value={selectedWarrenFile}
                      isClearable={true}
                      isMulti
                      styles={{
                        control: base => ({
                          ...base,
                        }),
                      }}
                      onChange={e => {
                        setSelectedWarrenFile(e);
                      }}
                      onMenuScrollToBottom={() => {
                        getAllWarrenGroupFiles();
                      }}
                      components={{ Option }}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      // allowSelectAll={true}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        callWarrenGroupFilesMerge();
                      }}
                    >
                      Merge
                    </button>
                  </div>
                )}

                {selectedOption.value === 'MORNING_STAR' && (
                  <div
                  //className="col-lg-6 col-md-6 form-group"
                  >
                    <label>Select Files</label>
                    <Select
                      className="w-100"
                      classNamePrefix="react-select"
                      noOptionsMessage={() => 'No Data'}
                      placeholder={'Select File'}
                      options={allFiles}
                      value={selectedAllFile}
                      isClearable={true}
                      isMulti={false}
                      styles={{
                        control: base => ({
                          ...base,
                        }),
                      }}
                      onChange={e => {
                        if (selected?.length) {
                          setSelectedAllFile(e);
                          getSelectedAllFileData(e.value);
                        } else {
                          showModal('Please select atleast 1 row');
                        }
                      }}
                      onMenuScrollToBottom={() => {
                        getAllFilesList();
                      }}
                    />
                  </div>
                )}

                {/* 
                <div className="clearfix">
                  <div className="col-lg-6 col-md-6 form-group">
                    <label className="mb-0"></label>
                    <Select
                      instanceId={'asynCreate'}
                      className="w-100"
                      classNamePrefix="react-select"
                      options={warranOption}
                      isMulti
                      value={selectedWarrenOption}
                      isClearable={true}
                      onChange={e => {
                        setSelectedWarrenOption(e);
                        addWarrenFile(e);
                      }}
                      styles={{
                        control: base => ({
                          ...base,
                        }),
                      }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-lg-auto col-md-auto form-group">
                  <div className="input-group mb-0">
                    <label
                      className="input-group-text btn btn-primary"
                      htmlFor={`csvFileInput${creditIndex}`}
                    >
                      Upload portfolio
                    </label>
                    <Input
                      type="file"
                      className="form-control d-none"
                      id={`csvFileInput${creditIndex}`}
                      accept=".xlsx, .xls, .csv"
                      onChange={handleUploadFile}
                    />
                  </div>
                </div>

                <label>Select Portfolio</label>
                {/* <select
                  id={`fileNames_dropdown-${creditIndex}`}
                  value={selectedFile}
                  className="form-control"
                  onChange={e => {
                    if (e.target.value !== '') {
                      getSelectedFileData(e.target.value);
                      setSelectedOption([]);
                      setSelectedWarrenFile([]);
                    }
                  }}
                >
                  <option value="">Please Select</option>
                  {fileNames?.map((cat: any) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.fileName}
                    </option>
                  ))}
                </select> */}
                <Select
                  className="w-100"
                  classNamePrefix="react-select"
                  noOptionsMessage={() => 'No Data'}
                  placeholder={'Select Portfolio'}
                  options={fileNames}
                  value={selectedFile}
                  isClearable={true}
                  isMulti={false}
                  styles={{
                    control: base => ({
                      ...base,
                    }),
                  }}
                  onChange={e => {
                    setSelectedFile(e);

                    getSelectedFileData(e.value);
                    setSelectedOption([]);
                    setSelectedWarrenFile([]);
                  }}
                  onMenuScrollToBottom={() => {
                    if (!isBottom) {
                      getFileNamesAfterSavePortfolio();
                    }
                  }}
                />
                <p></p>
              </div>
            </div>
          </div>
          {headCellsArray.length > 0 && (
            <Box sx={{ width: '100%' }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750, color: '#fff' }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    headCells={headCellsArray}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={tableArray.length}
                    setIsLoading={setIsLoading}
                    setSelectedExportType={setSelectedExportType}
                    setHeadCellsArray={setHeadCellsArray}
                    colTypeArray={colTypeArray}
                    fileKey={fileKey}
                    setColTypeArray={setColTypeArray}
                  />

                  <TableBody>
                    {visibleRows.map((row: any, index) => {
                      const isItemSelected = isSelected(index);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={() => handleClick(index)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer', color: '#fff' }}
                        >
                          <TableCell
                            padding="checkbox"
                            style={{ padding: '5px' }}
                          >
                            <Checkbox
                              sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                  color: 'white',
                                },
                              }}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          {Object.values(row).map((val: any) => {
                            const rowValue = val === '' ? '-' : val;
                            return (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                sx={{ color: '#fff', padding: '5px' }}
                              >
                                {rowValue}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tableArray.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </div>
        {showSessionModal && (
          <SessionModal
            modal={showSessionModal}
            toggle={toggleShowSessionModal}
            fileKey={fileKey}
          />
        )}
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const Scatter3DChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [selectedZAxis, setSelectedZAxis] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (xAxis: string, yAxis: string, zAxis: string) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);
    let zAxisArray = tempData?.map(x => x[zAxis]);

    let modifiedArray = [
      {
        x: xAxisArray,
        y: yAxisArray,
        z: zAxisArray,
        mode: 'markers',
        marker: {
          size: 12,
          line: {
            color: 'rgba(217, 217, 217, 0.14)',
            width: 0.5,
          },
          opacity: 0.8,
        },
        type: 'scatter3d',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis || selectedYAxis || selectedZAxis) {
      handleXandYaxisKeys(selectedXAxis, selectedYAxis, selectedZAxis);
    }
  }, [selectedXAxis, selectedYAxis, selectedZAxis]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select X</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Y</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Z</label>
          <select
            id={`z-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedZAxis}
            className="form-control"
            onChange={e => {
              setSelectedZAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedXAxis || selectedYAxis || selectedZAxis) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Scatter 3D',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default Scatter3DChart;

import React, { useEffect, useState } from 'react';
//import Iframe from 'react-iframe';
import { useSelector } from 'react-redux';
import useAction from '../../../components/hooks/useAction';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
import {
  callTelemetryRecord,
  chartTypesArray,
} from '../../../helpers/utils.helper';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import Select from 'react-select';
import config from '../../../../config';
import axios from 'axios';
import AppLoader from '../../../components/loaders/AppLoader';
import { reloadPortfolioData } from '../../../redux/selector/ReloadPortfolioSelector';
import ScatterChart from '../../../components/charts/basicChart/ScatterChart';
import LineChart from '../../../components/charts/basicChart/LineChart';
import BarChart from '../../../components/charts/basicChart/BarChart';
import PieChart from '../../../components/charts/basicChart/PieChart';
import BoxChart from '../../../components/charts/statisticalChart/BoxChart';
import HistogramChart from '../../../components/charts/statisticalChart/HistogramChart';
import Histogram2DChart from '../../../components/charts/statisticalChart/Histogram2DChart';
import ViolinChart from '../../../components/charts/statisticalChart/ViolinChart';
import ContourChart from '../../../components/charts/scientificChart/ContourChart';
import HeatmapChart from '../../../components/charts/scientificChart/HeatmapChart';
import TernaryScatterChart from '../../../components/charts/scientificChart/TernaryScatterChart';
import PolarBarChart from '../../../components/charts/scientificChart/PolarBarChart';
import ScatterPolarChart from '../../../components/charts/scientificChart/ScatterPolarChart';
import SunburstChart from '../../../components/charts/basicChart/SunburstChart';
import TreemapChart from '../../../components/charts/basicChart/TreemapChart';
import SankeyChart from '../../../components/charts/basicChart/SankeyChart';
import WaterfallChart from '../../../components/charts/financialChart/WaterfallChart';
import CandleStickChart from '../../../components/charts/financialChart/CandleStickChart';
import OhlcChart from '../../../components/charts/financialChart/OhlcChart';
import FunnelChart from '../../../components/charts/financialChart/FunnelChart';
import FunnelAreaChart from '../../../components/charts/financialChart/FunnelAreaChart';
import Scatter3DChart from '../../../components/charts/3D-Chart/Scatter3DChart';
import Mesh3DChart from '../../../components/charts/3D-Chart/Mesh3DChart';
import Scatter3DLinesChart from '../../../components/charts/3D-Chart/Scatter3DLinesChart';
import Cone3DChart from '../../../components/charts/3D-Chart/Cone3DChart';
import StreamtubeChart from '../../../components/charts/3D-Chart/StreamtubeChart';
import Surface3DChart from '../../../components/charts/3D-Chart/Surface3DChart';
import ScatterMapBoxChart from '../../../components/charts/mapsChart/ScatterMapBoxChart';
import ScatterGeoMapLineChart from '../../../components/charts/mapsChart/ScatterGeoMapLineChart';
import ScatterGeoChart from '../../../components/charts/mapsChart/ScatterGeoChart';

export default function EmbeddedBrowser({
  onClose,
  embeddedBrowserWidgetIndex,
}: //embeddedBrowserWidgetIndex,
//type,
any) {
  const { addWidgetType, showModal } = useAction();
  const widRank = useSelector(widgetRankData);

  const [fileNames, setFileNames] = useState([] as any);
  const [selectedFile, setSelectedFile] = useState([] as any);
  const [fileNamesPage, setFileNamesPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [chartType, setChartType] = useState('');
  const [chartData, setChartData] = useState([] as any);
  const [axisKeys, setAxisKeys] = useState([] as any);
  const [isBottom, setIsBottom] = useState(false);

  const shouldReload = useSelector(reloadPortfolioData);

  const embedded_taskWidgetPortfolioId = localStorage.getItem(
    'embedded_taskPortfolioId',
  );

  const toogleFilterModal = () => {
    document.body.classList.toggle('has-overlay');
    document.body.classList.toggle('chatbox-open');
  };

  const getFileNames = () => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', `${config.ER_ML_URL}/portfolio/?page=1&limit=${20}`, true);
    xhr.setRequestHeader(
      'x-er-Auth-Token',
      localStorage.getItem('_ERFTKEN') as string,
    );

    xhr.send();
    xhr.onload = function() {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          const resData = JSON.parse(xhr.response)?.data;

          // setFileNamesPage(fileNamesPage + 1);
          setFileNamesPage(1);
          const fileNamesData = resData?.map((x: any) => {
            return {
              label: x.fileName,
              value: x._id,
            };
          });

          // if (fileNames.length) {
          //   setFileNames([...fileNames, ...fileNamesData]);
          // } else {
          setFileNames(fileNamesData);
          // }

          // setFileNames(resData);
        } else {
          showModal(`Request failed with status: ${xhr.status}`);
        }
      }
    };
  };

  const getFileNamesAfterSavePortfolio = () => {
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      `${config.ER_ML_URL}/portfolio/?page=${
        fileNamesPage === 1 ? 2 : fileNamesPage
      }&limit=${20}`,
      true,
    );
    xhr.setRequestHeader(
      'x-er-Auth-Token',
      localStorage.getItem('_ERFTKEN') as string,
    );

    xhr.send();
    xhr.onload = function() {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          const resData = JSON.parse(xhr.response)?.data;

          if (fileNamesPage === 1) {
            setFileNamesPage(fileNamesPage + 2);
          } else {
            setFileNamesPage(fileNamesPage + 1);
          }

          if (resData.length < 20) {
            setIsBottom(true);
          }

          const fileNamesData = resData?.map((x: any) => {
            return {
              label: x.fileName,
              value: x._id,
            };
          });

          if (fileNames.length) {
            setFileNames([...fileNames, ...fileNamesData]);
          } else {
            setFileNames(fileNamesData);
          }

          // setFileNames(resData);
        } else {
          showModal(`Request failed with status: ${xhr.status}`);
        }
      }
    };
  };

  const getSelectedFileData = (file: string) => {
    setIsLoading(true);
    axios
      .get(`${config.ER_ML_URL}/visualization/get-data/${file}`)
      .then(({ data }) => {
        // setSelectedFile(file);
        localStorage.removeItem('taskPortfolioId');

        const filteredData = data.data.filter((item: any) =>
          Object.values(item).some(val => val !== null),
        );

        setChartData(filteredData || []);

        setAxisKeys(Object.keys(data?.data[0]));
        setChartType('');
        //setTableArray(data.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        showModal('something went wrong');
      });
  };

  useEffect(() => {
    if (embedded_taskWidgetPortfolioId) {
      getSelectedFileData(embedded_taskWidgetPortfolioId);
    }
  }, []);

  useEffect(() => {
    getFileNames();
  }, [shouldReload]);

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>Analytics Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                addWidgetType('EMBEDDED_BROWSER');

                toogleFilterModal();

                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'embedded_browser'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();

                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          id="chart-dynamic-div"
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <label>Select Portfolio</label>
              <Select
                className="w-100"
                classNamePrefix="react-select"
                noOptionsMessage={() => 'No Data'}
                placeholder={'Select Portfolio'}
                options={fileNames}
                value={selectedFile}
                isClearable={true}
                isMulti={false}
                styles={{
                  control: base => ({
                    ...base,
                  }),
                }}
                onChange={e => {
                  setSelectedFile(e);

                  getSelectedFileData(e.value);
                }}
                onMenuScrollToBottom={() => {
                  if (!isBottom) {
                    getFileNamesAfterSavePortfolio();
                  }
                }}
              />
            </div>

            <div className="col-md-6">
              <label>Select Chart Type</label>
              <select
                id={`chart-type-${embeddedBrowserWidgetIndex}`}
                value={chartType}
                className="form-control"
                onChange={e => {
                  setChartType(e.target.value);
                }}
              >
                <option value="">Please Select</option>
                {chartTypesArray?.map((chartData: any) => (
                  <option key={chartData?.value} value={chartData?.value}>
                    {chartData?.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {chartType === 'scatter' && (
            <ScatterChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'line' && (
            <LineChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'bar' && (
            <BarChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'pie' && (
            <PieChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'box' && (
            <BoxChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'histogram' && (
            <HistogramChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'histogram2d' && (
            <Histogram2DChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'violin' && (
            <ViolinChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'contour' && (
            <ContourChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'heatmap' && (
            <HeatmapChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scatterternary' && (
            <TernaryScatterChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'barpolar' && (
            <PolarBarChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scatterpolar' && (
            <ScatterPolarChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'sunburst' && (
            <SunburstChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'treemap' && (
            <TreemapChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'sankey' && (
            <SankeyChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'waterfall' && (
            <WaterfallChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'candlestick' && (
            <CandleStickChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'ohlc' && (
            <OhlcChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'funnel' && (
            <FunnelChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'funnelarea' && (
            <FunnelAreaChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scatter3d' && (
            <Scatter3DChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'surface' && (
            <Surface3DChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'mesh3d' && (
            <Mesh3DChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scatter3d_lines' && (
            <Scatter3DLinesChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'cone' && (
            <Cone3DChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'streamtube' && (
            <StreamtubeChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scattermapbox' && (
            <ScatterMapBoxChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scattergeo' && (
            <ScatterGeoMapLineChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}

          {chartType === 'scattergeo_scatter' && (
            <ScatterGeoChart
              embeddedBrowserWidgetIndex={embeddedBrowserWidgetIndex}
              axisKeys={axisKeys}
              chartData={chartData}
            />
          )}
        </div>
      </div>
    </>
  );
}

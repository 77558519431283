import axios, { AxiosResponse } from 'axios';
import { RequestObject } from '../@types/AxiosType';
import config from '../config';

export default class ApiUtil {
  static async SendAsync<T>(request: RequestObject): Promise<AxiosResponse<T>> {
    axios.defaults.baseURL = config.api.URL;
    const token = localStorage.getItem('_ERFTKEN');
    if (token) {
      axios.defaults.headers.common['x-er-auth-token'] = `${token}`;
    }
    // try {
    const res = await axios(request);
    if (res.status < 200 && res.status > 300) {
      throw new Error(`Error-${res.status}: ${res.statusText}`);
    }
    return res;
    // } catch (error) {
    //   throw error;
    // }
  }
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { chatData } from '../../../redux/selector/ChatSelector';
import useAction from '../../hooks/useAction';
import ScrollableFeed from 'react-scrollable-feed';
import { groupBy, isArray, keys, map, orderBy, split } from 'lodash';
import moment from 'moment';
import { widgetTypeData } from '../../../redux/selector/WidgetTypeSelector';
import { userData } from '../../../redux/selector/UserSelector';
import { typingData } from '../../../redux/selector/TypingSelector';

const Messages = ({ socket, setWebSocketConnection }: any) => {
  const { updateAllChatMessages, addNewMessage } = useAction();
  const user = useSelector(userData);
  const [WebSocket, setWebSocket] = useState(socket);
  const [typingStatus, setTypingStatus] = useState('');

  const chatMsg = useSelector(chatData);
  const widgetName = useSelector(widgetTypeData);
  const typingStatusData = useSelector(typingData);

  useEffect(() => {
    setTypingStatus(typingStatusData);
  }, [typingStatusData]);

  const handleJoinConversation = () => {
    WebSocket.emit('getMessages', { page: 1, limit: 1000 });

    WebSocket.on('messages', ({ messages }: any) => {
      const formattedData = orderBy(messages, ['createdAt'], ['asc']);
      const dateSeperatorAdded = map(formattedData, item => {
        const copyItem = { ...item };
        copyItem['createdDate'] = split(copyItem.createdAt, 'T')[0];
        return copyItem;
      });
      const messageFilteredObj = groupBy(dateSeperatorAdded, 'createdDate');
      updateAllChatMessages(messageFilteredObj);
    });
  };

  const getNewMessages = () => {
    WebSocket.on('sendMessages', ({ data }: any) => {
      const copyMessage = { ...data };
      copyMessage['createdDate'] = split(copyMessage.createdAt, 'T')[0];
      localStorage.setItem('latestMessage', JSON.stringify(copyMessage));

      addNewMessage(copyMessage);
    });
  };

  useEffect(() => {
    if (WebSocket !== null) {
      handleJoinConversation();
      getNewMessages();

      return () => {
        WebSocket.off('sendMessages');
      };
    } else {
      const newSocket = setWebSocketConnection();
      setWebSocket(newSocket);
    }
  }, [WebSocket]);

  return (
    <ScrollableFeed className="chat-content" forceScroll>
      {isArray(keys(chatMsg)) &&
        keys(chatMsg).map(itemMsgGroup => {
          return (
            <React.Fragment key={itemMsgGroup}>
              {chatMsg[itemMsgGroup]?.filter(
                (a: any) => a.widgetType.toUpperCase() === widgetName,
              )?.length > 0 && (
                <div className="day-date-box">
                  <h3 className="day-date-label">
                    <span>{moment(itemMsgGroup).format('DD MMM YYYY')}</span>
                  </h3>
                </div>
              )}

              {chatMsg[itemMsgGroup]
                ?.filter((a: any) => a.widgetType.toUpperCase() === widgetName)
                ?.map((dateMsg: any) => (
                  <>
                    {dateMsg?.fromId === user.user_id ? (
                      <div className="receiver-mainbox message-box">
                        <div className="message-inner-box">
                          <div className="message-name-time">
                            <p>
                              <span>
                                {moment(dateMsg?.createdAt).format('LT')}
                              </span>
                            </p>
                          </div>
                          <div className="message-description">
                            {dateMsg?.message
                              .split('\n')
                              .map((chatText: any, key: any) => {
                                return <p key={key}>{chatText}</p>;
                              })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="sender-mainbox message-box">
                        <div className="message-inner-box">
                          <div className="sender-avatar">
                            <p>E</p>
                            <span></span>
                          </div>
                          <div className="about-message">
                            <div className="message-name-time">
                              <p>
                                Exchange Chatbot{' '}
                                <span>
                                  {' '}
                                  {moment(dateMsg?.createdAt).format('LT')}
                                </span>
                              </p>
                            </div>
                            <div className="message-description">
                              {dateMsg?.message
                                .split('\n')
                                .map((chatText: any, key: any) => {
                                  return <p key={key}>{chatText}</p>;
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}

              {(typingStatus === 'PREPARE' ||
                typingStatus === 'CALL_LLM' ||
                typingStatus === 'CALL_ROBOTICS') &&
                moment().format('YYYY-MM-DD') === itemMsgGroup && (
                  <div className="sender-mainbox message-box">
                    <div className="message-inner-box">
                      <div
                        className="message-description"
                        style={{ color: 'black' }}
                      >
                        Typing...
                      </div>
                    </div>
                  </div>
                )}
            </React.Fragment>
          );
        })}
    </ScrollableFeed>
  );
};

export default Messages;

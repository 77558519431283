// import { useState } from 'react';
// import DatePicker from 'react-datepicker';
import { Table } from 'reactstrap';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('chatbox-open');
};

const TradesWidget = () => {
  // const slider = useRef<any>();
  // const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox mb-0">
          <h2>Trades Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={toogleFilterModal}
            >
              <IconChatbot index={'trades'} />
            </button>
            <button type="button" className="btn-icon chat-mike">
              <Iconclose />
            </button>
          </div>
        </div>
        <div className="common-widget-content">
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Credit Id</th>
                  <th>Derivative Id</th>
                  <th>Type</th>
                  <th>Typename</th>
                  <th>Client</th>
                  <th>Trade Date</th>
                  <th>Trade Amount</th>
                  <th>Trade Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>13</td>
                  <td></td>
                  <td>7</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>25 Aug 2019</td>
                  <td>7,410</td>
                  <td>123,478</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td></td>
                  <td>6</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>6,452.53</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td></td>
                  <td>5</td>
                  <td>derivative</td>
                  <td>Trade</td>
                  <td>---</td>
                  <td>2 Jun 2020</td>
                  <td>7,410</td>
                  <td>22,317.52</td>
                  <td>closed</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradesWidget;

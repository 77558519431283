import { chatConstant } from '../constant/chat.constant';

const { UPDATE_ALL_MESSAGE, ADD_NEW_MESSAGE } = chatConstant;

export const updateAllChatMessages = (messages: any) => {
  return {
    type: UPDATE_ALL_MESSAGE,
    payload: { messages },
  };
};

export const addNewMessage = (messages: any) => {
  return {
    type: ADD_NEW_MESSAGE,
    payload: { messages },
  };
};

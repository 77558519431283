import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { initializeApp } from 'firebase/app';
import AuthService from './app/services/auth.service';
import NotificationService from './app/services/notification.service';
import config from './config';

const authService = new AuthService();
const notificationService = new NotificationService();

const firebaseConfig = {
  apiKey: 'AIzaSyCj14WafeA15OER8L-BaA-fXPYg9rxe8Z0',
  authDomain: 'fluid-shadow-392700.firebaseapp.com',
  projectId: 'fluid-shadow-392700',
  storageBucket: 'fluid-shadow-392700.appspot.com',
  messagingSenderId: '281097389391',
  appId: '1:281097389391:web:c723268822a4f8d969b13a',
  measurementId: 'G-JH2XYHLZ9E',
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function requestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    //generate token
    const token = await getToken(messaging, {
      vapidKey: config.PUBLIC_VAPID_KEY,
    });

    let deviceID;

    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const randomString =
      Math.random()
        .toString(20)
        .substring(2, 14) +
      Math.random()
        .toString(20)
        .substring(2, 14);

    if (localStorage.getItem('deviceIdER')) {
      deviceID = localStorage.getItem('deviceIdER');
    } else {
      deviceID = `${userAgent}-${platform}-${randomString}`;
    }

    const payload = {
      fcmToken: token,
      deviceId: deviceID,
      source: 'WEB',
    };

    localStorage.setItem('deviceIdER', String(deviceID));

    authService.callFcm(payload).then(() => {
      notificationService.getNotificationCount().then(({ data }: any) => {
        localStorage.setItem('notificationCountER', data);
      });
    });
    // .catch(() => {
    //   window.location.href = config.EXCHANGE_ROBOTICS_LINK;
    // });
  } else if (permission === 'denied') {
    alert('Please allow notification');
  }
}

export const onMessageListener = () => {
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
};

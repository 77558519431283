import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { AppContext } from '../../../App';
import { IconDownArrow, IconMenu, IconSearch } from './Icons';
import PublicChat from './PublicChat';
import LightLogo from '../../../assets/images/logo-light.svg';
import DarkLogo from '../../../assets/images/logo-dark.svg';
import useAction from '../hooks/useAction';
import { hexToRGBCustom, _SET_GlobaColor } from '../../helpers/utils.helper';
import { __LocalStorage } from '../../helpers/constants';
//import { useSelector } from 'react-redux';

type _allColors =
  | '--bs-secondary'
  | '--bs-secondary-rgb'
  | '--menu-text-color'
  | '--bs-body-bg'
  | '--bs-body-bg-rgb'
  | '--bs-body-color'
  | '--widget-text-color'
  | '--btn-custom-bg-color'
  | '--btn-custom-bg-rgb-color'
  | '--btn-custom-text-color';

const colorsInitials = localStorage.getItem(__LocalStorage.colors)
  ? JSON.parse(localStorage.getItem(__LocalStorage.colors) as string)
  : null;

//import { userData } from '../../redux/selector/UserSelector';
const PublicHeader = () => {
  //const [dropDownMenuLoanOptions, setDropDownMenuLoanOptions] = useState(false);
  // const toggleLoanOptions = () =>
  //   setDropDownMenuLoanOptions(prevState => !prevState);

  const ctoggleDropDown = () => {
    setsearchDropDownOpen(state => !state);
  };
  const context = useContext(AppContext);
  const [storedColors, setStoredColors] = useState<Record<
    _allColors,
    string
  > | null>(colorsInitials);

  const [searchdropdownOpen, setsearchDropDownOpen] = useState(false);
  const { updateThemeType } = useAction();

  const [themeType, setThemeType] = useState(localStorage.getItem('themeType'));
  //const user = useSelector(userData);

  const [themedropdownOpen, setthemeDropDownOpen] = useState(false);
  const [
    themedropdownCustomeMenuOpen,
    setThemedropdownCustomeMenuOpen,
  ] = useState(false);
  const pageName = window.location.pathname.split('/')[1];

  const themetoggleDropDown = () => {
    if (themedropdownOpen) {
      const themeSetted = localStorage.getItem(__LocalStorage.themeType);
      if (themeSetted !== __LocalStorage.custom) {
        applyColorChanges('DONT_APPLY');
      }
    }
    setthemeDropDownOpen(state => !state);
    if (!themedropdownOpen) {
      setThemedropdownCustomeMenuOpen(false);
    }
  };

  const themetoggleDropDownCustomeMenu = () => {
    themetoggleDropDown();
    setThemedropdownCustomeMenuOpen(state => !state);
  };

  const remove_GlobalCustomStyle = () => {
    const r = document.querySelector('body') as any;

    r.style = '';
  };

  const toggleTheme = (type: string) => {
    if (type === 'light') {
      document.body.classList.add('theme-light');
      document.body.classList.remove('theme-custom');
      setThemeType('light');
      updateThemeType('light');
      localStorage.setItem('themeType', 'light');
      localStorage.removeItem(__LocalStorage.colors);
      remove_GlobalCustomStyle();
    }

    if (type === 'dark') {
      document.body.classList.remove('theme-light');
      document.body.classList.remove('theme-custom');
      setThemeType('dark');
      updateThemeType('dark');
      localStorage.setItem('themeType', 'dark');
      localStorage.removeItem(__LocalStorage.colors);
      remove_GlobalCustomStyle();
    }
    if (type === 'custom') {
      document.body.classList.remove('theme-light');
      document.body.classList.add('theme-custom');
      setThemeType('custom');
      updateThemeType('custom');
      localStorage.setItem('themeType', 'custom');
    }
  };

  useEffect(() => {
    toggleTheme(localStorage.getItem('themeType') || 'dark');
  }, []);

  const showColorLiveHandler = (name: _allColors, colorValue: string) => {
    //let colorToShow = hexToRGB(color);

    /*if (rgb) {
      colorToShow = hexToRGB(color);
    }*/

    _SET_GlobaColor({ colorVar: name, colorValue: colorValue });
    setStoredColors(color => {
      if (color) {
        if (color[name]) {
          color[name] = colorValue;

          if (name === '--bs-secondary') {
            color['--bs-secondary-rgb'] = hexToRGBCustom(colorValue);
          }

          if (name === '--bs-body-bg') {
            color['--bs-body-bg-rgb'] = hexToRGBCustom(colorValue);
          }

          if (name === '--btn-custom-bg-color') {
            color['--btn-custom-bg-rgb-color'] = hexToRGBCustom(colorValue);
          }
        }
        return color;
      }

      return {
        '--bs-secondary': '#00000',
        '--bs-secondary-rgb': '#00000',
        '--menu-text-color': '#00000',
        '--bs-body-bg': '#00000',
        '--bs-body-bg-rgb': '#00000',
        '--bs-body-color': '#00000',
        '--widget-text-color': '#00000',
        '--btn-custom-bg-color': '#00000',
        '--btn-custom-bg-rgb-color': '#00000',
        '--btn-custom-text-color': '#00000',
      };
    });
  };

  const applyColorChanges = (action: 'APPLY' | 'DONT_APPLY') => {
    if (action === 'APPLY') {
      localStorage.setItem(__LocalStorage.colors, JSON.stringify(storedColors));
      toggleTheme('custom');
      themetoggleDropDown();
      return;
    }

    if (action === 'DONT_APPLY') {
      localStorage.removeItem(__LocalStorage.colors);
      remove_GlobalCustomStyle();
      return;
    }
  };

  return (
    <>
      <PublicChat />
      <header className="header">
        <div className="inner-header flexbox">
          {/* <NavbarToggler  className="me-2 bg-info" /> */}
          <div className="">
            <button
              type="button"
              onClick={() => context.toggleSideBar()}
              className="bg-transparent border-0 d-lg-none me-3 p-0"
            >
              <IconMenu fill="#fff" width={16} height={15} />
            </button>
            <h1 className="d-none d-lg-block">{capitalize(pageName)}</h1>
            <Link to={'/'} className="d-lg-none">
              {themeType === 'dark' ? (
                <img src={DarkLogo} alt="" height={40} />
              ) : (
                <img src={LightLogo} alt="" height={40} />
              )}
            </Link>
          </div>
          <ul className="list-unstyled right-header">
            <li className="search">
              {/* <button className="btn-icon rounded-0">
                <IconSearch />
              </button> */}
              <Dropdown isOpen={searchdropdownOpen} toggle={ctoggleDropDown}>
                <DropdownToggle
                  caret
                  className="btn-icon rounded-0 dropdown-toggle"
                >
                  <IconSearch />
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here ... "
                    />
                  </div>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="theme-item">
              <span className="theme-text">Theme</span>
              <Dropdown isOpen={themedropdownOpen} toggle={themetoggleDropDown}>
                <DropdownToggle
                  caret
                  className="theme-dropdown border-0 rounded-0"
                >
                  {capitalize(themeType || 'dark')}{' '}
                  <span>
                    <IconDownArrow />
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => {
                      toggleTheme('dark');
                    }}
                    className={`${themeType === 'dark' &&
                      'active'} theme-color-options`}
                  >
                    <div className="flexbox-align-c">
                      <span className="theme-img dark-theme"></span>
                      <div className="theme-color-name">
                        {' '}
                        <h4>Dark</h4>
                        <p>Selected</p>
                      </div>
                    </div>
                    <span className="theme-check-sign"></span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      toggleTheme('light');
                    }}
                    className={`${themeType === 'light' &&
                      'active'} theme-color-options`}
                  >
                    <div className="flexbox-align-c">
                      <span className="theme-img light-theme"></span>
                      <div className="theme-color-name">
                        {' '}
                        <h4>Light</h4>
                        <p>Selected</p>
                      </div>
                    </div>
                    <span className="theme-check-sign"></span>
                  </DropdownItem>
                  <DropdownItem
                    className={`${themeType === 'custom' &&
                      'active'} theme-color-options`}
                    onClick={() => {
                      themetoggleDropDownCustomeMenu();
                    }}
                  >
                    <div className="flexbox-align-c">
                      <span className="theme-img custom-theme"></span>
                      <div className="theme-color-name">
                        {' '}
                        <h4>Custom</h4>
                        <p>Selected</p>
                      </div>
                    </div>
                    <span className="theme-check-sign"></span>
                  </DropdownItem>
                  {/* Custom Theme dropdown */}
                  <div
                    className={
                      themedropdownCustomeMenuOpen
                        ? 'custom-dropdown'
                        : 'custom-dropdown d-none '
                    }
                  >
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Choosing logo color:</label>
                      </div>
                      <div className="form-group choose-logo">
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Light
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                          <label className="form-check-label" htmlFor="flexCheckChecked">
                            Dark
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Primary BG Color:</label>
                        <input
                          onChange={ev =>
                            showColorLiveHandler(
                              '--bs-secondary',
                              ev.target.value,
                            )
                          }
                          type="color"
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Primary Text Color:</label>
                        <input
                          type="color"
                          onChange={ev =>
                            showColorLiveHandler(
                              '--menu-text-color',
                              ev.target.value,
                            )
                          }
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Body BG Color:</label>
                        <input
                          type="color"
                          onChange={ev =>
                            showColorLiveHandler(
                              '--bs-body-bg',
                              ev.target.value,
                            )
                          }
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Body Text Color:</label>
                        <input
                          type="color"
                          onChange={ev =>
                            showColorLiveHandler(
                              '--bs-body-color',
                              ev.target.value,
                            )
                          }
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Widget Color:</label>
                        <input
                          type="color"
                          onChange={ev =>
                            showColorLiveHandler(
                              '--widget-text-color',
                              ev.target.value,
                            )
                          }
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Button Color:</label>
                        <input
                          type="color"
                          onChange={ev =>
                            showColorLiveHandler(
                              '--btn-custom-bg-color',
                              ev.target.value,
                            )
                          }
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items">
                      <div className="form-group">
                        <label htmlFor="">Button Text Color:</label>
                        <input
                          type="color"
                          onChange={ev =>
                            showColorLiveHandler(
                              '--btn-custom-text-color',
                              ev.target.value,
                            )
                          }
                          className="form-control"
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="cutom-theme-items text-end">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          applyColorChanges('APPLY');
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
              {/* <Link to={''} className="theme-dropdown">
                Dark{' '}
                <span>
                  <IconDownArrow />
                </span>
              </Link> */}
              <div className="theme-option-dropdown d-none">
                <div className="theme-option-items"></div>
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};
export default PublicHeader;

import { widgetReferenceConstant } from '../constant';

const {
  ADD_WIDGET_REFERENCE_ID,
  REMOVE_WIDGET_REFERENCE_ID,
} = widgetReferenceConstant;

const initialState = {
  widgetReferenceId: '',
};

const WidgetReferenceIdReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_WIDGET_REFERENCE_ID:
      return {
        widgetReferenceId: action.payload.widgetReferenceId,
      };

    case REMOVE_WIDGET_REFERENCE_ID:
      return {
        widgetReferenceId: '',
      };

    default:
      return state;
  }
};

export default WidgetReferenceIdReducer;

import { AxiosResponse } from 'axios';

import ApiUtil from '../../utils/apiUtil';

export default class NotificationService {
  async getNotificationCount(): Promise<any> {
    const url = `/notification/getUnReadCount`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getNotificationList(payload: any): Promise<any> {
    const url = `/notification?limit=${payload.limit}&page=${payload.page}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async updateNotification(id: any): Promise<any> {
    const url = `/notification/${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
    });
    return res.data;
  }
}

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const CandleStickChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');

  const [selectedOpen, setSelectedOpen] = useState('');
  const [selectedClose, setSelectedClose] = useState('');
  const [selectedLow, setSelectedLow] = useState('');
  const [selectedHigh, setSelectedHigh] = useState('');

  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (
    xAxis: string,
    open: string,
    low: string,
    high: string,
    close: string,
  ) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);

    let openArray = tempData?.map(x => x[open]);
    let lowArray = tempData?.map(x => x[low]);
    let closeArray = tempData?.map(x => x[close]);
    let highArray = tempData?.map(x => x[high]);

    let modifiedArray = [
      {
        x: xAxisArray,

        high: highArray,
        close: closeArray,
        open: openArray,
        low: lowArray,
        type: 'candlestick',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (
      selectedXAxis ||
      selectedClose ||
      selectedHigh ||
      selectedLow ||
      selectedOpen
    ) {
      handleXandYaxisKeys(
        selectedXAxis,
        selectedOpen,
        selectedLow,
        selectedHigh,
        selectedClose,
      );
    }
  }, [selectedXAxis, selectedOpen, selectedLow, selectedHigh, selectedClose]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select X</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Open</label>
          <select
            id={`open-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedOpen}
            className="form-control"
            onChange={e => {
              setSelectedOpen(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Close</label>
          <select
            id={`close-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedClose}
            className="form-control"
            onChange={e => {
              setSelectedClose(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>High</label>
          <select
            id={`high-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedHigh}
            className="form-control"
            onChange={e => {
              setSelectedHigh(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Low</label>
          <select
            id={`low-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedLow}
            className="form-control"
            onChange={e => {
              setSelectedLow(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedXAxis ||
        selectedClose ||
        selectedHigh ||
        selectedLow ||
        selectedOpen) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Candlestick',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              dragmode: 'zoom',
              margin: {
                r: 10,
                t: 25,
                b: 40,
                l: 60,
              },
              showlegend: false,
              xaxis: {
                title: selectedXAxis,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default CandleStickChart;

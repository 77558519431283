import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import Select from 'react-select';

const ContourChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [selectedZAxis, setSelectedZAxis] = useState([] as any);
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (
    xAxis: string,
    yAxis: string,
    zAxis: { label: string; value: string }[],
  ) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);

    let zAxisArray = [] as any;

    for (let i = 0; i < zAxis.length; i++) {
      let tempzAxisArrayValue = tempData?.map(x => x[zAxis[i]?.value]);

      zAxisArray.push(tempzAxisArrayValue);
    }

    let modifiedArray = [
      {
        x: xAxisArray,
        y: yAxisArray,
        z: zAxisArray,
        contours: {
          coloring: 'heatmap',
        },
        type: 'contour',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis || selectedYAxis || selectedZAxis.length > 0) {
      handleXandYaxisKeys(selectedXAxis, selectedYAxis, selectedZAxis);
    }
  }, [selectedXAxis, selectedYAxis, selectedZAxis]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select X</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Y</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6 mt-3">
          <Select
            className="w-100"
            classNamePrefix="react-select"
            noOptionsMessage={() => 'No Data'}
            placeholder={'Select Z'}
            options={axisKeys?.map((val: string) => {
              return {
                label: val,
                value: val,
              };
            })}
            value={selectedZAxis}
            isClearable={true}
            isMulti
            styles={{
              control: base => ({
                ...base,
              }),
            }}
            onChange={e => {
              setSelectedZAxis(e);
            }}
          />
        </div>
      </div>
      {(selectedXAxis || selectedYAxis || selectedZAxis.length > 0) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Contour',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ContourChart;

import { useSelector } from 'react-redux';
import useAction from '../../../components/hooks/useAction';
import {
  IconAttachment,
  IconChatbot,
  Iconclose,
  IconMike,
} from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('chatbox-open');
};

const SimplePricingWidget = ({ onClose }: any) => {
  // const slider = useRef<any>();
  // const [startDate, setStartDate] = useState(new Date());
  const widRank = useSelector(widgetRankData);
  const { addWidgetType } = useAction();

  return (
    <>
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox mb-0">
          <h2>Simple Pricing Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('SIMPLE_PRICING');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'simplepricing'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <div className="simple-pricing-chatbox">
            <div className="simply-chatcontent">
              <div className="messangerbox senderbox">
                <div className="message-content">
                  <p>Lorem ipsum dolor sit amet </p>
                </div>
                <div className="messanger-time small">15 minutes ago</div>
              </div>
            </div>
            <div className="simply-texttype">
              <button
                type="button"
                className="chat-mike border-0 bg-transparent"
              >
                <IconAttachment />
              </button>
              <input
                type="text"
                className="border-0 form-control rounded-0"
                placeholder="Type your message... "
              />
              <button
                type="button"
                className="chat-mike border-0 bg-transparent"
              >
                <IconMike index={'chatmike'} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimplePricingWidget;

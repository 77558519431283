import { AxiosResponse } from 'axios';
import ApiUtil from '../../utils/apiUtil';

export default class PortfolioService {
  async saveCalculatedData(payload: any): Promise<any> {
    const url = '/userPortfolio';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getRowData(id: any, category?: any, columnType?: any): Promise<any> {
    const url = `/userPortfolio/getRowData?id=${id}&category=${category}&columnType=${columnType}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getSummary(id: any, columnType?: any): Promise<any> {
    const url = `/userPortfolio/getSummary?id=${id}&columnType=${columnType}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getPortfolioList(payload?: any): Promise<any> {
    const url = `/userPortfolio/listPortfolioName?category=${payload.category}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getPortfolioListProductStructuring(): Promise<any> {
    const url = `/userPortfolio/listPortfolioName`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getCalculations(id: any): Promise<any> {
    const url = `/userPortfolio/getCalculation?id=${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async allocationRowCalculation(id: number, payload: any): Promise<any> {
    const url = `/userPortfolio/calculation?id=${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async saveAllocationRowCalculation(id: number, payload: any): Promise<any> {
    const url = `/userPortfolio/saveCalculation?id=${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async financialCalculation(payload: any): Promise<any> {
    const url = '/calculation';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async telemetryRecord(payload: any): Promise<any> {
    const url = '/telemetry/record';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addTovoData(payload: any): Promise<any> {
    const url = `/tovoData`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addCustomCreditReport(payload: any): Promise<any> {
    const url = `/creditReport`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async addColumnType(id: string, payload: any): Promise<any> {
    const url = `/file/portfolio/${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }
}

import { AppStateType } from '../reducers';

export const nplAllocationCalculation = (state: AppStateType) => {
  return state.AllocationCalculationReducer.allocationDataNPL;
};

export const loanAllocationCalculation = (state: AppStateType) => {
  return state.AllocationCalculationReducer.allocationDataLOAN;
};

export const receivableAllocationCalculation = (state: AppStateType) => {
  return state.AllocationCalculationReducer.allocationDataRECEIVABLE;
};

/* eslint-disable */
import React, { ReactNode, useEffect, useState } from 'react';
//import { useLocation } from 'react-router';
//import queryString from 'query-string';
import queryString from 'query-string';
import Sidebar from '../../../components/public/Sidebar';
//import { useSelector } from "react-redux";
//import { userData } from "../../../redux/selector/UserSelector";
import PublicHeader from '../../../components/public/PublicHeader';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap';
import {
  IconAngleDown,
  IconAngleUp,
  IconPlus,
} from '../../../components/public/Icons';
// import PublicPageHeader from '../../../components/public/PublicPageHeader';
import { WidthProvider, Responsive } from 'react-grid-layout';
import TasksWidget from './TasksWidget';
import NPLWidget from './NPLWidget';
import TransactionReports from './TransactionReports';
import NewsfeedsWidget from './NewsfeedsWidget';
import { useSelector } from 'react-redux';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import { useLocation } from 'react-router';
import EmbeddedBrowserWidget from './AnalyticsWidget';
import { uniq } from 'lodash';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { debug } from 'console';
import useAction from '../../../components/hooks/useAction';
import {
  allDashboardWidgetsData,
  dashboardNewsFeedWidgetDataSelector,
} from '../../../redux/selector/DashboardWidgetSelector';
import MoodyNewsfeedsWidget from './MoodyNewsfeedsWidget';
// import { setWith } from 'lodash';

const jumpBy = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8];

type _widgets =
  | 'news'
  | 'task'
  | 'npl'
  | 'transactionreports'
  | 'embedded_browser'
  | 'moody_news';

const ResponsiveWidth = WidthProvider(Responsive);
const layouts = {
  md: [
    { i: 'a', x: 0, y: 0, w: 6, h: 4 },
    { i: 'b', x: 6, y: 0, w: 6, h: 4 },
    { i: 'c', x: 4, y: 6, w: 6, h: 4 },
    { i: 'd', x: 4, y: 6, w: 6, h: 4 },
    { i: 'e', x: 4, y: 6, w: 6, h: 4 },
    { i: 'f', x: 4, y: 6, w: 6, h: 4 },
    { i: 'g', x: 4, y: 6, w: 6, h: 4 },
  ],
};

const Dashboard = () => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const location = useLocation();
  //const [portfolioId, setPortfolioId] = useState(null as any);
  const widRank = useSelector(widgetRankData);

  const allDashboardWidgetReduxData = useSelector(allDashboardWidgetsData);

  const { search } = useLocation();

  useEffect(() => {
    let { token } = queryString.parse(search);

    if (token) {
      localStorage.setItem('_ERFTKEN', token as string);
    }
  }, [search]);

  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };

  //const user = useSelector(userData);
  // const [layout, setLayout] = useState(layoutSchema);
  //const [layout] = useState(layoutSchema);

  const [widgetToShow, setWidgetToShow] = useState<
    { label: string; value: _widgets; component: any }[] | null
  >(null);
  //const { search } = useLocation();

  /*const portfolioChange = (id: any) => {
    setPortfolioId(id);
  };*/

  const closeHandler = (widgetName: _widgets, index: number) => {
    setWidgetToShow(toShow => {
      if (toShow === null) {
        return null;
      }

      return toShow.filter((wid, widIndex) => widIndex !== index);
    });

    /*setWidgets(oldWidget => {
      let widgetToAdd = _widgetInitials.find(
        widget => widget.value === widgetName,
      );
      if (widgetToAdd) {
        return [...oldWidget, widgetToAdd];
      }
      return [...oldWidget];
    });*/
  };

  const _widgetInitials: {
    label: string;
    value: _widgets;
    component: any;
  }[] = [
    {
      label: 'News Feed Widget',
      value: 'news',
      component: (index: any) => (
        <NewsfeedsWidget
          onClose={(val: _widgets) => closeHandler('news', index)}
          newsWidgetIndex={index}
          type="DASHBOARD"
        />
      ),
    },

    {
      label: 'Moody News Feed Widget',
      value: 'moody_news',
      component: (index: any) => (
        <MoodyNewsfeedsWidget
          onClose={() => closeHandler('moody_news', index)}
          newsWidgetIndex={index}
          type="DASHBOARD"
        />
      ),
    },

    {
      label: 'Tasks Widget',
      value: 'task',
      component: (index: any) => (
        <TasksWidget
          onClose={(val: _widgets) => closeHandler('task', index)}
          tasksWidgetIndex={index}
          type="DASHBOARD"
        />
      ),
    },

    {
      label: 'NPL Financials',
      value: 'npl',
      component: (index: any) => (
        <NPLWidget
          onClose={(val: _widgets) => closeHandler('npl', index)}
          //onPortfolioChange={(val: any) => portfolioChange(val)}
          nplWidgetIndex={index}
          type="DASHBOARD"
        />
      ),
    },
    {
      label: 'Transaction Reports Widget',
      value: 'transactionreports',
      component: (index: any) => (
        <TransactionReports
          onClose={(val: _widgets) => closeHandler('transactionreports', index)}
          transactionreportsWidgetIndex={index}
          type="DASHBOARD"
        />
      ),
    },
    /*{
      label: 'Embeded Browser',
      value: 'embedded_browser',
      component: (index: any) => (
        <EmbeddedBrowserWidget
          onClose={(val: _widgets) => closeHandler('embedded_browser', index)}
          embeddedBrowserWidgetIndex={index}
          type="DASHBOARD"
        />
      ),
    },*/
  ];

  useEffect(() => {
    if (allDashboardWidgetReduxData?.length) {
      let newArray = [] as any;

      allDashboardWidgetReduxData.forEach((y: any, widReduxIndex: number) => {
        y.id = widReduxIndex;

        _widgetInitials.forEach(x => {
          if (y.value === x.value) {
            newArray.push(x);
          }
        });
      });

      setWidgetToShow(newArray);
    }
  }, []);

  const [widgets, setWidgets] = useState<
    {
      label: string;
      value: _widgets;
      component: any;
    }[]
  >(_widgetInitials);

  useEffect(() => {
    if (!allDashboardWidgetReduxData.length) {
      if (
        widRank &&
        widRank.widgetRank &&
        widRank?.widgetRank?.slots?.length > 0
      ) {
        let widToShow = widRank?.widgetRank?.slots[0]?.widget_group?.features?.filter(
          (x: any) =>
            x.platform_sections.includes(location.pathname.split('/')[1]),
        );

        const newArr = [] as any;
        let widArry = [] as any;

        _widgetInitials.forEach((x: any) => {
          widToShow.forEach((y: any) => {
            if (x.value === y.name) {
              newArr.push(x);
              return;
            } else {
              if (!newArr.includes(x)) {
                widArry.push(x);
              }
            }
          });
        });

        widArry = widArry
          .filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.value === value.value),
          )
          .filter((val: any) => !newArr.includes(val));

        if (newArr?.length === 0) {
          widArry = [..._widgetInitials];
        }
        setWidgetToShow(newArr);

        //setWidgets(widArry);
        setWidgets([..._widgetInitials]);
      }
    }
  }, [widRank]);

  const [dimensions] = useState<
    { i: string; x: number; y: number; w: number; h: number }[]
  >([{ i: '0', x: 0, y: 0, w: 6, h: 4 }]);

  /*useEffect(() => {
    let { token } = queryString.parse(search);

    if (!localStorage.getItem('_ERFTKEN')) {
      localStorage.setItem('_ERFTKEN', token as string);
    }
  }, [search]);*/
  const [tableWidth, setTableWidth] = useState<number>(1920);
  useEffect(() => {
    const tabWidth = document.getElementById('main-widget')!;

    let resizeObserver = new ResizeObserver(() => {
      setTableWidth(tabWidth.offsetWidth);
    });

    resizeObserver.observe(tabWidth);
  }, []);
  return (
    <>
      <Sidebar />
      <div className="page-content ">
        <PublicHeader />

        {/* <PublicPageHeader /> */}
        <div className="page-middle-content">
          {/* <div className="sliding-content">
            <div className="holder">
              <div className="news">
                <Nav className="newscroll">
                  <NavItem>
                    <label htmlFor="">IDXESGL</label>
                    134139.967 825
                    <span className="text-success">
                      {' '}
                      0.24% <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">LQ45</label>
                    945.068
                    <span className="text-danger">
                      0.03% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX30</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX80</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div> */}
          <div className="mb-3">
            <Dropdown
              className="add-dropdown"
              isOpen={dropdownOpen}
              toggle={toggleDropDown}
            >
              <DropdownToggle caret direction="down" className="btn">
                <IconPlus /> Add
              </DropdownToggle>
              <DropdownMenu>
                {widgets.map(widget => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        setWidgetToShow(toShow => {
                          if (toShow === null) {
                            return [widget];
                          }
                          return [...toShow, widget];
                        });
                        /*setWidgets(oldWidget => {
                          return oldWidget.filter(
                            wid => wid.value !== widget.value,
                          );
                        });*/
                      }}
                    >
                      {widget.label}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="main-widget" id={'main-widget'}>
            <ResponsiveWidth
              measureBeforeMount={true}
              // width={tableWidth}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2, xxs: 1 }}
              isDraggable={window.innerWidth > 767 ? true : false}
              onResizeStop={() => {
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['resize'],
                  );
                }
              }}
            >
              {/* <div key="1">a</div>
              <div key="2">b</div>
              <div key="3">c</div> */}
              {widgetToShow?.map((widget, index) => {
                let grid = dimensions[0];

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 0,
                    y: grid.h * jumpBy[index],
                    w: 6 || '{tableWidth}',
                    h: 4,
                  };
                }

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 6,
                    y: grid.h * jumpBy[index],
                    w: 6 || '{tableWidth}',
                    h: 4,
                  };
                }

                return (
                  <div key={index} data-grid={grid}>
                    {widget.component(index)}
                  </div>
                );
              })}
            </ResponsiveWidth>
            {/* <ResponsiveWidth
              measureBeforeMount={true}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2, xxs: 1 }}
              className="text-white res-view"
              layouts={layouts}
              width={tableWidth}
              // margin="[10, 10]"
              draggableCancel="false"
              isDraggable={window.innerWidth > 767 ? true : false}
              onResizeStop={() => {
                callTelemetryRecord(
                  'widgets',
                  widRank?.widgetRank?.event_id,
                  widRank?.widgetRank?.slots[0]?.id,
                  ['resize'],
                );
              }}
            >
              {widgetToShow?.map((widget, index) => {
                let grid = dimensions[0];

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 0,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                  };
                }

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 6,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                  };
                }

                return (
                  <div key={index} data-grid={grid}>
                    {widget.component(index)}
                  </div>
                );
              })}
            </ResponsiveWidth> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

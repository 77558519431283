import { Formik } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { object, string } from 'yup';
import NormalizeService from '../../services/normalize.service';
import useAction from '../hooks/useAction';

const normalizeService = new NormalizeService();

const AddActualFieldNameModal = ({
  modal,
  toggle,
  editRecord,
  addSuccess,
}: any) => {
  const { showModal } = useAction();
  return (
    <Modal
      className="modal-lg"
      isOpen={modal}
      centered={true}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Normalize Field</ModalHeader>

      <ModalBody className="pb-0">
        <Formik
          initialValues={{
            actualFieldName: editRecord
              ? get(editRecord, 'actualFieldName', '')
              : '',
            synonymsField: editRecord
              ? get(editRecord, 'synonymsField', '')
              : '',
          }}
          onSubmit={(values, { resetForm }) => {
            //call api here
            const payload = {
              actualFieldName: values.actualFieldName,
              synonymsField: values.synonymsField,
            };

            normalizeService
              .addSynonymsField(payload)
              .then(() => {
                showModal('Field Updated!', true);
                toggle(false);
                addSuccess(values.synonymsField, values.actualFieldName);
                resetForm();
              })
              .catch(err => {
                showModal(err?.response?.data?.message);
              });
          }}
          validationSchema={object().shape({
            actualFieldName: string().required('Actual Field Name is Required'),
            synonymsField: string().required('Synonyms Field Name is Required'),
          })}
        >
          {renderProps => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              handleSubmit,
            } = renderProps;

            return (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label style={{ color: 'black' }}>Actual Field Name</label>
                    <input
                      id="actualFieldName"
                      type="text"
                      style={{ color: 'black' }}
                      className="form-control"
                      placeholder="Enter here"
                      name="actualFieldName"
                      value={values.actualFieldName}
                      onChange={e => {
                        setFieldValue('actualFieldName', e.target.value);
                      }}
                    />
                    {errors.actualFieldName && touched.actualFieldName && (
                      <div className="invalid-feedback mb-3 d-block ">
                        {errors.actualFieldName}
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6">
                    <label style={{ color: 'black' }}>Synonym Field Name</label>
                    <input
                      id="synonymsField"
                      type="text"
                      className="form-control"
                      placeholder="Enter here"
                      style={{ color: 'black' }}
                      name="synonymsField"
                      value={values.synonymsField}
                      onChange={e => {
                        setFieldValue('synonymsField', e.target.value);
                      }}
                    />
                    {errors.synonymsField && touched.synonymsField && (
                      <div className="invalid-feedback mb-3 d-block ">
                        {errors.synonymsField}
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button className="btn btn-dark btn-mw" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddActualFieldNameModal;

import { userDetailsConstant } from '../constant';

const { SUCCESS_USER_DETAILS } = userDetailsConstant;

const initialState: any = {
  userDetails: {},
};

const UserDetailsReducer = (
  state = initialState,
  { type, payload }: any,
): any => {
  switch (type) {
    case SUCCESS_USER_DETAILS:
      return {
        userDetails: { ...payload.userDetails },
      };

    default:
      return state;
  }
};
export default UserDetailsReducer;

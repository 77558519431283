import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Genreal Meethods
//import AppLoader from './app/components/loaders/AppLoader';
import ErrorSuccessModal from './app/components/modals/ErrorSuccessModal';
import { hideModal } from './app/redux/actions/ErrorAction';
import { IsModalShow } from './app/redux/selector/ErrorSelector';
//import { isLoading } from './app/redux/selector/LoaderSelector';
import RoutesComponent from './routes/routes';
import useAction from './app/components/hooks/useAction';
import { useState } from 'react';
import { _SET_GlobaColor } from './app/helpers/utils.helper';
import { __LocalStorage } from './app/helpers/constants';
import { isEmpty } from 'lodash';
import WebSocketHoc from './app/Hoc/WebSocketHoc';
import Notification_custom from './app/components/notification/Notification';
import ProfileService from './app/services/profile.service';
import { jwtDecode } from 'jwt-decode';
import config from './config';
// import config from './config';

const profileService = new ProfileService();

export const AppContext = createContext({
  sideBar: false,
  toggleSideBar: () => {},
});

type _allColors =
  | '--bs-secondary'
  | '--bs-secondary-rgb'
  | '--menu-text-color'
  | '--bs-body-bg'
  | '--bs-body-color'
  | '--bs-body-bg-rgb'
  | '--widget-text-color'
  | '--btn-custom-bg-color'
  | '--btn-custom-bg-rgb-color'
  | '--btn-custom-text-color';

const App = ({ setWebSocketConnection }: any) => {
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = useState(false);
  const {
    getLoggedInUser,
    showLoading,
    getWidgetRank,
    addUserDetails,
    hideLoading,
    showModal,
  } = useAction();
  //const loader = useSelector(isLoading);
  const modal = useSelector(IsModalShow);

  function decodeToken(token: string) {
    try {
      return jwtDecode(token);
    } catch (err) {
      return null;
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('_ERFTKEN');
    if (token) {
      const decodedToken: any = decodeToken(token);
      if (decodedToken.exp < Date.now() / 1000) {
        localStorage.removeItem('_ERFTKEN');

        window.location.href = config.EXCHANGE_ROBOTICS_LINK;
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('_ERFTKEN')) {
      getLoggedInUser();
      getUserProfileDetails();
      setWebSocketConnection();
      //requestPermission();
    }
    // else {
    //   window.location.href = config.EXCHANGE_ROBOTICS_LINK;
    // }
  }, []);

  const getUserProfileDetails = async () => {
    showLoading();
    await profileService
      .getUserProfile()
      .then(data => {
        const userDetails = data.data;

        let payload: any = {
          role: userDetails?.role || '',
          position: userDetails?.position || '',
          function: userDetails?.function || [],
          type: userDetails?.type || '',
          sex: userDetails?.sex || '',
          products: userDetails?.products || [],
          sizes: userDetails?.sizes || '',
          credits: userDetails?.credits || [],
          organization: userDetails?.organization || '',
        };

        if (!payload.role) delete payload.role;
        if (!payload.position) delete payload.position;
        if (!payload.type) delete payload.type;
        if (!payload.sex) delete payload.sex;
        if (!payload.sizes) delete payload.sizes;
        if (!payload.organization) delete payload.organization;
        if (payload.function.length <= 0) delete payload.function;
        if (payload.products.length <= 0) delete payload.products;
        if (payload.credits.length <= 0) delete payload.credits;

        if (!isEmpty(payload)) {
          getWidgetRank();
        }
        hideLoading();
        addUserDetails(data.data);
      })
      .catch(err => {
        hideLoading();
        showModal(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    const colorAlreadySetted = localStorage.getItem(__LocalStorage.colors);
    /*const parsedColors = JSON.parse(
      localStorage.getItem(__LocalStorage.colors) as any,
    );*/
    //&& !colorAlreadySetted
    const themeType = localStorage.getItem(__LocalStorage.themeType);
    if (themeType === 'custom' && !colorAlreadySetted) {
      localStorage.setItem(
        __LocalStorage.colors,
        JSON.stringify({
          '--bs-body-bg': '#f3f3f9',
          '--bs-body-bg-rgb': '0, 194, 146',
          '--bs-body-color': '#495057',
          '--bs-secondary': '#00c292',
          '--bs-secondary-rgb': '0, 194, 146',
          //'--bs-secondary-rgb': hexToRGBCustom(parsedColors['--bs-secondary']),
          '--bs-border-color': '#00c292',
          '--btn-custom-bg-color': '#00c292',
          /*'--btn-custom-bg-rgb-color': hexToRGBCustom(
            parsedColors['--btn-custom-bg-color'],
          ),*/
          '--btn-custom-bg-rgb-color': '0, 194, 146',
          '--btn-custom-text-color': '#fff',
          '--menu-text-color': '#ffffff',
          '--widget-text-color': '#495057',
        }),
      );
    }

    if (themeType === 'custom' && colorAlreadySetted) {
      const colorsToShow = JSON.parse(colorAlreadySetted) as Record<
        _allColors,
        string
      >;
      if (colorsToShow) {
        (Object.keys(colorsToShow) as _allColors[]).forEach(color => {
          _SET_GlobaColor({
            colorValue: colorsToShow[color],
            colorVar: color,
          });
        });
      }
    }
  }, []);

  const handleToggle = () => {
    dispatch(hideModal());
  };

  const toggleSideBar = () => {
    setShowSideBar(show => !show);
    // console.log('click');
    document.body.classList.toggle('sidebar-overlay');
  };

  return (
    <>
      <AppContext.Provider
        value={{ sideBar: showSideBar, toggleSideBar: toggleSideBar }}
      >
        {modal.isShow && (
          <ErrorSuccessModal onToggle={handleToggle} {...modal} />
        )}
        {/*loader.isLoading && <AppLoader title={loader?.title} />*/}
        <div className="layout">
          <div className="overlay"></div>
          <RoutesComponent />
          <Notification_custom />
        </div>
      </AppContext.Provider>
    </>
  );
};

export default WebSocketHoc(App);

export const dashboardWidgetConstant = {
  ADD_NEWS_FEED_DATA: 'ADD_NEWS_FEED_DATA',
  REMOVE_NEWS_FEED_DATA: 'REMOVE_NEWS_FEED_DATA',
  ADD_MOODY_NEWS_FEED_DATA: 'ADD_MOODY_NEWS_FEED_DATA',
  REMOVE_MOODY_NEWS_FEED_DATA: 'REMOVE_MOODY_NEWS_FEED_DATA',

  ADD_TASKS_WIDGET_DATA: 'ADD_TASKS_WIDGET_DATA',
  REMOVE_TASKS_WIDGET_DATA: 'REMOVE_TASKS_WIDGET_DATA',
  ADD_NPL_FINANCIAL_WIDGET_DATA: 'ADD_NPL_FINANCIAL_WIDGET_DATA',
  REMOVE_NPL_FINANCIAL_WIDGET_DATA: 'REMOVE_NPL_FINANCIAL_WIDGET_DATA',
  ADD_TRANSACTION_REPORTS_WIDGET_DATA: 'ADD_TRANSACTION_REPORTS_WIDGET_DATA',
  REMOVE_TRANSACTION_REPORTS_WIDGET_DATA:
    'REMOVE_TRANSACTION_REPORTS_WIDGET_DATA',
  ADD_EMBEDDED_BROWSER_WIDGET_DATA: 'ADD_EMBEDDED_BROWSER_WIDGET_DATA',
  REMOVE_EMBEDDED_BROWSER_WIDGET_DATA: 'REMOVE_EMBEDDED_BROWSER_WIDGET_DATA',
};

import { has } from 'lodash';
import { chatConstant } from '../constant/chat.constant';

const { UPDATE_ALL_MESSAGE, ADD_NEW_MESSAGE } = chatConstant;

const initialState = {
  messages: [],
  flag: false,
};

const filterNewMessage = (allObj: any, newMsgObj: any) => {
  const newMsgCreateDate = newMsgObj.createdDate;
  if (has(allObj, newMsgCreateDate)) {
    allObj[newMsgCreateDate] = [...allObj[newMsgCreateDate], newMsgObj];
  } else {
    allObj[newMsgCreateDate] = [newMsgObj];
  }

  return allObj;
};

const ChatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW_MESSAGE:
      return {
        messages: {
          ...filterNewMessage(state.messages, action.payload.messages),
        },
      };

    case UPDATE_ALL_MESSAGE:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default ChatReducer;

import { AppStateType } from '../reducers';

export const newsFeedWidgetsDataSelector = (state: AppStateType) => {
  return state.NewsFeedWidgetsReducer.newsFeedWidgetData;
};

export const moodyNewsFeedWidgetsDataSelector = (state: AppStateType) => {
  return state.NewsFeedWidgetsReducer.moodyNewsFeedWidgetData;
};

export const allNewsfeedWidgetsData = (state: AppStateType) => {
  return [
    ...state.NewsFeedWidgetsReducer.newsFeedWidgetData,
    ...state.NewsFeedWidgetsReducer.moodyNewsFeedWidgetData,
  ];
};

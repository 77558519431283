// import { Input } from 'reactstrap';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
import Select from 'react-select';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { useSelector } from 'react-redux';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import { useEffect, useRef, useState } from 'react';
import useAction from '../../../components/hooks/useAction';
import { reloadPortfolioData } from '../../../redux/selector/ReloadPortfolioSelector';
// import { nplAllocationCalculation } from '../../../redux/selector/AllocationCalculationSelector';
import { dashboardNplFinancialsWidgetDataSelector } from '../../../redux/selector/DashboardWidgetSelector';
//import { nplPortfolioIdData } from '../../../redux/selector/PortfolioSelector';
//import { isLoading } from '../../../redux/selector/LoaderSelector';
import AppLoader from '../../../components/loaders/AppLoader';
import { portfolioNplWidgetSelector } from '../../../redux/selector/PortfolioWidgetsSelector';
import { isEmpty, isString, uniq } from 'lodash';
import { widgetReferenceIdData } from '../../../redux/selector/WidgetReferenceIdSelector';
import PortfolioService from '../../../services/portfolio.service';
import { nplAllocationCalculation } from '../../../redux/selector/AllocationCalculationSelector';
import axios from 'axios';
import config from '../../../../config';
import { searchedNplChatQuery } from '../../../redux/selector/ChatQuerySelector';

const portfolioService = new PortfolioService();

/*const wateroption = [
  { value: 'Sample portfolio 1', label: 'Sample portfolio 1' },
  { value: 'Sample portfolio 2', label: 'Sample portfolio 2' },
];*/
const NPLWidget = ({ onClose, nplWidgetIndex, type }: any) => {
  const toogleFilterModal = () => {
    document.body.classList.toggle('has-overlay');
    document.body.classList.toggle('chatbox-open');
  };

  const {
    showModal,
    addNplPortfolioId,
    addWidgetType,
    addDashboardNplFinancialsData,
    removeDashboardNplFinancialsData,
    addPortfolioNplWidgetData,
    removePortfolioNplWidgetData,
    addWidgetReferenceId,
    reloadPortfolio,
    addNplChatQuery,
  } = useAction();
  //const loader = useSelector(isLoading);

  const [isLoading, setIsLoading] = useState(false);

  const [userPortfolioName, setUserPortfolioName] = useState([]);
  const [selectedPortfolioName, setSelectedPortfolioName] = useState([] as any);
  //const [rowsData, setRowsData] = useState({} as any);
  //const [headerKeys, setHeaderKeys] = useState([] as any);
  const [calculationData, setCalculationData] = useState({} as any);
  // const [updatedPortfolioName, setUpdatedPortfolioName] = useState('');

  const nplReduxData = useSelector(dashboardNplFinancialsWidgetDataSelector);
  const portfolioNplReduxData = useSelector(portfolioNplWidgetSelector);
  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);

  const widRank = useSelector(widgetRankData);
  let allocationObj = useSelector(nplAllocationCalculation);
  //const portfolioId = useSelector(nplPortfolioIdData);
  const [portfolioId, setPortfolioId] = useState('' as any);
  const firstRender = useRef(true);

  const shouldReload = useSelector(reloadPortfolioData);

  const chatQueryData = useSelector(searchedNplChatQuery);

  const [oldCalculationData, setOldCalculationData] = useState({} as any);

  useEffect(() => {
    if (chatQueryData !== '' && widgetReferenceIdValue === nplWidgetIndex) {
      setIsLoading(true);
      const payload = {
        portfolio_id: portfolioId,
        user_query: chatQueryData,
        widget_type: 'NPL_FINANCIAL',
      };
      axios({
        method: 'post',
        url: `${config.ER_ML_URL}/portfolio/update-calculation`,
        data: payload,
      })
        .then(({ data }) => {
          calcualteSelectionApi(data?.function, false);

          addNplChatQuery('');

          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          showModal(err?.response?.data?.error);
          addNplChatQuery('');
        });
    }
  }, [chatQueryData]);

  const savePortfolioWithCalculation = (query: string) => {
    setIsLoading(true);
    const payload = {
      portfolio_id: portfolioId,
      user_query: query,
      widget_type: 'NPL_FINANCIAL',
    };
    axios({
      method: 'post',
      url: `${config.ER_ML_URL}/portfolio/update-calculation`,
      data: payload,
    })
      .then(({ data }) => {
        showModal(data.message, true);
        // callGetPortfolioList();
        reloadPortfolio(shouldReload ? false : true);

        // addNplChatQuery('');

        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
        // addNplChatQuery('');
      });
  };

  const isObjectEmpty = (obj: any) => {
    // Check if the object is empty
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return true;
    }
    // Check if all nested objects are empty
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (!isObjectEmpty(obj[key])) {
          return false; // If any nested object is not empty, return false
        }
      } else {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
          return false; // If any property is not empty, return false
        }
      }
    }
    return true; // All nested objects are empty
  };

  const calcualteSelectionApi = (newFunction: any, isRemove: boolean) => {
    setIsLoading(true);

    const payload = {
      portfolio_id: portfolioId,
      selection: { ...allocationObj },
      function: !isRemove
        ? uniq([...Object.keys(calculationData), ...newFunction])
        : [...newFunction],
    };
    axios
      .post(
        `${config.ER_ML_URL}/portfolio/calculate-selection-average`,
        payload,
      )
      .then(({ data }: any) => {
        setCalculationData(data || {});

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        showModal('Something went wrong');
      });
  };

  /*const getRowsData = (id: any) => {
    service
      .getRowData(id)
      .then(({ data }) => {
        setRowsData(data);
        setHeaderKeys(Object.keys(data.rowData[0]));
      })
      .catch(err => {
        showModal(err.message);
      });
  };*/

  // const callUpdatePortfolio = () => {
  //   setIsLoading(true);
  //   const payload = {
  //     functionName: calculationData,
  //     portfolioName: updatedPortfolioName,
  //     statistics: { ...allocationObj },
  //   };
  //   portfolioService
  //     .saveAllocationRowCalculation(portfolioId, payload)
  //     .then(() => {
  //       callGetPortfolioList();
  //       setIsLoading(false);
  //       showModal('Portfolio Created Successfully!', true);
  //     })
  //     .catch(err => {
  //       setIsLoading(false);
  //       showModal(err?.response?.data?.message);
  //     });
  // };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      updateWidgetData();
    } else {
      if (nplWidgetIndex === widgetReferenceIdValue) {
        updateWidgetData();
      }
    }
  }, [
    portfolioNplReduxData[nplWidgetIndex]?.selectedPortfolioId,
    nplReduxData[nplWidgetIndex]?.selectedPortfolioId,
  ]);

  const updateWidgetData = () => {
    if (type === 'DASHBOARD') {
      if (nplReduxData?.length) {
        let nplWidgetData = nplReduxData.find(
          (x: any) => x?.id === nplWidgetIndex,
        );

        if (nplWidgetData) {
          setCalculationData(nplWidgetData?.calculationData);
          setSelectedPortfolioName({
            label: nplWidgetData?.selectedPortfolioName,
            value: nplWidgetData?.selectedPortfolioId,
          });
          setPortfolioId(nplWidgetData.selectedPortfolioId);
          addNplPortfolioId(nplWidgetData.selectedPortfolioId);
        }
      }
    }

    if (type === 'PORTFOLIO') {
      if (portfolioNplReduxData?.length) {
        let nplWidgetData = portfolioNplReduxData.find(
          (x: any) => x?.id === nplWidgetIndex,
        );

        if (nplWidgetData) {
          setCalculationData(nplWidgetData?.calculationData);
          setSelectedPortfolioName({
            label: nplWidgetData?.selectedPortfolioName,
            value: nplWidgetData?.selectedPortfolioId,
          });
          setPortfolioId(nplWidgetData.selectedPortfolioId);
          addNplPortfolioId(nplWidgetData.selectedPortfolioId);
        }
      }
    }
  };

  useEffect(() => {
    if (portfolioId) {
      if (type === 'PORTFOLIO') {
        if (portfolioNplReduxData?.length) {
          let nplWidgetData = portfolioNplReduxData.find(
            (x: any) => x?.id === nplWidgetIndex,
          );

          getCalculationData(portfolioId, nplWidgetData?.selectedPortfolioName);
        }
      }

      if (type === 'DASHBOARD') {
        if (nplReduxData?.length) {
          let nplWidgetData = nplReduxData.find(
            (x: any) => x?.id === nplWidgetIndex,
          );

          getCalculationData(portfolioId, nplWidgetData?.selectedPortfolioName);
        }
      }
    }
  }, [portfolioId]);

  const getCalculationData = (id: any, portfolioName: string) => {
    setIsLoading(true);
    portfolioService
      .getCalculations(id)
      .then(({ data }) => {
        setIsLoading(false);
        setCalculationData(data?.function || {});

        if (
          JSON.stringify(data?.function) !== JSON.stringify(oldCalculationData)
        ) {
          setOldCalculationData(data.function);
        }

        if (type === 'DASHBOARD') {
          addDashboardNplFinancialsData({
            id: nplWidgetIndex,
            value: 'npl',
            widgetType: 'DASHBOARD',
            portfolioList: userPortfolioName,
            calculationData: data?.function || {},
            selectedPortfolioId: id,
            selectedPortfolioName: portfolioName,
          });
        }

        if (type === 'PORTFOLIO') {
          addPortfolioNplWidgetData({
            id: nplWidgetIndex,
            value: 'npl',
            widgetType: 'PORTFOLIO',
            portfolioList: userPortfolioName,
            calculationData: data.functionName,
            selectedPortfolioId: id,
            selectedPortfolioName: portfolioName,
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        showModal(err?.response?.data?.message);
      });
  };

  // useEffect(() => {
  //   if (portfolioId) {
  //     setIsLoading(true);
  //     portfolioService
  //       .allocationRowCalculation(portfolioId, allocationObj)
  //       .then(({ data }) => {
  //         setCalculationData(data);

  //         setIsLoading(false);
  //       })
  //       .catch(err => {
  //         showModal(err?.response?.data?.message);
  //         setIsLoading(false);
  //       });
  //   }
  // }, [allocationObj]);

  const callGetPortfolioList = () => {
    setIsLoading(true);
    const payload = {
      category: 'NPL',
    };
    portfolioService
      .getPortfolioList(payload)
      .then(({ data }) => {
        setIsLoading(false);
        setUserPortfolioName(
          data.map((x: any) => {
            return {
              label: x.fileName,
              value: x._id,
            };
          }),
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const removeCalculationFunction = (functionName: string) => {
    setIsLoading(true);
    const payload = {
      portfolio_id: portfolioId,
      function: functionName,
    };
    axios({
      method: 'post',
      url: `${config.ER_ML_URL}/portfolio/remove-function`,
      data: payload,
    })
      .then(() => {
        const allCalculationDataFunction = Object.keys(calculationData)?.filter(
          x => x !== functionName,
        );

        calcualteSelectionApi(allCalculationDataFunction, true);

        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);

        calcualteSelectionApi(err?.response?.data?.functions, true);

        // showModal(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (!isEmpty(allocationObj)) {
      const timer = setTimeout(() => {
        if (isObjectEmpty(allocationObj)) {
          setCalculationData(oldCalculationData);
        } else {
          calcualteSelectionApi([], false);
        }
      }, 3000);

      return () => {
        // Cleanup function to clear the timeout in case the component unmounts or the state changes again
        clearTimeout(timer);
      };
    }
  }, [allocationObj]);

  useEffect(() => {
    callGetPortfolioList();
  }, [shouldReload]);

  return (
    <>
      {isLoading && <AppLoader title={''} />}
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>NPL Financials</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('NPL_FINANCIAL');

                addWidgetReferenceId(nplWidgetIndex);

                if (type === 'PORTFOLIO') {
                  addPortfolioNplWidgetData({
                    id: nplWidgetIndex,
                    value: 'npl',
                    widgetType: 'PORTFOLIO',
                    portfolioList: userPortfolioName,
                    calculationData: calculationData || '',
                    selectedPortfolioId: selectedPortfolioName?.value || '',
                    selectedPortfolioName: selectedPortfolioName?.label || '',
                  });
                }

                if (type === 'DASHBOARD') {
                  addDashboardNplFinancialsData({
                    id: nplWidgetIndex,
                    value: 'npl',
                    widgetType: 'PORTFOLIO',
                    portfolioList: userPortfolioName,
                    calculationData: calculationData || '',
                    selectedPortfolioId: selectedPortfolioName?.value || '',
                    selectedPortfolioName: selectedPortfolioName?.label || '',
                  });
                }
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'nplwidget'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                if (type === 'DASHBOARD') {
                  removeDashboardNplFinancialsData(nplWidgetIndex);
                }

                if (type === 'PORTFOLIO') {
                  removePortfolioNplWidgetData(nplWidgetIndex);
                }
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <div className="row">
            {/* <div className="col-auto">
              <div className="row">
                <div className="col-auto form-group">
                  <div className="input-group mb-3">
                    <label
                      className="input-group-text btn btn-primary"
                      htmlFor="csvFileInput"
                      title=" Choose an .xlsx file"
                    >
                      Import from file
                    </label>
                    <Input
                      type="file"
                      className="form-control d-none"
                      id="csvFileInput"
                      accept=".csv"
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <button className="btn btn-primary" type="button">
                    Clear
                  </button>
                </div>
                <div className="clearfix"></div>
              </div>
            </div> */}
            <div className="col-md-8">
              <div className="row">
                <div className="col-auto form-group">
                  <label className="mb-1">Load NPL portfolio from server</label>
                  <Select
                    className="w-100"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => 'No Data'}
                    placeholder={'Select Portfolio Name'}
                    options={userPortfolioName}
                    value={selectedPortfolioName}
                    isClearable={true}
                    styles={{
                      control: base => ({
                        ...base,
                      }),
                    }}
                    onChange={e => {
                      setSelectedPortfolioName(e);
                      //getRowsData(e.value);
                      getCalculationData(e.value, e.label);
                      setPortfolioId(e.value);
                      addNplPortfolioId(e.value);
                    }}
                  />
                </div>
                <div className="col-auto form-group">
                  {/*Calculation Data -
                  {Object.entries(calculationData).map(([key, value]) => {
                    return (
                      <div key={key}>
                        {key} : {value}
                      </div>
                    );
                  })}*/}
                </div>
              </div>
            </div>
            {portfolioId && (
              <div className="col-md-8">
                <div className="row">
                  {/* <div className="col-auto form-group">
                  <label className="mb-1">Portfolio Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="Updated_Portfolio_name"
                    placeholder="Enter Portfolio Name"
                    value={updatedPortfolioName}
                    onChange={e => {
                      setUpdatedPortfolioName(e.target.value);
                    }}
                  />
                </div> */}
                  <div className="col-auto form-group">
                    <button
                      className="btn btn-primary"
                      type="button"
                      //disabled={!updatedPortfolioName}
                      onClick={() => {
                        savePortfolioWithCalculation(
                          'Save portfolio as type NPL',
                        );
                      }}
                    >
                      Save Portfolio
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="table-responsive mb-3">
            <table className="table" aria-label="sticky table">
              {/*<thead>
                <tr>
                  {headerKeys.map((x: any) => (
                    <th scope="col">{x}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="">
                {rowsData?.rowData?.map((rData: any) => {
                  return (
                    <tr className="">
                      {Object.values(rData).map((y: any) => (
                        <td>{y}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>*/}
              {!isEmpty(calculationData) && (
                <>
                  <thead>
                    <tr>
                      <th scope="col">Key Figures</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {Object.entries(calculationData)?.map(
                      ([key, value]: any) => {
                        return (
                          <tr className="">
                            <td>{key}</td>
                            <td>
                              {isString(value) ? value : JSON.stringify(value)}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn-icon chat-mike"
                                onClick={() => {
                                  removeCalculationFunction(key);
                                }}
                              >
                                <Iconclose />
                              </button>
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default NPLWidget;

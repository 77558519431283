import { AxiosResponse } from 'axios';
import { UserResponseType } from '../../@types/UserType';
import ApiUtil from '../../utils/apiUtil';

export default class AuthService {
  async getLoginUser(): Promise<UserResponseType> {
    const url = '/auth/getUser';
    const res: AxiosResponse = await ApiUtil.SendAsync<UserResponseType>({
      url: url,
      method: 'GET',
    });
    return res.data.data;
  }

  async logout(): Promise<any> {
    const url = '/auth/logout';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async callFcm(payload: any): Promise<any> {
    const url = `/auth/fcmToken`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'PUT',
      data: payload,
    });
    return res.data;
  }
}

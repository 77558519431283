import { AxiosResponse } from 'axios';

import ApiUtil from '../../utils/apiUtil';

export default class TaskService {
  async getSharedTask(task: string): Promise<any> {
    const url = `/file/getShareTasks?task=${task}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
}

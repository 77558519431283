import { widgetReferenceConstant } from '../constant';

const {
  ADD_WIDGET_REFERENCE_ID,
  REMOVE_WIDGET_REFERENCE_ID,
} = widgetReferenceConstant;

export const addWidgetReferenceId = (data: any) => {
  return {
    type: ADD_WIDGET_REFERENCE_ID,
    payload: { widgetReferenceId: data },
  };
};

export const removeWidgetReferenceId = () => {
  return {
    type: REMOVE_WIDGET_REFERENCE_ID,
    payload: { widgetReferenceId: '' },
  };
};

import { AxiosResponse } from 'axios';
import ApiUtil from '../../utils/apiUtil';

export default class NormalizeService {
  async addSynonymsField(payload: any): Promise<any> {
    const url = '/normalize';
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getNormalizeHeader(): Promise<any> {
    const url = `/normalize`;
    const res: AxiosResponse = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }
}

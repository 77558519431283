/* ACTIONS */
import { newsFeedWidgetConstant } from '../constant';

const {
  ADD_NEWS_FEED_DATA,
  REMOVE_NEWS_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
} = newsFeedWidgetConstant;

export const addNewsFeedData = (data: any) => {
  return {
    type: ADD_NEWS_FEED_DATA,
    payload: {
      newsFeedWidgetData: data,
    },
  };
};

export const removeNewsFeedData = (id: number) => {
  return {
    type: REMOVE_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addMoodyNewsFeedData = (data: any) => {
  return {
    type: ADD_MOODY_NEWS_FEED_DATA,
    payload: {
      moodyNewsFeedWidgetData: data,
    },
  };
};

export const removeMoodyNewsFeedData = (id: number) => {
  return {
    type: REMOVE_MOODY_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const PolarBarChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (xAxis: string, yAxis: string) => {
    let tempData = [...chartData];

    let xAxisArrayValue = tempData?.map(x => x[xAxis]);
    let yAxisArrayValue = tempData?.map(x => x[yAxis]);

    function myFilter(elm: any) {
      return elm != null && elm !== false && elm !== '';
    }

    let xAxisArray = xAxisArrayValue.filter(myFilter);
    let yAxisArray = yAxisArrayValue.filter(myFilter);

    let modifiedArray = [
      {
        r: xAxisArray,
        theta: yAxisArray,
        marker: { color: 'rgb(106,81,163)' },
        type: 'barpolar',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis || selectedYAxis) {
      handleXandYaxisKeys(selectedXAxis, selectedYAxis);
    }
  }, [selectedXAxis, selectedYAxis]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select Radius</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Theta</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedXAxis || selectedYAxis) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Polar Bar',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              font: { size: 16 },
              legend: { font: { size: 16 } },
            }}
          />
        </div>
      )}
    </>
  );
};

export default PolarBarChart;

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useAction from '../../../components/hooks/useAction';
import { IconChatbot, Iconclose } from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
//import { dashboardTasksWidgetDataSelector } from '../../../redux/selector/DashboardWidgetSelector';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import TaskService from '../../../services/task.service';

const taskService = new TaskService();

const TasksWidget = ({ onClose, tasksWidgetIndex }: any) => {
  const toogleFilterModal = () => {
    document.body.classList.toggle('has-overlay');
    document.body.classList.toggle('chatbox-open');
  };
  const {
    addWidgetType,
    showModal,
    addPortfolioCreditWidgetData,
    showLoading,
    hideLoading,

    addDashboardTasksData,
    removeDashboardTasksData,
  } = useAction();
  const navigate = useNavigate();

  const widRank = useSelector(widgetRankData);
  //const dashboardTaskData = useSelector(dashboardTasksWidgetDataSelector);

  const [sendTaskList, setSendTaskList] = useState([]);
  const [receiveTaskList, setReceiveTaskList] = useState([]);
  const [activeTab, setActiveTab] = useState('RECEIVED');

  const getSharedTaskList = () => {
    showLoading();
    taskService
      .getSharedTask(activeTab.toLowerCase())
      .then(({ data }) => {
        if (activeTab === 'SEND') {
          setSendTaskList(data?.sharedPortfolio);
        } else {
          setReceiveTaskList(data?.sharedPortfolio);
        }

        addDashboardTasksData({
          id: tasksWidgetIndex,
          value: 'task',
          widgetType: 'DASHBOARD',
          //taskListData: data.sharedPortfolio,
        });
        hideLoading();
      })
      .catch(() => {
        showModal('Something went wrong');
        hideLoading();
      });
  };

  useEffect(() => {
    getSharedTaskList();
  }, [activeTab]);

  return (
    <>
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>Tasks Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('TASKS');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'nplwidget'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                removeDashboardTasksData(tasksWidgetIndex);
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === 'SEND' })}
                onClick={() => {
                  setActiveTab('SEND');
                }}
              >
                Send
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === 'RECEIVED' })}
                onClick={() => {
                  setActiveTab('RECEIVED');
                }}
              >
                Received
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="SEND">
              {sendTaskList.length > 0 && (
                <div className="">
                  <table className="table" aria-label="sticky table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Instruction</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {sendTaskList?.map((item: any, index: number) => {
                        return (
                          <tr
                            key={item.id}
                            id={`col-${index + 1}`}
                            onClick={() => {
                              addPortfolioCreditWidgetData({
                                id: 0,
                                value: 'credits',
                                widgetType: 'PORTFOLIO',
                              });

                              localStorage.setItem(
                                'taskPortfolioId',
                                item.portfolioId,
                              );

                              navigate('/portfolio');
                            }}
                          >
                            <td className="claimtablecell">
                              <div className="mw-250 text-truncate-2">
                                {item?.owner?.name}
                              </div>
                            </td>

                            <td className="claimtablecell">
                              <div className="mw-250 text-truncate-2">
                                {item?.owner?.email}
                              </div>
                            </td>

                            <td className="claimtablecell">
                              <div className="mw-250 text-truncate-2">
                                {item?.introduction}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPane>
            <TabPane tabId="RECEIVED">
              {receiveTaskList.length > 0 && (
                <div className="">
                  <table className="table" aria-label="sticky table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Instruction</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {receiveTaskList?.map((item: any, index: number) => {
                        return (
                          <tr
                            key={item.id}
                            id={`col-${index + 1}`}
                            onClick={() => {
                              addPortfolioCreditWidgetData({
                                id: 0,
                                value: 'credits',
                                widgetType: 'PORTFOLIO',
                              });

                              localStorage.setItem(
                                'taskPortfolioId',
                                item.portfolioId,
                              );

                              navigate('/portfolio');
                            }}
                          >
                            <td className="claimtablecell">
                              <div className="mw-250 text-truncate-2">
                                {item?.owner?.name}
                              </div>
                            </td>

                            <td className="claimtablecell">
                              <div className="mw-250 text-truncate-2">
                                {item?.owner?.email}
                              </div>
                            </td>

                            <td className="claimtablecell">
                              <div className="mw-250 text-truncate-2">
                                {item?.introduction}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default TasksWidget;

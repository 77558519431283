/* ACTIONS */
import { dashboardWidgetConstant } from '../constant';

const {
  ADD_NEWS_FEED_DATA,
  REMOVE_NEWS_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
  ADD_TASKS_WIDGET_DATA,
  REMOVE_TASKS_WIDGET_DATA,
  ADD_NPL_FINANCIAL_WIDGET_DATA,
  REMOVE_NPL_FINANCIAL_WIDGET_DATA,
  ADD_TRANSACTION_REPORTS_WIDGET_DATA,
  REMOVE_TRANSACTION_REPORTS_WIDGET_DATA,
  ADD_EMBEDDED_BROWSER_WIDGET_DATA,
  REMOVE_EMBEDDED_BROWSER_WIDGET_DATA,
} = dashboardWidgetConstant;

export const addDashboardNewsFeedData = (data: any) => {
  return {
    type: ADD_NEWS_FEED_DATA,
    payload: {
      newsFeedWidgetData: data,
    },
  };
};

export const removeDashboardNewsFeedData = (id: number) => {
  return {
    type: REMOVE_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardMoodyNewsFeedData = (data: any) => {
  return {
    type: ADD_MOODY_NEWS_FEED_DATA,
    payload: {
      moodyNewsFeedWidgetData: data,
    },
  };
};

export const removeDashboardMoodyNewsFeedData = (id: number) => {
  return {
    type: REMOVE_MOODY_NEWS_FEED_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardTasksData = (data: any) => {
  return {
    type: ADD_TASKS_WIDGET_DATA,
    payload: {
      tasksWidgetData: data,
    },
  };
};

export const removeDashboardTasksData = (id: number) => {
  return {
    type: REMOVE_TASKS_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardNplFinancialsData = (data: any) => {
  return {
    type: ADD_NPL_FINANCIAL_WIDGET_DATA,
    payload: {
      nplFinancialWidgetData: data,
    },
  };
};

export const removeDashboardNplFinancialsData = (id: number) => {
  return {
    type: REMOVE_NPL_FINANCIAL_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardTransactionReportsData = (data: any) => {
  return {
    type: ADD_TRANSACTION_REPORTS_WIDGET_DATA,
    payload: {
      transactionReportsWidgetData: data,
    },
  };
};

export const removeDashboardTransactionReportsData = (id: number) => {
  return {
    type: REMOVE_TRANSACTION_REPORTS_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

export const addDashboardEmbeddedBrowserData = (data: any) => {
  return {
    type: ADD_EMBEDDED_BROWSER_WIDGET_DATA,
    payload: {
      embeddedBrowserWidgetData: data,
    },
  };
};

export const removeDashboardEmbeddedBrowserData = (id: number) => {
  return {
    type: REMOVE_EMBEDDED_BROWSER_WIDGET_DATA,
    payload: {
      removeId: id,
    },
  };
};

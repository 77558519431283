import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const SankeyChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedTarget, setSelectedTarget] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (
    source: string,
    target: string,
    value: string,
    label: string,
  ) => {
    let tempData = [...chartData];

    let sourceArray = tempData?.map(x => x[source]);
    let targetArray = tempData?.map(x => x[target]);
    let valueArray = tempData?.map(x => x[value]);
    let labelArray = tempData?.map(x => x[label]);

    let modifiedArray = [
      {
        orientation: 'h',
        node: {
          pad: 15,
          thickness: 30,
          line: {
            color: 'black',
            width: 0.5,
          },
          // label: xAxisArray,
          // x: xAxisArray,
          // y: yAxisArray,
        },

        link: {
          source: sourceArray,
          target: targetArray,
          value: valueArray,
          label: labelArray,
        },
        type: 'sankey',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedSource || selectedTarget || selectedValue || selectedLabel) {
      handleXandYaxisKeys(
        selectedSource,
        selectedTarget,
        selectedValue,
        selectedLabel,
      );
    }
  }, [selectedSource, selectedTarget, selectedValue, selectedLabel]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select Source</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedSource}
            className="form-control"
            onChange={e => {
              setSelectedSource(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Target</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedTarget}
            className="form-control"
            onChange={e => {
              setSelectedTarget(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Value</label>
          <select
            id={`z-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedValue}
            className="form-control"
            onChange={e => {
              setSelectedValue(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Label</label>
          <select
            id={`z-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedValue}
            className="form-control"
            onChange={e => {
              setSelectedLabel(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedSource || selectedTarget || selectedValue || selectedLabel) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Sankey',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
            }}
          />
        </div>
      )}
    </>
  );
};

export default SankeyChart;

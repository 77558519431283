import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const Cone3DChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [selectedZAxis, setSelectedZAxis] = useState('');
  const [selectedU, setSelectedU] = useState('');
  const [selectedV, setSelectedV] = useState('');
  const [selectedW, setSelectedW] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (
    xAxis: string,
    yAxis: string,
    zAxis: string,
    uValue: string,
    vValue: string,
    wValue: string,
  ) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);
    let zAxisArray = tempData?.map(x => x[zAxis]);
    let uArray = tempData?.map(x => x[uValue]);
    let vArray = tempData?.map(x => x[vValue]);
    let wArray = tempData?.map(x => x[wValue]);

    let modifiedArray = [
      {
        x: xAxisArray,
        y: yAxisArray,
        z: zAxisArray,
        u: uArray,
        v: vArray,
        w: wArray,
        opacity: 0.8,
        type: 'cone',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (
      selectedXAxis ||
      selectedYAxis ||
      selectedZAxis ||
      selectedU ||
      selectedV ||
      selectedW
    ) {
      handleXandYaxisKeys(
        selectedXAxis,
        selectedYAxis,
        selectedZAxis,
        selectedU,
        selectedV,
        selectedW,
      );
    }
  }, [
    selectedXAxis,
    selectedYAxis,
    selectedZAxis,
    selectedU,
    selectedV,
    selectedW,
  ]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select X</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Y</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Z</label>
          <select
            id={`z-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedZAxis}
            className="form-control"
            onChange={e => {
              setSelectedZAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select U</label>
          <select
            id={`u-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedU}
            className="form-control"
            onChange={e => {
              setSelectedU(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select V</label>
          <select
            id={`v-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedV}
            className="form-control"
            onChange={e => {
              setSelectedV(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select W</label>
          <select
            id={`w-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedW}
            className="form-control"
            onChange={e => {
              setSelectedW(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedXAxis ||
        selectedYAxis ||
        selectedZAxis ||
        selectedU ||
        selectedV ||
        selectedW) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Cone 3D',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default Cone3DChart;

import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const SunburstChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [selectedIds, setSelectedIds] = useState('');
  const [selectedValues, setSelectedValues] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  const handleXandYaxisKeys = (
    xAxis: string,
    yAxis: string,
    ids: string,
    values: string,
  ) => {
    let tempData = [...chartData];

    let xAxisArray = tempData?.map(x => x[xAxis]);
    let yAxisArray = tempData?.map(x => x[yAxis]);
    let idsArray = tempData?.map(x => x[ids]);
    let valuesArray = tempData?.map(x => x[values]);

    let modifiedArray = [
      {
        ids: idsArray,
        labels: xAxisArray,
        parents: yAxisArray,
        values: valuesArray,
        textposition: 'inside',
        insidetextorientation: 'radial',
        type: 'sunburst',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis || selectedYAxis || selectedIds || selectedValues) {
      handleXandYaxisKeys(
        selectedXAxis,
        selectedYAxis,
        selectedIds,
        selectedValues,
      );
    }
  }, [selectedXAxis, selectedYAxis, selectedIds, selectedValues]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select Labels</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Parents</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select Ids</label>
          <select
            id={`ids-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedIds}
            className="form-control"
            onChange={e => {
              setSelectedIds(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <label>Select Values</label>
          <select
            id={`values-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedValues}
            className="form-control"
            onChange={e => {
              setSelectedValues(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedXAxis || selectedYAxis || selectedIds || selectedValues) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Sunburst',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default SunburstChart;

import { newsFeedWidgetConstant } from '../constant';

const {
  ADD_NEWS_FEED_DATA,
  REMOVE_NEWS_FEED_DATA,
  ADD_MOODY_NEWS_FEED_DATA,
  REMOVE_MOODY_NEWS_FEED_DATA,
} = newsFeedWidgetConstant;

const initialState: any = {
  newsFeedWidgetData: [] as any,
  moodyNewsFeedWidgetData: [] as any,
};

const filterByRemoveId = (newsData: any, widgetId: any) => {
  let newsArray = [...newsData];
  let tempArray = newsArray.filter(x => x.id !== widgetId);
  return tempArray;
};

const updatedNewsFeedData = (newsData: any, newNewsFeedData: any) => {
  //newsData = {}
  let tempData = [...newsData];

  let newsIndex = tempData.findIndex((x: any) => x.id === newNewsFeedData.id);

  if (newsIndex > -1) {
    tempData[newsIndex] = newNewsFeedData;
  } else {
    tempData.push(newNewsFeedData);
  }

  return tempData;
};

const NewsFeedWidgetsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ADD_NEWS_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: updatedNewsFeedData(
          state.newsFeedWidgetData,
          action.payload.newsFeedWidgetData,
        ),
      };

    case REMOVE_NEWS_FEED_DATA:
      return {
        ...state,
        newsFeedWidgetData: filterByRemoveId(
          state.newsFeedWidgetData,
          action.payload.removeId,
        ),
      };

    case ADD_MOODY_NEWS_FEED_DATA:
      return {
        ...state,
        moodyNewsFeedWidgetData: updatedNewsFeedData(
          state.moodyNewsFeedWidgetData,
          action.payload.moodyNewsFeedWidgetData,
        ),
      };

    case REMOVE_MOODY_NEWS_FEED_DATA:
      return {
        ...state,
        moodyNewsFeedWidgetData: filterByRemoveId(
          state.moodyNewsFeedWidgetData,
          action.payload.removeId,
        ),
      };

    default:
      return state;
  }
};
export default NewsFeedWidgetsReducer;

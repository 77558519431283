import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const TernaryScatterChart = ({
  embeddedBrowserWidgetIndex,
  axisKeys,
  chartData,
}: {
  embeddedBrowserWidgetIndex: number;
  axisKeys: any;
  chartData: any;
}) => {
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [selectedZAxis, setSelectedZAxis] = useState('');
  const [updatedData, setUpdatedData] = useState([] as any);

  function makeAxis(title: string) {
    return {
      title: title,
      ticksuffix: '%',
      min: 0.01,
      linewidth: 2,
      ticks: 'outside',
      ticklen: 8,
      showgrid: true,
    };
  }

  const handleXandYaxisKeys = (xAxis: string, yAxis: string, zAxis: string) => {
    let tempData = [...chartData];

    let xAxisArrayValue = tempData?.map(x => x[xAxis]);
    let yAxisArrayValue = tempData?.map(x => x[yAxis]);
    let zAxisArrayValue = tempData?.map(x => x[zAxis]);

    function myFilter(elm: any) {
      return elm != null && elm !== false && elm !== '';
    }

    let xAxisArray = xAxisArrayValue.filter(myFilter);
    let yAxisArray = yAxisArrayValue.filter(myFilter);
    let zAxisArray = zAxisArrayValue.filter(myFilter);

    let modifiedArray = [
      {
        a: xAxisArray,
        b: yAxisArray,
        c: zAxisArray,
        hoveron: 'fills+points',
        line: { color: '#8dd3c7' },
        fillcolor: '#bebada',
        fill: 'toself',
        mode: 'lines',
        type: 'scatterternary',
      },
    ];

    setUpdatedData(modifiedArray);
  };
  useEffect(() => {
    if (selectedXAxis || selectedYAxis || selectedZAxis) {
      handleXandYaxisKeys(selectedXAxis, selectedYAxis, selectedZAxis);
    }
  }, [selectedXAxis, selectedYAxis, selectedZAxis]);
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Select A</label>
          <select
            id={`x-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedXAxis}
            className="form-control"
            onChange={e => {
              setSelectedXAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select B</label>
          <select
            id={`y-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedYAxis}
            className="form-control"
            onChange={e => {
              setSelectedYAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-6">
          <label>Select C</label>
          <select
            id={`z-axis-type-${embeddedBrowserWidgetIndex}`}
            value={selectedZAxis}
            className="form-control"
            onChange={e => {
              setSelectedZAxis(e.target.value);
            }}
          >
            <option value="">Please Select</option>
            {axisKeys?.map((axisData: any) => (
              <option key={axisData} value={axisData}>
                {axisData}
              </option>
            ))}
          </select>
        </div>
      </div>
      {(selectedXAxis || selectedYAxis || selectedZAxis) && (
        <div className="mt-4" onMouseDown={e => e.stopPropagation()}>
          <Plot
            data={updatedData}
            config={{
              scrollZoom: true,
              showEditInChartStudio: true,
              plotlyServerURL: 'https://chart-studio.plotly.com',
              displaylogo: false,
              editable: true,
              responsive: true,
              // autosizable: true,
            }}
            layout={{
              title: 'Ternary Scatter',
              // mode: 'markers',
              width: document.getElementById('chart-dynamic-div')?.clientWidth,
              ternary: {
                sum: 100,
                aaxis: makeAxis(selectedXAxis),
                baxis: makeAxis(selectedYAxis),
                caxis: makeAxis(selectedZAxis),
                bgcolor: '#fff1e0',
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default TernaryScatterChart;

// import { useState } from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Card, CardBody, CardText, CardTitle, Input } from 'reactstrap';
import useAction from '../../../components/hooks/useAction';
// import DatePicker from 'react-datepicker';
import {
  IconChatbot,
  Iconclose,
  IconMinus,
  IconPlus,
  IconTrash,
} from '../../../components/public/Icons';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import { productStructuringWidgetData } from '../../../redux/selector/ProductStructuringSelector';
import { reloadPortfolioData } from '../../../redux/selector/ReloadPortfolioSelector';
import { rowSelectionTypeData } from '../../../redux/selector/RowSelectionTypeSelector';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import PortfolioService from '../../../services/portfolio.service';

const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('chatbox-open');
};

/*const wateroption = [
  { value: 'txc', label: 'txc' },
  { value: 'Other', label: 'Other' },
];*/

type _Criteria =
  | 'VaR'
  | 'Standard Deviation'
  | 'Alpha'
  | 'Beta'
  | 'Sharpe Ratio';

const criteria: { value: _Criteria; label: _Criteria }[] = [
  { value: 'VaR', label: 'VaR' },
  { value: 'Standard Deviation', label: 'Standard Deviation' },
  { value: 'Alpha', label: 'Alpha' },
  { value: 'Beta', label: 'Beta' },
  { value: 'Sharpe Ratio', label: 'Sharpe Ratio' },
];

const portfolioService = new PortfolioService();

const ProductStructuringWidget = ({ onClose }: any) => {
  // const slider = useRef<any>();
  // const [startDate, setStartDate] = useState(new Date());
  const widRank = useSelector(widgetRankData);
  const [userPortfolioName, setUserPortfolioName] = useState([]);
  const [selectedPortfolioName, setSelectedPortfolioName] = useState([] as any);
  const shouldReload = useSelector(reloadPortfolioData);
  const {
    addWidgetType,
    addSelectedPortfolio,
    addRowSelectionType,

    //addProductStructureData,
    //removeProductStructureData,
  } = useAction();
  const selectedPortfolios = useSelector(productStructuringWidgetData);
  const rowSelectionData = useSelector(rowSelectionTypeData);

  const [tranches, setTranches] = useState<null | string[]>(null);
  const [inputsToShow, setInputsToShow] = useState<
    null | { value: _Criteria; label: _Criteria }[]
  >(null);

  useEffect(() => {
    if (rowSelectionData === 'CHATBOX') {
      if (selectedPortfolios) {
        let selectedPortfolioArray = [] as any;
        selectedPortfolios?.forEach((x: any) => {
          selectedPortfolioArray.push({ label: x.label, value: x.value });
        });
        setSelectedPortfolioName(selectedPortfolioArray);
      }
    }
  }, [selectedPortfolios]);

  useEffect(() => {
    portfolioService.getPortfolioListProductStructuring().then(({ data }) => {
      setUserPortfolioName(
        data.map((x: any) => {
          return {
            label: x.portfolioName,
            value: x.id,
          };
        }),
      );
    });
  }, [shouldReload]);

  const min = 0;
  const max = 100;

  const [value, setValue] = useState<string[]>([]);

  const handleChange = (inputVal: string, index: number) => {
    const val = Math.max(min, Math.min(max, Number(inputVal)));
    let number = val.toString();
    number = number.substring(0, 0) + '' + number.substring(0 + ''.length);
    const valeus = JSON.parse(JSON.stringify(value));
    valeus[index] = number;

    setValue(valeus);
    // setValue(number);
  };

  const removeInputHandler = (criteria: _Criteria) => {
    setInputsToShow(inputs => {
      if (inputs === null) {
        return null;
      }
      return inputs.filter(input => input.value !== criteria);
    });
  };

  const addRemoveHandler = (action: 'add' | 'remove') => {
    if (action === 'add') {
      const val = [...value];
      val.push('50');
      setValue(val);
      setTranches(tran => {
        if (tran === null) {
          return ['tranch'];
        }
        return [...tran, 'tranch'];
      });
    }

    if (action === 'remove') {
      setTranches(tran => {
        if (tran === null) {
          return null;
        }

        if (tran?.length === 0) {
          return null;
        }

        const newTran = [...tran];
        newTran.length = tran.length - 1;
        return newTran;
      });
    }
  };

  return (
    <>
      <div className="common-widget">
        <div className="widget-title-header common-widget-header flexbox">
          <h2>Product Structuring Widget</h2>
          {/* <button type="button" className="btn-icon chat-mike"><IconMike index={'newsfeed'} /></button> */}
          <div className="d-flex gap-3 py-2 py-lg-0">
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                toogleFilterModal();
                addWidgetType('PRODUCTS');
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['chat'],
                  );
                }
              }}
            >
              <IconChatbot index={'productstructure'} />
            </button>
            <button
              type="button"
              className="btn-icon chat-mike"
              onClick={() => {
                onClose();
                //removeProductStructureData(productStructureIndex);
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['close'],
                  );
                }
              }}
            >
              <Iconclose />
            </button>
          </div>
        </div>
        <div
          className="common-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          <h4 className=""> Portfolio selection </h4>
          {/* {console.log({ selectedPortfolioName })} */}
          <div className="row">
            <div className="col-lg-5 col-md-6 form-group">
              <label className="mb-0">Select Portfolio</label>
              <Select
                className="w-100"
                classNamePrefix="react-select"
                noOptionsMessage={() => 'No Data'}
                placeholder={'Select Portfolio Name'}
                options={userPortfolioName}
                value={selectedPortfolioName}
                isClearable={true}
                isMulti
                styles={{
                  control: base => ({
                    ...base,
                  }),
                }}
                onChange={e => {
                  addRowSelectionType('MANUAL');
                  setSelectedPortfolioName(e);
                  addSelectedPortfolio(e);
                }}
              />
            </div>
            <div className="clearfix"></div>
            <div className="col-md-6">
              <h4>Configuration</h4>
              <div className="form-group">
                <label className="mb-0">Allocation criteria</label>
                <Select
                  className="w-100"
                  classNamePrefix="react-select"
                  options={criteria}
                  isMulti
                  value={inputsToShow}
                  onChange={val => {
                    setInputsToShow(
                      val as { value: _Criteria; label: _Criteria }[],
                    );
                  }}
                  // defaultInputValue="ml"
                  styles={{
                    control: base => ({
                      ...base,
                    }),
                  }}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="col-md-6">
              <h4>Tranches</h4>
              <div className="add-minus-btn">
                <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() => addRemoveHandler('add')}
                >
                  <IconPlus /> Add
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => addRemoveHandler('remove')}
                >
                  <IconMinus /> Remove
                </button>
              </div>
            </div>
            <div className="clearfix"></div>
            {tranches?.map((tranch, index) => {
              // if (index === 0) {
              //   return (
              //     <div className="col-md-auto com-sm-6 my-3">
              //       <Card
              //         className="h-100 w-100"
              //         style={{
              //           borderRadius: '4px',
              //         }}
              //       >
              //         <CardBody className="h-100">
              //           <div className="align-items-start d-flex justify-content-between ">
              //             <CardTitle tag="h5">Senior tranche</CardTitle>
              //             <div
              //               className="form-group mb-0"
              //               style={{ width: '90px' }}
              //             >
              //               <label htmlFor="" className="card-text mb-0">
              //                 Weightage
              //               </label>
              //               <input
              //                 type="number"
              //                 placeholder="Your fav number"
              //                 value={'51'}
              //                 // onChange={handleChange}
              //                 className="form-control"
              //                 name="weightage"
              //               />
              //             </div>
              //           </div>

              //           <CardText>Parameters:</CardText>
              //           {inputsToShow?.map(input => {
              //             return (
              //               <div className="parameters-input form-group d-flex align-items-center">
              //                 <Input
              //                   type="text"
              //                   className="form-control bg-transparent"
              //                   placeholder={input.label}
              //                 ></Input>
              //                 <button
              //                   type="button"
              //                   className="btn btn-icon border-0 h-100 rounded"
              //                   onClick={() => removeInputHandler(input.value)}
              //                 >
              //                   <IconTrash />
              //                 </button>
              //               </div>
              //             );
              //           })}

              //           <button type="button" className="btn btn-primary">
              //             Underwrite
              //           </button>
              //         </CardBody>
              //       </Card>
              //     </div>
              //   );
              // }

              return (
                <div className="col-md-auto com-sm-6 my-3">
                  <Card
                    className="h-100 w-100"
                    style={{
                      borderRadius: '4px',
                    }}
                  >
                    <CardBody className="h-100">
                      <div className="align-items-start d-flex justify-content-between ">
                        <CardTitle tag="h5">
                          {index === 0
                            ? 'Senior tranche'
                            : `Ezzanine tranche ${index}`}
                        </CardTitle>
                        <div
                          className="form-group mb-0"
                          style={{ width: '90px' }}
                        >
                          <label htmlFor="" className="card-text mb-0">
                            Weightage
                          </label>
                          <input
                            type="number"
                            placeholder="Your fav number"
                            value={value[index]}
                            onChange={e => handleChange(e.target.value, index)}
                            className="form-control"
                            name={`'Ezzanine-tranche-${index}`}
                            id={`'Ezzanine-tranche-${index}`}
                          />
                        </div>
                      </div>
                      <CardText>Parameters:</CardText>
                      {inputsToShow?.map(input => {
                        return (
                          <div className="parameters-input form-group d-flex align-items-center">
                            <Input
                              type="text"
                              className="form-control bg-transparent"
                              placeholder={input.label}
                            ></Input>
                            <button
                              type="button"
                              className="btn btn-icon border-0 h-100 rounded"
                              onClick={() => removeInputHandler(input.value)}
                            >
                              <IconTrash />
                            </button>
                          </div>
                        );
                      })}
                      <button type="button" className="btn btn-primary">
                        Underwrite
                      </button>
                    </CardBody>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductStructuringWidget;

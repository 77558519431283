/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../../components/public/Sidebar';
import PublicHeader from '../../../components/public/PublicHeader';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap';
import {
  IconAngleDown,
  IconAngleUp,
  IconPlus,
} from '../../../components/public/Icons';
import GridLayout from 'react-grid-layout';
// import ClaimsWidget from '../../../components/unused/ClaimsWidget';
// import PublicPageHeader from '../../../components/public/PublicPageHeader';
import NPLCollateralWidget from '../dashboard/NPLCollateralWidget';
import NPLLoanWidget from '../dashboard/NPLLoanWidget';
import NPLWidget from '../dashboard/NPLWidget';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { widgetRankData } from '../../../redux/selector/WidgetRankSelector';
import { useLocation } from 'react-router';
import { callTelemetryRecord } from '../../../helpers/utils.helper';
import LOANLoanWidget from '../dashboard/LOANLoanWidget';
import LOANCollateralWidget from '../dashboard/LOANCollateralWidget';
import LOANFinancials from '../dashboard/LOANFinancials';
import EmbeddedBrowserWidget from '../dashboard/AnalyticsWidget';
import useAction from '../../../components/hooks/useAction';
// import ClaimsWidgetExp from '../../../components/unused/ClaimsWidgetExp';
import { AppStateType } from '../../../redux/reducers';
import { allPortfolioWidgetsSelector } from '../../../redux/selector/PortfolioWidgetsSelector';
import CreditWidgetCopy from '../dashboard/CreditWidget';
import ReceivablesWidget from './widgets/ReceivablesWidget';
import ReceivablesCollateralWidget from './widgets/ReceivablesCollateralWidget';
import ReceivablesLoanWidget from './widgets/ReceivablesLoanWidget';

const jumpBy = [0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8];

type _widgets =
  | 'credits'
  | 'credits_copy'
  | 'npl'
  | 'npl_collateral'
  | 'npl_loan'
  | 'loan'
  | 'loan_loan'
  | 'loan_collateral'
  | 'receivable'
  | 'receivable_loan'
  | 'receivable_collateral'
  | 'embedded_browser';

const ResponsiveWidth = WidthProvider(Responsive);
const layouts = {
  md: [
    { i: 'a', x: 0, y: 0, w: 6, h: 4 },
    { i: 'b', x: 6, y: 0, w: 6, h: 4 },
    { i: 'c', x: 4, y: 6, w: 6, h: 4 },
    { i: 'd', x: 4, y: 6, w: 6, h: 4 },
    { i: 'e', x: 4, y: 6, w: 6, h: 4 },
    { i: 'f', x: 4, y: 6, w: 6, h: 4 },
    { i: 'g', x: 4, y: 6, w: 6, h: 4 },
  ],
};
// const allWidgets = useSelector(
//   (state: AppStateType) => state.PortfolioWidgetReducer,
// );

const Portfolio = () => {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const location = useLocation();
  const widRank = useSelector(widgetRankData);
  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);
  const toggleDropDown = () => {
    setDropDownOpen(state => !state);
  };
  const widgetsCache = useSelector(
    (state: AppStateType) => state.PortfolioWidgetReducer.widgetCache,
  );

  const _widgetInitials: {
    label: string;
    value: _widgets;
    component: any;
  }[] = [
    // {
    //   label: 'Credit Widget',
    //   value: 'credits',
    //   component: (index: any) => (
    //     <ClaimsWidget
    //       onClose={(val: _widgets) => closeHandler('credits', index)}
    //       creditIndex={index}
    //       type="PORTFOLIO"
    //     />
    //   ),
    // },

    {
      label: 'Credit Widget',
      value: 'credits',
      component: (index: any) => (
        <CreditWidgetCopy
          onClose={(val: _widgets) => closeHandler('credits', index)}
          creditIndex={index}
          type="PORTFOLIO"
        />
      ),
    },

    {
      label: 'Analytics Widget',
      value: 'embedded_browser',
      component: (index: any) => (
        <EmbeddedBrowserWidget
          onClose={(val: _widgets) => closeHandler('embedded_browser', index)}
          embeddedBrowserWidgetIndex={index}
          //type="PORTFOLIO"
        />
      ),
    },

    {
      label: 'NPL Financials',
      value: 'npl',
      component: (index: any) => (
        <NPLWidget
          onClose={(val: _widgets) => closeHandler('npl', index)}
          nplWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },

    {
      label: 'NPL Collateral Widget',
      value: 'npl_collateral',
      component: (index: any) => (
        <NPLCollateralWidget
          onClose={(val: _widgets) => closeHandler('npl_collateral', index)}
          nplCollateralWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },
    {
      label: 'NPL Loan Widget',
      value: 'npl_loan',
      component: (index: any) => (
        <NPLLoanWidget
          onClose={(val: _widgets) => closeHandler('npl_loan', index)}
          nplLoanWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },
    {
      label: 'LOAN Financials',
      value: 'loan',
      component: (index: any) => (
        <LOANFinancials
          onClose={(val: _widgets) => closeHandler('loan', index)}
          loanWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },
    {
      label: 'Loan Loan Widget',
      value: 'loan_loan',
      component: (index: any) => (
        <LOANLoanWidget
          onClose={(val: _widgets) => closeHandler('loan_loan', index)}
          loanLoanWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },
    {
      label: 'Loan Collateral Widget',
      value: 'loan_collateral',
      component: (index: any) => (
        <LOANCollateralWidget
          onClose={(val: _widgets) => closeHandler('loan_collateral', index)}
          loanCollateralWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },

    {
      label: 'Receivables Widget',
      value: 'receivable',
      component: (index: any) => (
        <ReceivablesWidget
          onClose={(val: _widgets) => closeHandler('receivable', index)}
          receivablesWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },

    {
      label: 'Receivables Collateral Widget',
      value: 'receivable_collateral',
      component: (index: any) => (
        <ReceivablesCollateralWidget
          onClose={(val: _widgets) =>
            closeHandler('receivable_collateral', index)
          }
          receivablesWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },

    {
      label: 'Receivables Loan Widget',
      value: 'receivable_loan',
      component: (index: any) => (
        <ReceivablesLoanWidget
          onClose={(val: _widgets) => closeHandler('receivable_loan', index)}
          receivablesWidgetIndex={index}
          type="PORTFOLIO"
        />
      ),
    },
  ];

  const [widgetToShow, setWidgetToShow] = useState<
    { label: string; value: _widgets; component: any }[] | null
  >(null);

  const [widgets, setWidgets] = useState<
    {
      label: string;
      value: _widgets;
      component: any;
    }[]
  >(_widgetInitials);

  //const user = useSelector(userData);
  // const [layout, setLayout] = useState(layoutSchema);
  //const [layout] = useState(layoutSchema);

  const { creditWidget } = useAction();

  //const { search } = useLocation();

  const closeHandler = (widgetName: _widgets, index: number) => {
    setWidgetToShow(toShow => {
      if (toShow === null) {
        return null;
      }
      return toShow.filter((wid, widIndex) => widIndex !== index);
    });
    /*setWidgets(oldWidget => {
      let widgetToAdd = _widgetInitials.find(
        widget => widget.value === widgetName,
      );
      if (widgetToAdd) {
        return [...oldWidget, widgetToAdd];
      }
      return [...oldWidget];
    });*/
  };

  useEffect(() => {
    if (allPortfolioWidgetReduxData?.length) {
      let newArray = [] as any;

      allPortfolioWidgetReduxData.forEach((y: any, widReduxIndex: number) => {
        y.id = widReduxIndex;

        _widgetInitials.forEach(x => {
          if (y.value === x.value) {
            newArray.push(x);
          }
        });
      });

      setWidgetToShow(newArray);
    }
  }, []);

  useEffect(() => {
    if (!allPortfolioWidgetReduxData.length) {
      if (
        widRank &&
        widRank.widgetRank &&
        widRank?.widgetRank?.slots?.length > 0
      ) {
        let widToShow = widRank?.widgetRank?.slots[0]?.widget_group?.features?.filter(
          (x: any) =>
            x.platform_sections.includes(location.pathname.split('/')[1]),
        );

        const newArr = [] as any;
        let widArry = [] as any;

        _widgetInitials.forEach((x: any) => {
          widToShow.forEach((y: any) => {
            if (x.value === y.name) {
              newArr.push(x);
              return;
            } else {
              if (!newArr.includes(x)) {
                widArry.push(x);
              }
            }
          });
        });

        widArry = widArry
          .filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.value === value.value),
          )
          .filter((val: any) => !newArr.includes(val));

        if (newArr?.length === 0) {
          widArry = [..._widgetInitials];
        }

        const filtered = widgets.filter(widget =>
          widgetsCache.includes(widget.value),
        );

        setWidgetToShow([...newArr, ...filtered]);

        //setWidgets(widArry);
        setWidgets([..._widgetInitials]);
      }
    }
  }, [widRank]);

  const [dimensions] = useState<
    { i: string; x: number; y: number; w: number; h: number }[]
  >([{ i: '0', x: 0, y: 0, w: 6, h: 4 }]);

  return (
    <>
      <Sidebar />
      <div className="page-content ">
        <PublicHeader />
        {/* <PublicPageHeader /> */}
        <div className="page-middle-content">
          {/* <div className="sliding-content">
            <div className="holder">
              <div className="news">
                <Nav className="newscroll">
                  <NavItem>
                    <label htmlFor="">IDXESGL</label>
                    134139.967 825
                    <span className="text-success">
                      {' '}
                      0.24% <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">LQ45</label>
                    945.068
                    <span className="text-danger">
                      0.03% <IconAngleDown />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX30</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">IDX80</label>
                    134.825
                    <span className="text-success">
                      {' '}
                      0.12%
                      <IconAngleUp />
                    </span>
                  </NavItem>
                  <NavItem>
                    <label htmlFor="">Composite</label>
                    6,638.019
                    <span className="text-danger">
                      {' '}
                      0.33% <IconAngleDown />
                    </span>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div> */}
          <div className="mb-3">
            <Dropdown
              className="add-dropdown"
              isOpen={dropdownOpen}
              toggle={toggleDropDown}
            >
              <DropdownToggle caret direction="down" className="btn">
                <IconPlus /> Add
              </DropdownToggle>
              <DropdownMenu>
                {widgets.map(widget => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        creditWidget(widget.value);
                        setWidgetToShow(toShow => {
                          if (toShow === null) {
                            return [widget];
                          }
                          return [...toShow, widget];
                        });
                        /*setWidgets(oldWidget => {
                          return oldWidget.filter(
                            wid => wid.value !== widget.value,
                          );
                        });*/
                      }}
                    >
                      {widget.label}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="main-widget" id={'main-widget'}>
            <ResponsiveWidth
              measureBeforeMount={true}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 10, sm: 6, xs: 2, xxs: 1 }}
              className="text-white res-view"
              layouts={layouts}
              // margin="[10, 10]"
              draggableCancel="false"
              isDraggable={window.innerWidth > 767 ? true : false}
              onResizeStop={() => {
                if (
                  widRank &&
                  widRank.widgetRank &&
                  widRank?.widgetRank?.slots?.length > 0
                ) {
                  callTelemetryRecord(
                    'widgets',
                    widRank?.widgetRank?.event_id,
                    widRank?.widgetRank?.slots[0]?.id,
                    ['resize'],
                  );
                }
              }}
            >
              {widgetToShow?.map((widget, index) => {
                let grid = dimensions[0];

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 0,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                  };
                }

                if (index % 2 === 1) {
                  grid = {
                    i: `${index}`,
                    x: 6,
                    y: grid.h * jumpBy[index],
                    w: 6,
                    h: 4,
                  };
                }

                return (
                  <div key={index} data-grid={grid}>
                    {widget.component(index)}
                  </div>
                );
              })}
            </ResponsiveWidth>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;

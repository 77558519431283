import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
/* COMPONENTS */
import App from './App';
import ScrollToTop from './app/components/public/ScrollToTop';
// React-Redux store
import store from './app/redux/store';
import en from './locales/en';
import reportWebVitals from './reportWebVitals';
/* VENDOR */
import './vendor';

const root = (
  <Provider store={store}>
    <IntlProvider messages={en} locale="en" defaultLocale="en">
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </IntlProvider>
  </Provider>
);

const rootElement = document.getElementById('root');
ReactDOM.render(root, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
